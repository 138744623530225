/*
 * API Routes
 */

export const AUTH_API = '/auth';

export const LOGIN_PASSWORD_ENDPOINT = `${AUTH_API}/login`;
export const LOGIN_SEND_OTP_ENDPOINT = `${AUTH_API}/send-otp`;
export const LOGIN_VERIFY_OTP_ENDPOINT = `${AUTH_API}/verify-otp`;

export const USER_API = '/users';
export const CURRENT_USER_ENDPOINT = `${USER_API}/me`;
export const CURRENT_USER_SUPERVISOR_ENDPOINT = `${CURRENT_USER_ENDPOINT}/supervisor`;
export const UPDATE_PASSWORD_ENDPOINT = `${CURRENT_USER_ENDPOINT}/password`;

export const ADMIN_ENDPOINT = `${USER_API}/admins`;
export const OPERATOR_ENDPOINT = `${USER_API}/operators`;
export const DEVELOPER_ENDPOINT = `${USER_API}/developers`;

export const INVOICE_API = '/invoices';
export const INVOICE_ENDPOINT = INVOICE_API;

export const ORDER_API = '/orders';
export const ORDER_ENDPOINT = ORDER_API;
export const ORDER_DETAIL_ENDPOINT = `${ORDER_API}/:orderId`;

export const PAYMENT_API = '/payments';
export const PRE_CAPTURE_AMOUNT_ENDPOINT = `${PAYMENT_API}/pre-capture-amount`;

export const GET_INVOICE_ENDPOINT = `${INVOICE_ENDPOINT}/:invoiceId`;
export const DOWNLOAD_INVOICE_PDF_ENDPOINT = `${GET_INVOICE_ENDPOINT}/pdf`;
export const DOWNLOAD_INVOICE_HTML_ENDPOINT = `${GET_INVOICE_ENDPOINT}/html`;

export const CUSTOMER_API = '/customers';
export const CUSTOMER_ENDPOINT = CUSTOMER_API;
export const CUSTOMER_DETAIL_ENDPOINT = `${CUSTOMER_API}/:customerId`;
export const CUSTOMER_LOCK_STATUS_ENDPOINT = `${CUSTOMER_DETAIL_ENDPOINT}/lock-status`;
export const CUSTOMER_LOCK_ENDPOINT = `${CUSTOMER_DETAIL_ENDPOINT}/lock`;
export const CUSTOMER_UNLOCK_ENDPOINT = `${CUSTOMER_DETAIL_ENDPOINT}/unlock`;
export const CUSTOMER_SYNC_SUBSCRIPTION = `${CUSTOMER_DETAIL_ENDPOINT}/sync-subscriptions`;

export const CHARGING_SESSION_API = '/charging-sessions';
export const CHARGING_SESSION_ENDPOINT = CHARGING_SESSION_API;
export const CHARGING_SESSION_DETAIL_ENDPOINT = `${CHARGING_SESSION_API}/:chargingSessionId`;

export const CHARGE_POINT_API = '/charge-points';
export const CHARGE_POINT_QR_CODE_ENDPOINT = `${CHARGE_POINT_API}/:chargePointId/qr-code`;

export const QR_CODE_API = '/qr-code';
export const QR_CODE_CONFIG_ENDPOINT = `${QR_CODE_API}/config`;
export const QR_CODE_GENERATE_IDENTIFIER_ENDPOINT = `${QR_CODE_API}/generate-identifier`;

export const LOG_API = '/logs';

export const CPO_API = '/cpos';

export const LOCATION_API = '/locations';
