import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { RoundIcon } from '../round-icon/round-icon';

type BaseTitleProps = {
  readonly iconSx?: SxProps;
  readonly icon: ReactNode;
  readonly children: ReactNode;
  readonly sx?: SxProps;
};

function BaseTitle(props: BaseTitleProps): React.JSX.Element {
  const { iconSx, children, icon, sx } = props;

  return (
    <Stack gap={1} flexDirection="row" alignItems="center" sx={sx}>
      <RoundIcon sx={iconSx}>{icon}</RoundIcon>
      {children}
    </Stack>
  );
}

type TitleProps = {
  readonly icon: ReactNode;
  readonly children: string;
};

export function Title(props: TitleProps): React.JSX.Element {
  const { children, ...baseTitleProps } = props;

  return (
    <BaseTitle {...baseTitleProps}>
      <Typography variant="h4" component="h1">
        {children}
      </Typography>
    </BaseTitle>
  );
}

export function SubTitle(props: TitleProps): React.JSX.Element {
  const { children, ...baseTitleProps } = props;

  return (
    <BaseTitle {...baseTitleProps}>
      <Typography variant="h5" component="h2">
        {children}
      </Typography>
    </BaseTitle>
  );
}

export function DialogTitle(props: TitleProps): React.JSX.Element {
  const { icon, children } = props;

  return (
    <MuiDialogTitle
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center',
      }}>
      <RoundIcon>{icon}</RoundIcon>
      {children}
    </MuiDialogTitle>
  );
}
