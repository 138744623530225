import { Skeleton, TableCell, TableRow } from '@mui/material';

type SkeletonTableProps = {
  rows?: number;
  cols?: number;
};

export function SkeletonTable(props: SkeletonTableProps): React.JSX.Element[] {
  const { rows = 4, cols = 4 } = props;

  return Array.from({ length: rows }, (_, index) => index).map((row) => (
    <TableRow key={row}>
      {Array.from({ length: cols }, (_, index) => index).map((col) => (
        <TableCell key={col}>
          <Skeleton />
        </TableCell>
      ))}
    </TableRow>
  ));
}
