import { useCallback, useLayoutEffect, useState } from 'react';
import {
  CheckoutFeeModel,
  EnhancedQuarterOverviewModel,
  QuarterOverviewModel,
} from 'src/core/model/quarter-overview.model';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/shared/button/button';
import { useSetPageActionContext } from 'src/shared/layout/page-layout/page-layout.context-provider';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { QuarterOverviewDownload } from './quarter-overview-download';
import { QuarterOverviewTable } from './quarter-overview-table/quarter-overview.table';
import { QuarterOverviewUpload } from './quarter-overview-upload/quarter-overview-upload';

export function QuarterOverview(): React.JSX.Element {
  const { t } = useTranslation();
  const [lmsLines, setLmsLines] = useState<QuarterOverviewModel[]>([]);
  const [checkoutLines, setCheckoutLines] = useState<CheckoutFeeModel[]>([]);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const setPageAction = useSetPageActionContext();

  const handleReplaceLine = useCallback(
    (id: string, payload: Partial<EnhancedQuarterOverviewModel>) => {
      setLmsLines((previousLines) =>
        previousLines.map((previousItem) =>
          previousItem.id === id
            ? { ...previousItem, ...payload }
            : previousItem,
        ),
      );
    },
    [],
  );

  const handleAddCheckoutLines = useCallback((data: CheckoutFeeModel[]) => {
    setCheckoutLines((prev) => [...prev, ...data]);
  }, []);

  useLayoutEffect(() => {
    const createOperatorButton = (
      <Button
        key="create-operator"
        onClick={() => setIsUploadDialogOpen(true)}
        startIcon={<UploadFileIcon />}>
        {t('quarter-overview.upload.button')}
      </Button>
    );

    setPageAction([createOperatorButton]);
  }, [t, setPageAction]);

  return (
    <>
      <QuarterOverviewTable
        lmsLines={lmsLines}
        rawCheckoutList={checkoutLines}
        onChange={handleReplaceLine}
      />

      <QuarterOverviewDownload
        data={lmsLines}
        disabled={lmsLines.length === 0}
      />

      <Dialog
        fullWidth
        maxWidth="lg"
        open={isUploadDialogOpen}
        onClose={() => setIsUploadDialogOpen(false)}>
        <DialogTitle>{t('quarter-overview.upload.title')}</DialogTitle>
        <DialogContent>
          <QuarterOverviewUpload
            onLmsUpload={setLmsLines}
            onCheckoutUpload={handleAddCheckoutLines}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
