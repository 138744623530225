import { useTranslation } from 'react-i18next';
import { unparse } from 'papaparse';
import { CSV_MIME_TYPE } from 'src/config/app.config';
import { downloadBlob } from 'src/core/helper/download-blob';
import { Button } from 'src/shared/button/button';
import { EnhancedQuarterOverviewModel } from 'src/core/model/quarter-overview.model';
import DownloadIcon from '@mui/icons-material/Download';

import { unparseQuarterOverviewModel } from './quarter-overview.utils';

type Props = {
  readonly disabled?: boolean;
  readonly data: EnhancedQuarterOverviewModel[];
};

export function QuarterOverviewDownload(props: Props): React.JSX.Element {
  const { data, disabled } = props;
  const { t } = useTranslation();

  const quarterOverviewTranslations: Record<
    keyof EnhancedQuarterOverviewModel,
    string
  > = t('model.quarter-overview', {
    returnObjects: true,
  });

  const fields = [
    quarterOverviewTranslations.id,
    quarterOverviewTranslations.date,
    quarterOverviewTranslations.chargePointName,
    quarterOverviewTranslations.customerId,
    quarterOverviewTranslations.connectorId,
    quarterOverviewTranslations.name,
    quarterOverviewTranslations.externalCardId,
    quarterOverviewTranslations.internalCardId,
    quarterOverviewTranslations.global,
    quarterOverviewTranslations.energyDelivered,
    quarterOverviewTranslations.totalHT,
    quarterOverviewTranslations.durationInMin,
    quarterOverviewTranslations.evseUid,
    quarterOverviewTranslations.totalHTDelmonicos,
    quarterOverviewTranslations.totalTTCDelmonicos,
    quarterOverviewTranslations.transactionId,
    quarterOverviewTranslations.iTransactionId,
    quarterOverviewTranslations.checkoutFees,
  ];

  const handleDownload = (): void => {
    const csv = unparse(
      {
        fields,
        data: data.map(unparseQuarterOverviewModel),
      },
      {
        delimiter: ';',
      },
    );
    const blob = new Blob([csv], { type: CSV_MIME_TYPE });

    downloadBlob(blob, t('quarter-overview.result-filename'));
  };

  return (
    <Button
      startIcon={<DownloadIcon />}
      variant="contained"
      onClick={handleDownload}
      disabled={disabled}>
      {t('quarter-overview.download-button')}
    </Button>
  );
}
