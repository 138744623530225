import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { UpdatePasswordModel } from 'src/core/model/user.model';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import { TextField } from 'src/shared/form/text-field';
import { SubmitButton } from 'src/shared/button/submit.button';

import {
  UpdatePasswordFormModel,
  useUpdatePasswordFormValidation,
} from './use-update-password.form-validation';

function mapUpdatePasswordFormModelToUpdatePasswordModel(
  payload: UpdatePasswordFormModel,
): UpdatePasswordModel {
  return {
    password: payload.password,
    currentPassword: payload.currentPassword,
  };
}

type UpdatePasswordFormProps = {
  readonly title?: string;
  readonly caption?: string;
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly onSubmit: (payload: UpdatePasswordModel) => void;
};

export function UpdatePasswordForm(
  props: UpdatePasswordFormProps,
): React.JSX.Element {
  const { t } = useTranslation();
  const {
    isLoading,
    isError,
    onSubmit,
    title = t('update-password.title'),
    caption = t('update-password.caption'),
  } = props;

  const formValidation = useUpdatePasswordFormValidation();

  const translations: Record<keyof UpdatePasswordFormModel, string> = t(
    'model.update-password',
    {
      returnObjects: true,
    },
  );

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<UpdatePasswordFormModel>({
    mode: 'onChange',
  });

  return (
    <>
      <DialogTitle>{title}</DialogTitle>

      <form
        noValidate
        onSubmit={handleSubmit((payload) =>
          onSubmit(mapUpdatePasswordFormModelToUpdatePasswordModel(payload)),
        )}>
        <DialogContent>
          <DialogContentText>{caption}</DialogContentText>

          <TextField
            {...register('currentPassword', formValidation.currentPassword)}
            autoFocus
            autoComplete="current-password"
            id="current-password"
            label={translations.currentPassword}
            type="password"
            errorMessage={errors?.currentPassword?.message}
            isTouched={touchedFields?.currentPassword}
          />

          <TextField
            {...register('password', formValidation.password)}
            autoComplete="new-password"
            id="new-password"
            label={translations.password}
            type="password"
            errorMessage={errors?.password?.message}
            isTouched={touchedFields?.password}
          />

          <TextField
            {...register(
              'passwordVerification',
              formValidation.passwordVerification,
            )}
            autoComplete="off"
            id="password-verification"
            label={translations.passwordVerification}
            type="password"
            errorMessage={errors?.passwordVerification?.message}
            isTouched={touchedFields?.passwordVerification}
          />

          {isError && (
            <Typography sx={{ color: 'error.main' }}>
              {t('update-password.error')}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <SubmitButton loading={isLoading} disabled={!isValid}>
            {t('update-password.submit-button')}
          </SubmitButton>
        </DialogActions>
      </form>
    </>
  );
}
