import { createApi } from '@reduxjs/toolkit/query/react';

import {
  QR_CODE_CONFIG_ENDPOINT,
  QR_CODE_GENERATE_IDENTIFIER_ENDPOINT,
} from 'src/config/api-routes';
import { baseQueryBuilder } from './base-query.builder';
import {
  QrCodeGenerateIdentifierResponseModel,
  QrCodeGetConfigResponseModel,
} from '../model/qr-code.model';

export const qrCodeApi = createApi({
  reducerPath: 'qrCodeApi',
  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getConfig: builder.query<QrCodeGetConfigResponseModel, void>({
      query: () => QR_CODE_CONFIG_ENDPOINT,
    }),

    generateIdentifier: builder.mutation<
      QrCodeGenerateIdentifierResponseModel,
      void
    >({
      query: () => ({
        url: QR_CODE_GENERATE_IDENTIFIER_ENDPOINT,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGenerateIdentifierMutation, useGetConfigQuery } = qrCodeApi;
