import { Logger } from 'src/core/logger/logger';

function isNotNullish(value: string | null): boolean {
  return ![null, undefined, ''].includes(value);
}

interface StorageProps {
  storage: Storage;
}

interface GetFromStrageProps<T> extends StorageProps {
  key: string;
  defaultValue: T;
}

function getFromStorage<T>({
  storage,
  key,
  defaultValue,
}: GetFromStrageProps<T>): T {
  const { [key]: value }: Storage = storage;

  if (!isNotNullish(value)) {
    Logger.debug(
      `Cannot retrieve ${key} from storage. Returned default value:`,
      defaultValue,
    );
    return defaultValue;
  }

  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

export function getFromLocalStorage<T>(key: string, defaultValue: T): T {
  return getFromStorage({ storage: localStorage, key, defaultValue });
}

interface SetInStorageProps extends StorageProps {
  key: string;
  value: string;
}

function setInStorage({ storage, key, value }: SetInStorageProps): string {
  try {
    const valueToStore =
      typeof value === 'string' ? value : JSON.stringify(value);
    storage.setItem(key, valueToStore);
  } catch (e) {
    Logger.error(`Cannot set ${key} from storage`, e);
  }

  return value;
}

export function setInLocalStorage(key: string, value: string): string {
  return setInStorage({ storage: localStorage, key, value });
}

interface RemoveInStorageProps extends StorageProps {
  key: string;
}

function removeInStorage({ storage, key }: RemoveInStorageProps): void {
  try {
    storage.removeItem(key);
  } catch (e) {
    Logger.error(`Cannot remove ${key} from storage`, e);
  }
}

export function removeInLocalStorage(key: string): void {
  removeInStorage({ storage: localStorage, key });
}
