import {
  BaseLogResponseModel,
  EmspWebAppLogResponseModel,
  LogModel,
  LogResponseModel,
} from '../model/log.model';

function isEmspWebAppLog(
  response: LogResponseModel,
): response is BaseLogResponseModel & EmspWebAppLogResponseModel {
  return 'level' in response && 'message' in response;
}

export function mapLogResponseModelToLogModel(
  response: LogResponseModel,
): LogModel {
  if (isEmspWebAppLog(response)) {
    return { ...response, payload: response.message };
  }

  const { evse, location, session, cdr, connector, command } = response;

  return {
    ...response,
    payload: evse ?? location ?? session ?? cdr ?? connector ?? command,
  };
}
