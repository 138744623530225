import { useMemo } from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  DataGrid as MuiDataGrid,
} from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { MAX_PAGE_SIZE, PAGE_SIZE_OPTIONS } from 'src/config/data-grid.config';
import {
  DataGridQueryModel,
  FilterQueryModel,
  FilterDefinitionModel,
} from 'src/core/model/data-grid.model';
import { Search } from 'src/feature/data-grid/search/search';
import Stack from '@mui/material/Stack';
import { DataGridPropsWithDefaultValues } from '@mui/x-data-grid/models/props/DataGridProps';
import { useTranslation } from 'react-i18next';

import { Toolbar } from './toolbar';
import { DataGridContainer } from './data-grid.container';
import { ActiveFilter } from './filter/active-filter/active-filter';
import { FilterDrawer } from './filter/filter-drawer/filter-drawer';

type DataGridProps<T> = {
  readonly rows: T[];
  readonly loading: boolean;
  readonly filterDefinitionModel?: FilterDefinitionModel[];
  readonly columns: GridColDef[];
  readonly rowCount: number;
  readonly initialState?: GridInitialStateCommunity;
  readonly getRowId?: (row: T) => string | number;

  readonly model?: DataGridQueryModel;
  readonly onModelChange?: (query: DataGridQueryModel) => void;
};

export function DataGrid<T>(props: DataGridProps<T>): React.JSX.Element {
  const {
    model,
    initialState,
    filterDefinitionModel = [],
    columns,
    onModelChange = () => {},
    rowCount,
    ...dataGridProps
  } = props;

  const { t } = useTranslation();

  const handlePaginate = (payload: GridPaginationModel) => {
    onModelChange({ paginate: payload });
  };

  const handleSort = (payload: GridSortModel) => {
    onModelChange({ sort: payload });
  };

  const handleSearch = (payload?: string) => {
    onModelChange({ textSearch: payload });
  };

  const handleFilter = (payload: FilterQueryModel) => {
    onModelChange({ filter: payload });
  };

  const pageSizeOptions: DataGridPropsWithDefaultValues['pageSizeOptions'] =
    useMemo(() => {
      if (Math.max(...PAGE_SIZE_OPTIONS) > rowCount) {
        return PAGE_SIZE_OPTIONS;
      }

      return [
        ...PAGE_SIZE_OPTIONS,
        {
          label:
            rowCount > MAX_PAGE_SIZE
              ? MAX_PAGE_SIZE.toString()
              : t('data-grid.pagination.all-pages'),
          value: Math.min(rowCount, MAX_PAGE_SIZE),
        },
      ];
    }, [rowCount, t]);

  return (
    <>
      <Stack gap={2} direction="row" alignItems="center">
        <Search
          sx={{ flex: 1 }}
          initialValue={model?.textSearch}
          onSearch={handleSearch}
        />

        <FilterDrawer
          filterDefinition={filterDefinitionModel}
          filterQuery={model?.filter}
          onFilter={handleFilter}
        />
      </Stack>

      <ActiveFilter
        filterDefinition={filterDefinitionModel}
        filterQuery={model?.filter}
        onChange={handleFilter}
      />

      <DataGridContainer>
        <MuiDataGrid
          rowCount={rowCount}
          columns={columns}
          sx={{ height: '100%' }}
          pageSizeOptions={pageSizeOptions}
          slots={{
            toolbar: Toolbar,
          }}
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
          disableColumnFilter
          initialState={initialState}
          paginationMode="server"
          onPaginationModelChange={handlePaginate}
          paginationModel={model?.paginate}
          sortingMode="server"
          onSortModelChange={handleSort}
          sortModel={model?.sort}
          {...dataGridProps}
        />
      </DataGridContainer>
    </>
  );
}
