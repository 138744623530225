import { Navigate } from 'react-router-dom';
import { canAccess } from 'src/core/helper/can-access';
import { APP_PATH } from 'src/config/app-paths';
import { RoleEnum } from 'src/core/model/enum/role.enum';
import { PropsWithChildren, ReactNode } from 'react';
import { getUserRole } from 'src/core/authentication/authentication.slice';
import { useAppSelector } from 'src/core/helper/redux.hook';

type RoleGuardProps = {
  role?: RoleEnum;
};

export function RoleGuard(
  props: Readonly<PropsWithChildren<RoleGuardProps>>,
): ReactNode {
  const { role, children } = props;
  const userRole: RoleEnum | null = useAppSelector(getUserRole);

  if (userRole && canAccess(userRole, role)) {
    return children;
  }

  return <Navigate to={APP_PATH.DASHBOARD} />;
}
