import { useMemo } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ApiErrorModel } from '../model/api-error.model';

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export function isErrorWithStatus(error: unknown): error is { status: number } {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    typeof (error as any).status === 'number'
  );
}

export function getErrorMessage(err: unknown): string | null {
  if (isFetchBaseQueryError(err)) {
    if ('error' in err) {
      return err.error;
    }

    if ('data' in err && (err.data as ApiErrorModel)?.message) {
      return (err.data as ApiErrorModel).message ?? null;
    }

    return JSON.stringify(err.data);
  }

  if (isErrorWithMessage(err)) {
    return err.message;
  }

  return null;
}

export function getErrorStatus(err: unknown): number | null {
  if (!isErrorWithStatus(err)) {
    return null;
  }

  return err.status;
}

export function getErrorCode(err: unknown): string | null {
  if (isFetchBaseQueryError(err)) {
    if ('data' in err && (err.data as ApiErrorModel)?.errorCode) {
      return (err.data as ApiErrorModel).errorCode ?? null;
    }
  }

  return null;
}

type ParseApiError = {
  status: number | null;
  message: string | null;
  code: string | null;
};

export function parseApiError(err: unknown): ParseApiError {
  return {
    status: getErrorStatus(err),
    message: getErrorMessage(err),
    code: getErrorCode(err),
  };
}

export function useApiError(err: unknown): ParseApiError {
  return useMemo(() => parseApiError(err), [err]);
}
