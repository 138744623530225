import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import desktopComputerUrl from 'src/asset/desktop-computer.png';
import lockUrl from 'src/asset/lock.png';

import { authenticate } from 'src/core/authentication/authentication.slice';
import { InAppStorage } from 'src/core/storage/in-app-storage';
import { APP_PATH } from 'src/config/app-paths';

import { EmailPasswordStepController } from './step/email-password-step/email-password-step.controller';
import { EmailOtpStepController } from './step/email-otp-step/email-otp-step.controller';
import { LoginLayout } from './component/login-layout/login.layout';
import { ImageHero } from './component/image-hero/image-hero';

enum LoginStepEnum {
  PASSWORD_STEP,
  OTP_STEP,
}

export function Login(): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailAddress, setEmailAddress] = useState<string>();
  const [step, setStep] = useState<LoginStepEnum>(LoginStepEnum.PASSWORD_STEP);

  function handleAuthenticate(jwt: string) {
    InAppStorage.jwt = jwt;
    dispatch(authenticate(jwt));
    navigate(APP_PATH.DASHBOARD);
  }

  function handleEmailPasswordStep(payload: string) {
    setEmailAddress(payload);
    setStep(LoginStepEnum.OTP_STEP);
  }

  return (
    <LoginLayout>
      {step === LoginStepEnum.PASSWORD_STEP ? (
        <>
          <ImageHero src={desktopComputerUrl} alt="destkop computer" />
          <EmailPasswordStepController onNext={handleEmailPasswordStep} />
        </>
      ) : (
        <>
          <ImageHero src={lockUrl} alt="lock" />
          <EmailOtpStepController
            emailAddress={emailAddress}
            onNext={handleAuthenticate}
          />
        </>
      )}
    </LoginLayout>
  );
}
