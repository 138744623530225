import { useMemo, useState } from 'react';
import {
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CheckoutFeeModel,
  EnhancedQuarterOverviewModel,
  QuarterOverviewModel,
} from 'src/core/model/quarter-overview.model';

import { isDelmonicosLine } from '../quarter-overview.utils';
import { QuarterOverviewTableRow } from './quarter-overview.table-row';
import { QuarterOverviewTableHead } from './quarter-overview.table-head';
import { EnhanceQuarterOverview } from '../enhance-quarter-overview/enhance-quarter-overview';

type Props = {
  readonly lmsLines?: QuarterOverviewModel[];
  readonly rawCheckoutList?: CheckoutFeeModel[];
  readonly onChange: (
    id: string,
    payload: Partial<EnhancedQuarterOverviewModel>,
  ) => void;
};

export function QuarterOverviewTable(props: Props): React.JSX.Element {
  const { lmsLines = [], rawCheckoutList = [], onChange } = props;
  const { t } = useTranslation();

  const [showOnlyDelmonicosLines, setShowOnlyDelmonicosLines] = useState(true);
  const delmonicosLines = useMemo(
    () => lmsLines.filter(isDelmonicosLine),
    [lmsLines],
  );

  const lmsLinesToShow = useMemo(
    () => (showOnlyDelmonicosLines ? delmonicosLines : lmsLines),
    [showOnlyDelmonicosLines, delmonicosLines, lmsLines],
  );

  return (
    <>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent="space-between">
        <FormControlLabel
          control={
            <Switch
              onChange={(event) =>
                setShowOnlyDelmonicosLines(event.target.checked)
              }
              checked={showOnlyDelmonicosLines}
            />
          }
          label={t('quarter-overview.show-only-delmonicos-lines')}
        />

        <div>
          <Typography>
            {t('quarter-overview.stats.lms-lines', {
              count: lmsLines.length,
            })}
          </Typography>
          <Typography>
            {t('quarter-overview.stats.delmonicos-lines', {
              count: delmonicosLines.length,
            })}
          </Typography>
          <Typography>
            {t('quarter-overview.stats.checkout-lines', {
              count: rawCheckoutList.length,
            })}
          </Typography>
        </div>
      </Stack>

      <TableContainer component={Paper}>
        <Table stickyHeader>
          <QuarterOverviewTableHead />

          <TableBody>
            {lmsLinesToShow.length === 0 && (
              <TableRow>
                <TableCell colSpan={18} sx={{ textAlign: 'center' }}>
                  <Typography variant="subtitle1">
                    {t('quarter-overview.empty-table')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {lmsLinesToShow.map((data) =>
              isDelmonicosLine(data) ? (
                <EnhanceQuarterOverview
                  key={data.id}
                  rawCheckoutList={rawCheckoutList}
                  quarterOverview={data}
                  onChange={(payload) => onChange(data.id, payload)}
                />
              ) : (
                <QuarterOverviewTableRow key={data.id} {...data} />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
