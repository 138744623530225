import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterQueryModel,
  FilterDefinitionModel,
} from 'src/core/model/data-grid.model';

type ActiveFilterProps = {
  filterDefinition: FilterDefinitionModel[];
  filterQuery?: FilterQueryModel;
  onChange: (payload: FilterQueryModel) => void;
};

export function ActiveFilter(
  props: Readonly<ActiveFilterProps>,
): React.JSX.Element | null {
  const { filterDefinition, filterQuery = {}, onChange } = props;

  const { t } = useTranslation();

  const handleDelete = (...filterIds: string[]) => {
    const newFilter = { ...filterQuery };

    filterIds.forEach((filterId) => {
      if (filterId in newFilter) {
        delete newFilter[filterId];
      }
    });

    onChange(newFilter);
  };

  const activeFilterList: ReactNode[] = filterDefinition
    .map(
      ({ getChip }: FilterDefinitionModel): ReactNode =>
        getChip(filterQuery, handleDelete),
    )
    .filter((component: ReactNode) => !!component);

  if (activeFilterList.length === 0) {
    return null;
  }

  return (
    <Box>
      <Typography variant="caption" display="block">
        {t('data-grid.active-filter.title', {
          count: activeFilterList.length,
        })}
      </Typography>

      <Stack direction="row" columnGap={2}>
        {activeFilterList}
      </Stack>
    </Box>
  );
}
