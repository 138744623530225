import { useEffect, useMemo } from 'react';
import {
  useGenerateIdentifierMutation,
  useGetConfigQuery,
} from 'src/core/service/qr-code.service';
import { useTranslation } from 'react-i18next';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useApiError } from 'src/core/helper/use-api-error';
import { convertToHttpHost } from 'src/core/helper/convert-to-http-host';
import { QrCodeDialog } from '../qr-code-dialog/qr-code-dialog';

type QrCodeGeneratorProps = {
  readonly chargePointId: string;
  readonly onSuccess?: () => void;
};

export function QrCodeGenerator(
  props: QrCodeGeneratorProps,
): React.JSX.Element {
  const { chargePointId, onSuccess = () => {} } = props;
  const { t } = useTranslation();

  const { data: qrCodeConfig, isError: isErrorConfig } = useGetConfigQuery();

  const [
    generateIdentifier,
    {
      data: qrCodeIdentifier,
      isError: isErrorIdentifier,
      error: generateIdentifierError,
    },
  ] = useGenerateIdentifierMutation();
  const {
    status: generateIdentifierStatus,
    message: generateIdentifierMessage,
  } = useApiError(generateIdentifierError);

  const tinyUrl = useMemo(() => {
    if (!qrCodeIdentifier?.qrCodeIdentifier || !qrCodeConfig?.baseTinyUrl) {
      return null;
    }

    return new URL(
      qrCodeIdentifier.qrCodeIdentifier,
      convertToHttpHost(qrCodeConfig.baseTinyUrl),
    ).toString();
  }, [qrCodeIdentifier?.qrCodeIdentifier, qrCodeConfig?.baseTinyUrl]);

  useEffect(() => {
    generateIdentifier();
  }, [generateIdentifier]);

  return (
    <>
      <QrCodeDialog
        chargePointId={chargePointId}
        tinyId={qrCodeIdentifier?.qrCodeIdentifier}
        tinyUrl={tinyUrl}
        onSuccess={onSuccess}
      />

      {isErrorConfig && (
        <Snackbar variant="error" message={t('error.get-config-query')} />
      )}

      {isErrorIdentifier && (
        <Snackbar
          variant="error"
          message={t('error.generate-identifier-mutation', {
            status: generateIdentifierStatus,
            message: generateIdentifierMessage,
          })}
        />
      )}
    </>
  );
}
