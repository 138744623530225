import { PriceComponentModel } from 'src/core/model/tariff.model';
import { useTranslation } from 'react-i18next';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { TextField } from 'src/shared/form/text-field';
import { Select } from 'src/shared/form/select';
import { MenuItem } from '@mui/material';
import { TariffDimensionTypeEnum } from 'src/core/model/enum/tariff-dimension-type.enum';
import { usePriceComponentFormValidation } from './use-price-component.form-validation';

type Props = {
  prefixName: string;
  errors?: FieldErrors<PriceComponentModel>;
  touchedFields?: Partial<
    Readonly<{
      [x: string]: any;
    }>
  >;
};

export function PriceComponentForm(props: Readonly<Props>): React.JSX.Element {
  const { prefixName, errors, touchedFields } = props;
  const { t } = useTranslation();
  const { register, control, watch } = useFormContext();

  const translations: Record<keyof PriceComponentModel, string> = t(
    'model.price-component',
    {
      returnObjects: true,
    },
  );

  const priceType = watch(`${prefixName}.type`) as TariffDimensionTypeEnum;

  const formValidation = usePriceComponentFormValidation();

  return (
    <>
      <Controller
        control={control}
        rules={formValidation.type}
        name={`${prefixName}.type`}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            errorMessage={errors?.type?.message as string}
            isTouched={touchedFields?.type}
            label={translations.type}
            labelId={`${prefixName}.type-select`}
            value={value}
            onChange={onChange}
            ref={ref}
            size="small">
            {Object.keys(TariffDimensionTypeEnum).map((key) => (
              <MenuItem value={key} key={key}>
                {t(`model.enum.tariff-dimension-type.${key}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      <TextField
        {...register(`${prefixName}.price`, formValidation.price)}
        size="small"
        id={`${prefixName}.price`}
        label={translations.price}
        type="number"
        errorMessage={errors?.price?.message as string}
        isTouched={touchedFields?.price}
        endAdornment={t(
          `tariff.form.tariff-element.price-component.price-adornment-label.${priceType}`,
        )}
      />

      <TextField
        {...register(`${prefixName}.stepSize`, formValidation.stepSize)}
        size="small"
        type="number"
        id={`${prefixName}.stepSize`}
        label={translations.stepSize}
        errorMessage={errors?.stepSize?.message as string}
        isTouched={touchedFields?.stepSize}
        endAdornment={t(
          `tariff.form.tariff-element.price-component.step-size-adornment-label.${priceType}`,
        )}
      />
    </>
  );
}
