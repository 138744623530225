import { Options } from 'qr-code-styling';

import delmonicosTemplate from './delmonicos-template.json';

export const QRCodeTemplateConfig: Record<string, Options> = {
  delmonicos: delmonicosTemplate as unknown as Options,
};

export const DEFAULT_DIMENSIONS = 300;
export const DEFAULT_TEMPLATE = 'delmonicos';
