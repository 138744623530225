import { SupportedLocaleEnum } from 'src/core/model/enum/supported-locale.enum';
import { I18nFormatterFunctionType } from './i18n-formatter.type';

function padTo2(value: number, language: string): string {
  return value.toLocaleString(language, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

export const durationFormatter: I18nFormatterFunctionType = (
  seconds: number,
  language: string = SupportedLocaleEnum.fr,
): string => {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const second = Math.floor(seconds % 60);
  return `${padTo2(hour, language)}:${padTo2(minute, language)}:${padTo2(
    second,
    language,
  )}`;
};
