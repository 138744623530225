import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceStatusEnum } from 'src/core/model/enum/invoice-status.enum';
import { useUidColumn } from 'src/feature/data-grid/column/use-uid-column';
import { useCurrencyColumn } from 'src/feature/data-grid/column/use-currency-column';
import { useDatetimeColumn } from 'src/feature/data-grid/column/use-datetime-column';
import { OrderResponseModel } from 'src/core/model/order.model';
import { useTextColumn } from 'src/feature/data-grid/column/use-text-column';
import { usePseudoColumn } from 'src/feature/data-grid/column/use-pseudo-column';

export function useOrderColumn(): GridColDef[] {
  const { t } = useTranslation();

  const textColumn = useTextColumn();
  const uidColumn = useUidColumn();
  const currencyColumn = useCurrencyColumn();
  const datetimeColumn = useDatetimeColumn();
  const pseudoColumn = usePseudoColumn();

  const orderTranslations: Record<keyof OrderResponseModel, string> = t(
    'model.order',
    {
      returnObjects: true,
    },
  );

  return useMemo(
    () => [
      {
        ...uidColumn,
        field: 'id',
        headerName: orderTranslations.id,
        sortable: false,
      },
      {
        ...pseudoColumn,
        field: 'customerPseudo',
        headerName: orderTranslations.customerPseudo,
      },
      {
        ...textColumn,
        field: 'status',
        headerName: orderTranslations.status,
        width: 240,
        sortable: false,
        valueFormatter: (
          params: GridValueFormatterParams<InvoiceStatusEnum>,
        ): string =>
          t(`model.enum.invoice-status.${params.value}`, params.value),
      },
      {
        ...textColumn,
        field: 'chargePointName',
        headerName: orderTranslations.chargePointName,
        width: 200,
        sortable: false,
      },
      {
        ...datetimeColumn,
        field: 'createdAt',
        headerName: orderTranslations.createdAt,
      },
      {
        ...datetimeColumn,
        field: 'updatedAt',
        headerName: orderTranslations.updatedAt,
      },
      {
        ...currencyColumn,
        field: 'totalTTC',
        headerName: orderTranslations.totalTTC,
        sortable: false,
      },
    ],
    [
      t,
      orderTranslations,
      uidColumn,
      currencyColumn,
      datetimeColumn,
      textColumn,
      pseudoColumn,
    ],
  );
}
