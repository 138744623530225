import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { PasswordLoginModel } from 'src/core/model/login.model';
import { TextField } from 'src/shared/form/text-field';
import { SubmitButton } from 'src/shared/button/submit.button';

import {
  EmailPasswordForm,
  useEmailPasswordFormValidation,
} from './use-email-password.form-validation';
import { BodyLayout } from '../../component/login-layout/body.layout';

type EmailPasswordStepViewProps = {
  readonly pending: boolean;
  readonly isError: boolean;
  readonly onSubmit: (payload: EmailPasswordForm) => void;
};

export function EmailPasswordStepView(
  props: EmailPasswordStepViewProps,
): React.JSX.Element {
  const { pending, isError, onSubmit } = props;

  const { t } = useTranslation();
  const formValidation = useEmailPasswordFormValidation();

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<EmailPasswordForm>({
    mode: 'onChange',
  });

  const loginTranslations: Record<keyof PasswordLoginModel, string> = t(
    'model.password-login',
    {
      returnObjects: true,
    },
  );

  const handleTrimThenSubmit = (payload: EmailPasswordForm): void => {
    onSubmit({
      emailAddress: payload.emailAddress.trim(),
      password: payload.password,
    });
  };

  return (
    <BodyLayout component="form" onSubmit={handleSubmit(handleTrimThenSubmit)}>
      <Stack>
        <Typography component="h1" variant="h2">
          {t('login.email-password-step.title')}
        </Typography>

        <Typography variant="subtitle1">
          {t('login.email-password-step.subtitle')}
        </Typography>
      </Stack>

      <Stack gap={3}>
        <TextField
          {...register('emailAddress', formValidation.emailAddress)}
          id="email-address"
          label={loginTranslations.emailAddress}
          autoComplete="email"
          autoFocus
          errorMessage={errors?.emailAddress?.message}
          isTouched={touchedFields?.emailAddress}
        />

        <TextField
          {...register('password', formValidation.password)}
          label={loginTranslations.password}
          type="password"
          id="password"
          autoComplete="current-password"
          errorMessage={errors?.password?.message}
          isTouched={touchedFields?.password}
        />

        {isError && (
          <Typography sx={{ color: 'error.main' }}>
            {t('login.email-password-step.error-credentials')}
          </Typography>
        )}
      </Stack>

      <SubmitButton loading={pending} disabled={!isValid} fullWidth>
        {t('login.email-password-step.submit-button')}
      </SubmitButton>
    </BodyLayout>
  );
}
