import { useTranslation } from 'react-i18next';
import { FilterDefinitionModel } from 'src/core/model/data-grid.model';
import { LogFilterModel } from 'src/core/model/log.model';
import { useBooleanFilter } from 'src/feature/data-grid/filter/generic-filter/use-boolean-filter';
import { useMonthFilter } from 'src/feature/data-grid/filter/generic-filter/use-month-filter';
import { useYearFilter } from 'src/feature/data-grid/filter/generic-filter/use-year-filter';

export function useGenericLogFilter(): FilterDefinitionModel[] {
  const { t } = useTranslation();
  const logTranslations: Record<keyof LogFilterModel, string> = t(
    'model.filter-log',
    {
      returnObjects: true,
    },
  );

  const searchOnOcpiLogsFilter = useBooleanFilter(
    'searchOnOcpiLogs',
    logTranslations.searchOnOcpiLogs,
  );
  const searchOnEmspWebAppLogsFilter = useBooleanFilter(
    'searchOnEmspWebAppLogs',
    logTranslations.searchOnEmspWebAppLogs,
  );
  const monthFilter = useMonthFilter({
    field: 'monthFilter',
    label: logTranslations.monthFilter,
  });
  const yearFilter = useYearFilter({
    field: 'yearFilter',
    label: logTranslations.yearFilter,
  });

  return [
    searchOnOcpiLogsFilter,
    searchOnEmspWebAppLogsFilter,
    monthFilter,
    yearFilter,
  ];
}
