import { CircularProgress, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import { QuarterOverviewStateEnum } from 'src/core/model/enum/quarter-overview-state.enum';

type Props = {
  readonly state?: QuarterOverviewStateEnum;
};

export function QuarterOverviewStatus(props: Props): React.JSX.Element {
  const { state = QuarterOverviewStateEnum.INIT } = props;
  const { t } = useTranslation();

  switch (state) {
    case QuarterOverviewStateEnum.LOADING:
      return <CircularProgress size={24} />;

    case QuarterOverviewStateEnum.MULTIPLE_ITEM_FOUND:
      return (
        <Tooltip title={t('quarter-overview.multiple-found')}>
          <WarningIcon color="warning" />
        </Tooltip>
      );

    case QuarterOverviewStateEnum.QUERY_ERROR:
      return (
        <Tooltip title={t('quarter-overview.query-error')}>
          <ErrorIcon color="error" />
        </Tooltip>
      );
    case QuarterOverviewStateEnum.NO_ITEM_FOUND:
      return (
        <Tooltip title={t('quarter-overview.no-found')}>
          <BlockIcon color="error" />
        </Tooltip>
      );

    case QuarterOverviewStateEnum.SUCCESS:
      return <CheckCircleIcon color="success" />;

    default:
      return <HorizontalRuleIcon />;
  }
}
