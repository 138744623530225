import {
  FilterDefinitionModel,
  FilterFormItemProps,
} from 'src/core/model/data-grid.model';
import { FormControlLabel, Switch } from '@mui/material';
import { ReactNode } from 'react';

import { FilterItem } from './filter-item';
import { useSimpleChip } from './use-simple-chip';

export function useBooleanFilter(
  field: string,
  label: string,
): FilterDefinitionModel {
  const getChip = useSimpleChip({ field, label });

  return {
    getFilter: (props: FilterFormItemProps): ReactNode => {
      const { values, setValues } = props;

      return (
        <FilterItem key={field} label={label}>
          <FormControlLabel
            control={
              <Switch
                checked={!!values[field]}
                onChange={(event) => {
                  setValues((prev) => ({
                    ...prev,
                    [field]: event.target.checked,
                  }));
                }}
              />
            }
            label={label}
          />
        </FilterItem>
      );
    },

    getChip,
  };
}
