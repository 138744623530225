import { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OcpiLogModel } from 'src/core/model/log.model';
import { useTextColumn } from 'src/feature/data-grid/column/use-text-column';
import { useUidColumn } from 'src/feature/data-grid/column/use-uid-column';

export function useOcpiLogColumn(): GridColDef[] {
  const { t } = useTranslation();

  const textColumn = useTextColumn();
  const uidColumn = useUidColumn();

  const logTranslations: Record<keyof OcpiLogModel, string> = t(
    'model.ocpi-log',
    {
      returnObjects: true,
    },
  );

  return useMemo(
    () => [
      {
        ...uidColumn,
        field: 'locationId',
        width: 120,
        headerName: logTranslations.locationId,
        sortable: false,
      },
      {
        ...textColumn,
        field: 'version',
        width: 80,
        headerName: logTranslations.version,
        sortable: false,
      },
      {
        ...textColumn,
        field: 'action',
        width: 80,
        headerName: logTranslations.action,
        sortable: false,
      },
    ],
    [uidColumn, textColumn, logTranslations],
  );
}
