import { getDecodedJwt, isValidJwt } from '../helper/is-valid-jwt';
import { RoleEnum } from '../model/enum/role.enum';
import { AuthenticationStateModel } from './authentication-state.model';

export function getStateFromJwt(
  jwt: string | null,
  defaultState: AuthenticationStateModel,
): AuthenticationStateModel {
  if (!jwt) {
    return defaultState;
  }

  const decodedJwt = jwt ? getDecodedJwt(jwt) : null;
  if (decodedJwt === null || !isValidJwt(decodedJwt)) {
    return defaultState;
  }

  return {
    token: jwt,
    userId: decodedJwt.sub,
    role: decodedJwt.role as RoleEnum,
  };
}
