import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';

export function Toolbar(): React.JSX.Element {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />

      <GridToolbarDensitySelector />

      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ allColumns: true }}
      />
    </GridToolbarContainer>
  );
}
