import {
  GridColTypeDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useDialog } from 'src/core/helper/use-dialog';
import { JsonViewDialog } from 'src/shared/json-view/json-view.dialog';
import { TextOverflow } from 'src/shared/typography/text-overflow';

type JsonCellProps = {
  maxWidth: number;
  title?: string;
  value?: any;
};

function JsonCell(props: Readonly<JsonCellProps>): React.JSX.Element | null {
  const { maxWidth, title, value = '' } = props;
  const { isOpen, onClose, onOpen } = useDialog();

  if (!value) {
    return null;
  }

  return (
    <>
      <TextOverflow onClick={onOpen} maxWidth={maxWidth}>
        {value}
      </TextOverflow>
      <JsonViewDialog
        payload={value}
        onClose={onClose}
        isOpen={isOpen}
        title={title}
      />
    </>
  );
}

export function useJsonColumn(): GridColTypeDef {
  return {
    type: 'string',
    width: 300,
    valueFormatter(params: GridValueFormatterParams<string>): string {
      return JSON.stringify(params.value);
    },
    renderCell: (params: GridRenderCellParams): React.JSX.Element | null => (
      <JsonCell
        title={params.colDef.headerName}
        maxWidth={params.colDef.computedWidth}
        value={params.formattedValue}
      />
    ),
  };
}
