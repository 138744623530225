import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/core/store';
import { InAppStorage } from '../storage/in-app-storage';
import { getStateFromJwt } from './get-state-from-jwt';
import { AuthenticationStateModel } from './authentication-state.model';
import { RoleEnum } from '../model/enum/role.enum';

export const defaultState = {
  token: null,
  userId: null,
  role: null,
};

const { jwt } = InAppStorage;
const initialState: AuthenticationStateModel = getStateFromJwt(
  jwt,
  defaultState,
);

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    authenticate: (
      currentState: AuthenticationStateModel,
      action: PayloadAction<string>,
    ): AuthenticationStateModel => {
      return getStateFromJwt(action.payload, currentState);
    },
    disconnect: (): AuthenticationStateModel => {
      return defaultState;
    },
  },
});

export const { authenticate, disconnect } = authenticationSlice.actions;

export const isAuthenticated = (state: RootState): boolean =>
  !!state.authentication.userId;

export const getJwt = (state: RootState): string | null =>
  state.authentication.token;

export const getUserId = (state: RootState): string | null =>
  state.authentication.userId;

export const getUserRole = (state: RootState): RoleEnum | null =>
  state.authentication.role;

export const authenticationReducer = authenticationSlice.reducer;
