import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { DrawerMenuItem } from 'src/shared/layout/dashboard-layout/drawer-menu/drawer-menu-item';
import { useGetCurrentUserQuery } from 'src/core/service/current-user.service';
import { Avatar } from 'src/shared/avatar/avatar';
import { useState } from 'react';
import { UserMenu } from 'src/shared/layout/dashboard-layout/user-menu/user-menu';
import { DrawerMenuBuilder } from 'src/shared/layout/dashboard-layout/drawer-menu/drawer-menu-builder';
import { RoutingConfiguration } from 'src/config/routing.config';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useLocation } from 'react-router-dom';
import { Brand } from './brand';

interface DrawerMenuProps {
  readonly onClose: () => void;
}

export function DrawerMenu(props: DrawerMenuProps): React.JSX.Element {
  const { t } = useTranslation();
  const { onClose } = props;
  const { data: user, isError } = useGetCurrentUserQuery();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const location = useLocation();

  return (
    <>
      <Brand onClose={onClose} />

      <List component="nav" sx={{ flex: 1, width: '100%' }}>
        <DrawerMenuBuilder
          configuration={RoutingConfiguration}
          pathname={location.pathname}
        />
      </List>

      <Divider />

      <List>
        <DrawerMenuItem
          label={
            user
              ? `${user?.firstName} ${user?.lastName}`
              : t('shared.layout.user-menu.label-fallback')
          }
          icon={<Avatar trigram={user?.trigram} />}
          onClick={handleOpenUserMenu}
        />

        <UserMenu
          onClose={handleCloseUserMenu}
          anchor={anchorElUser}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        />
      </List>

      {isError && (
        <Snackbar variant="error" message={t('error.get-current-user-query')} />
      )}
    </>
  );
}
