import { useTranslation } from 'react-i18next';
import { ChargingSessionStatusEnum } from 'src/core/model/enum/charging-session-status.enum';

type GetChargingSessionStatusTextHookReturn = (
  status: ChargingSessionStatusEnum,
) => string;

export function useGetChargingSessionStatusText(): GetChargingSessionStatusTextHookReturn {
  const { t } = useTranslation();

  return (statusNumber: ChargingSessionStatusEnum) => {
    const statusText = t(
      `model.enum.charging-session-status-enum.${statusNumber}`,
    );

    return `${statusNumber} - ${statusText}`;
  };
}
