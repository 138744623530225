import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  IconButton,
  List,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { SkeletonList } from 'src/shared/skeleton/skeleton-list';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { APP_PATH } from 'src/config/app-paths';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { generateDataGridRoute } from 'src/feature/data-grid/use-data-grid-search-params/generate-data-grid-route';
import { useGetListChargingSessionQuery } from 'src/core/service/charging-session.service';
import { ChargingSessionListItem } from 'src/shared/charging-session-list-item/charging-session.list-item';
import BoltIcon from '@mui/icons-material/Bolt';

type CustomerChargingSessionListProps = CardProps & {
  pseudo?: string;
};

export function CustomerChargingSessionList(
  props: Readonly<CustomerChargingSessionListProps>,
): React.JSX.Element {
  const { pseudo = '', sx, ...cardProps } = props;
  const { t } = useTranslation();

  const { data, isFetching, isError, isUninitialized } =
    useGetListChargingSessionQuery(
      {
        paginate: {
          pageSize: 3,
          page: 0,
        },
        filter: {
          pseudo,
        },
        sort: [
          {
            field: 'stoppedAt',
            sort: 'desc',
          },
        ],
      },
      {
        skip: !pseudo,
      },
    );

  return (
    <>
      <Card sx={{ overflow: 'unset', ...sx }} {...cardProps}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: 'primary.light' }}>
              <BoltIcon color="primary" />
            </Avatar>
          }
          title={t('customer.detail.charging-session-section')}
        />

        <CardContent>
          {isFetching || isUninitialized ? (
            <SkeletonList />
          ) : (
            <List>
              {data?.docs.length === 0
                ? t('customer.detail.no-charging-session')
                : data?.docs.map((chargingSession) => (
                    <ChargingSessionListItem
                      key={chargingSession.id}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          component={RouterLink}
                          target="_blank"
                          to={generatePath(APP_PATH.CHARGING_SESSION_DETAIL, {
                            chargingSessionId: chargingSession.id,
                          })}>
                          <OpenInNewIcon />
                        </IconButton>
                      }
                      startedAt={chargingSession.startedAt}
                      city={chargingSession.locationCity}
                      locationName={chargingSession.locationName}
                      totalKwh={chargingSession.energyDelivered}
                    />
                  ))}
            </List>
          )}
        </CardContent>

        {pseudo && data?.hasNextPage && (
          <CardActions>
            <Button
              component={RouterLink}
              target="_blank"
              to={generateDataGridRoute(APP_PATH.LIST_CHARGING_SESSION, {
                filter: { pseudo },
              })}>
              {t('customer.detail.see-more')}
            </Button>
          </CardActions>
        )}
      </Card>

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.get-list-charging-session-query')}
        />
      )}
    </>
  );
}
