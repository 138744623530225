import { useState } from 'react';
import {
  CheckoutFeeModel,
  QuarterOverviewModel,
} from 'src/core/model/quarter-overview.model';
import { Stack } from '@mui/material';
import { CSV_MIME_TYPE } from 'src/config/app.config';
import { Dropzone } from 'src/shared/file-upload/dropzone';
import { QuarterOverviewFileUploadManager } from './quarter-overview-file-upload-manager';

type Props = {
  readonly onLmsUpload: (data: QuarterOverviewModel[]) => void;
  readonly onCheckoutUpload: (data: CheckoutFeeModel[]) => void;
};

export function QuarterOverviewUpload(props: Props): React.JSX.Element {
  const { onCheckoutUpload, onLmsUpload } = props;
  const [files, setFiles] = useState<File[]>([]);

  const handleFileListUpload = async (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }

    for (const file of fileList) {
      setFiles((prevFileList) => [...prevFileList, file]);
    }
  };

  return (
    <Stack direction="column" gap={3}>
      <Dropzone onChange={handleFileListUpload} accept={CSV_MIME_TYPE} />

      <Stack
        direction="row"
        rowGap={4}
        columnGap={3}
        flexWrap="wrap"
        justifyContent="center">
        {files.map((file) => (
          <QuarterOverviewFileUploadManager
            key={file.name}
            onCheckoutUpload={onCheckoutUpload}
            onLmsUpload={onLmsUpload}
            file={file}
            sx={{ flexBasis: 200, flexShrink: 0, flexGrow: 0 }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
