import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { ChargingSessionResponseModel } from 'src/core/model/charging-session.model';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import { SkeletonList } from '../skeleton/skeleton-list';

type ChargingSessionCardProps = CardProps &
  Partial<
    Omit<ChargingSessionResponseModel, 'status' | 'id' | 'did' | 'orderId'>
  > & {
    chargingSessionId?: string;
    chargingDuration?: number;
    parkingDuration?: number;
    isPending?: boolean;
  };

export function ChargingSessionCard(
  props: Readonly<ChargingSessionCardProps>,
): React.JSX.Element {
  const {
    chargingSessionId,
    locationName,
    locationCity,
    startedAt,
    stoppedAt,
    energyDelivered,
    sessionId,
    cpoId,
    tokenUid,
    contractId,
    evseUid,
    locationId,
    connectorId,
    chargingDuration,
    parkingDuration,
    sx,
    isPending = false,
    ...cardProps
  } = props;

  const { t } = useTranslation();

  return (
    <Card sx={{ overflow: 'unset', ...sx }} {...cardProps}>
      <CardContent
        component={Stack}
        direction={{ sm: 'column', md: 'row' }}
        gap={2}>
        <List sx={{ flexGrow: 1, flexShrink: 0 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: 'primary.light' }}>
                <ElectricalServicesIcon color="primary" />
              </Avatar>
            }
            title={t('charging-session.card.charging-data')}
          />

          {isPending ? (
            <SkeletonList />
          ) : (
            <>
              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('charging-session.card.location.label')}
                  secondary={
                    locationName || locationCity
                      ? t('charging-session.card.location.value', {
                          locationName,
                          city: locationCity,
                        })
                      : t('charging-session.card.fallback')
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ElectricBoltIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('charging-session.card.energyDelivered.label')}
                  secondary={
                    energyDelivered
                      ? t('charging-session.card.energyDelivered.value', {
                          value: energyDelivered,
                        })
                      : t('charging-session.card.fallback')
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <PlayCircleFilledIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('charging-session.card.startedAt.label')}
                  secondary={
                    startedAt
                      ? t('charging-session.card.startedAt.value', {
                          startedAt: new Date(startedAt),
                        })
                      : t('charging-session.card.fallback')
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <StopCircleIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('charging-session.card.endedAt.label')}
                  secondary={
                    stoppedAt
                      ? t('charging-session.card.endedAt.value', {
                          endedAt: new Date(stoppedAt),
                        })
                      : t('charging-session.card.fallback')
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <TimelapseIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('charging-session.card.chargingDuration.label')}
                  secondary={
                    typeof chargingDuration === 'number'
                      ? t('charging-session.card.chargingDuration.value', {
                          chargingDuration,
                        })
                      : t('charging-session.card.fallback')
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LocalParkingIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('charging-session.card.parkingDuration.label')}
                  secondary={
                    typeof parkingDuration === 'number'
                      ? t('charging-session.card.parkingDuration.value', {
                          parkingDuration,
                        })
                      : t('charging-session.card.fallback')
                  }
                />
              </ListItem>
            </>
          )}
        </List>

        <List dense sx={{ flexGrow: 1, flexShrink: 0 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: 'primary.light' }}>
                <LightbulbCircleIcon color="primary" />
              </Avatar>
            }
            title={t('charging-session.card.session-identifiers')}
          />

          {isPending ? (
            <SkeletonList />
          ) : (
            <>
              <ListItem>
                <ListItemText
                  primary={t('charging-session.card.sessionId')}
                  secondary={sessionId ?? t('charging-session.card.fallback')}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t('charging-session.card.cpoId')}
                  secondary={cpoId ?? t('charging-session.card.fallback')}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t('charging-session.card.ocpiTokenUid')}
                  secondary={tokenUid ?? t('charging-session.card.fallback')}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t('charging-session.card.ocpiContractId')}
                  secondary={contractId ?? t('charging-session.card.fallback')}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t('charging-session.card.evseUid')}
                  secondary={evseUid ?? t('charging-session.card.fallback')}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t('charging-session.card.locationId')}
                  secondary={locationId ?? t('charging-session.card.fallback')}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t('charging-session.card.connectorId')}
                  secondary={connectorId ?? t('charging-session.card.fallback')}
                />
              </ListItem>
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
}
