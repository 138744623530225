import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CHARGING_SESSION_DETAIL_ENDPOINT,
  CHARGING_SESSION_ENDPOINT,
} from 'src/config/api-routes';
import { generatePath } from 'react-router-dom';
import {
  DataGridQueryModel,
  PaginationResponseModel,
} from '../model/data-grid.model';
import { baseQueryBuilder } from './base-query.builder';
import { ChargingSessionResponseModel } from '../model/charging-session.model';
import { mapDataGridQueryModelToApiQuery } from '../helper/map-data-grid-query-model-to-api-query';

export const chargingSessionApi = createApi({
  reducerPath: 'chargingSessionApi',

  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getListChargingSession: builder.query<
      PaginationResponseModel<ChargingSessionResponseModel>,
      DataGridQueryModel
    >({
      query: (params: DataGridQueryModel) =>
        `${CHARGING_SESSION_ENDPOINT}?${mapDataGridQueryModelToApiQuery(
          params,
        ).toString()}`,
    }),

    getOneChargingSession: builder.query<
      ChargingSessionResponseModel,
      string | null
    >({
      query: (chargingSessionId: string | null) =>
        generatePath(CHARGING_SESSION_DETAIL_ENDPOINT, { chargingSessionId }),
    }),
  }),
});

export const { useGetListChargingSessionQuery, useGetOneChargingSessionQuery } =
  chargingSessionApi;
