import { PostProcessorModule } from 'i18next';

export const errorMessagePostProcessor = {
  type: 'postProcessor',
  name: 'errorMessage',
  process(value: string, _, options) {
    if (!options?.message && !options?.status) {
      return value;
    }

    return `${value} [API: "${options.message}" Status: ${options.status}]`;
  },
} as PostProcessorModule;
