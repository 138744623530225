import jwtDecode from 'jwt-decode';
import { JwtDecodedModel } from '../model/jwt-response.model';

export function getDecodedJwt(jwt: string): JwtDecodedModel | null {
  try {
    return jwtDecode(jwt);
  } catch {
    return null;
  }
}

export function isValidJwt(decoded: JwtDecodedModel): boolean {
  if (!decoded.exp) {
    return false;
  }

  return new Date(decoded.exp * 1000) > new Date();
}
