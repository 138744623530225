import { styled } from '@mui/material/styles';

export const RoundIcon = styled('div')(({ theme }) => ({
  height: '40px',
  width: '40px',
  padding: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
}));
