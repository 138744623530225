import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Stack } from '@mui/material';
import { Logger } from 'src/core/logger/logger';
import {
  TextOverflow,
  TextOverflowProps,
} from 'src/shared/typography/text-overflow';

type CopyTextProps = TextOverflowProps & {
  children?: string;
};

export function CopyText(props: CopyTextProps): React.JSX.Element {
  const { maxWidth = 300, children, ...textOverflowProps } = props;

  const handleCopy = () => {
    if (!children) {
      return;
    }

    navigator.clipboard.writeText(children).catch((error) => {
      Logger.error('Copy to clipboard failed.', error);
    });
  };

  const maxWidthProps: number | 'unset' =
    maxWidth === 'unset' ? 'unset' : maxWidth - 64;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <TextOverflow maxWidth={maxWidthProps} {...textOverflowProps}>
        {children}
      </TextOverflow>

      <IconButton onClick={handleCopy}>
        <ContentCopyIcon />
      </IconButton>
    </Stack>
  );
}
