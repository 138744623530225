import {
  FilterDefinitionModel,
  FilterValueModel,
} from 'src/core/model/data-grid.model';
import { useTranslation } from 'react-i18next';

import { useDateFilter } from './use-date-filter';

type UseYearFilterProps = {
  field: string;
  label: string;
  valueFormatter?: (value: FilterValueModel) => string;
};

export function useYearFilter(
  hookProps: UseYearFilterProps,
): FilterDefinitionModel {
  const { t } = useTranslation();

  const {
    label,
    field,
    valueFormatter = (value) => t('data-grid.row.year', { value }),
  } = hookProps;

  return useDateFilter({
    label,
    field,
    valueFormatter,
    datePickerFormat: 'YYYY',
    datePickerOnChangeFormatTemplate: 'YYYY',
    datePickerViews: ['year'],
  });
}
