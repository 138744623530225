import { DEVELOPER_ENDPOINT } from 'src/config/api-routes';
import { useTranslation } from 'react-i18next';
import { WithUserId } from 'src/core/helper/with-user-id';
import {
  CreateGenericUserProps,
  CreateUser,
} from '../../generic-user/user-create/user-create';

function DeveloperCreate(
  props: Readonly<CreateGenericUserProps>,
): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <CreateUser
      title={t('user.developer.create.title')}
      endpoint={DEVELOPER_ENDPOINT}
      {...props}
    />
  );
}

export const CreateDeveloper = WithUserId(DeveloperCreate);
