import { ADMIN_ENDPOINT } from 'src/config/api-routes';
import { useTranslation } from 'react-i18next';
import {
  UpdateGenericUserProps,
  UserUpdate,
} from '../../generic-user/user-update/user-update';

export function AdministratorUpdate(
  props: Readonly<UpdateGenericUserProps>,
): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <UserUpdate
      {...props}
      title={t('user.administrator.update.title')}
      endpoint={ADMIN_ENDPOINT}
    />
  );
}
