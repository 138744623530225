import { DataGrid } from 'src/feature/data-grid/data-grid';
import { useGetListUserQuery } from 'src/core/service/user.service';
import { useDataGridSearchParams } from 'src/feature/data-grid/use-data-grid-search-params/use-data-grid-search-params';
import { UserResponseModel } from 'src/core/model/user.model';
import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';
import { useTranslation } from 'react-i18next';
import { Snackbar } from 'src/shared/snackbar/snackbar';

import { useUserColumn } from './use-user-column';
import { useUserFilter } from './use-user-filter';

type UserDataGridProps = {
  readonly endpoint: string;
  readonly onUpdateUser: (user: UserResponseModel) => void;
};

export function UserDataGrid(props: UserDataGridProps): React.JSX.Element {
  const { endpoint, onUpdateUser } = props;

  const columns = useUserColumn({ onUpdateUser });
  const filterDefinitionModel = useUserFilter();
  const { model, onModelChange } = useDataGridSearchParams({
    ...DEFAULT_DATA_GRID_MODEL,
    sort: [
      {
        field: 'updatedAt',
        sort: 'desc',
      },
    ],
  });
  const { t } = useTranslation();

  const {
    data: paginatedData,
    isFetching,
    isError,
  } = useGetListUserQuery({
    endpoint,
    ...model,
  });

  return (
    <>
      <DataGrid
        columns={columns}
        filterDefinitionModel={filterDefinitionModel}
        loading={isFetching}
        rows={paginatedData?.docs ?? []}
        rowCount={paginatedData?.totalDocs ?? 0}
        model={model}
        onModelChange={onModelChange}
      />

      {isError && (
        <Snackbar variant="error" message={t('error.get-list-user-query')} />
      )}
    </>
  );
}
