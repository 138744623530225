import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { EnhancedQuarterOverviewModel } from 'src/core/model/quarter-overview.model';

export function QuarterOverviewTableHead(): React.JSX.Element {
  const { t } = useTranslation();

  const quarterOverviewTranslations: Record<
    keyof EnhancedQuarterOverviewModel,
    string
  > = t('model.quarter-overview', {
    returnObjects: true,
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell>{quarterOverviewTranslations.id}</TableCell>
        <TableCell>{quarterOverviewTranslations.date}</TableCell>
        <TableCell>{quarterOverviewTranslations.chargePointName}</TableCell>
        <TableCell>{quarterOverviewTranslations.customerId}</TableCell>
        <TableCell>{quarterOverviewTranslations.connectorId}</TableCell>
        <TableCell>{quarterOverviewTranslations.name}</TableCell>
        <TableCell>{quarterOverviewTranslations.externalCardId}</TableCell>
        <TableCell>{quarterOverviewTranslations.internalCardId}</TableCell>
        <TableCell>{quarterOverviewTranslations.global}</TableCell>
        <TableCell align="right">
          {quarterOverviewTranslations.energyDelivered}
        </TableCell>
        <TableCell align="right">
          {quarterOverviewTranslations.totalHT}
        </TableCell>
        <TableCell align="right">
          {quarterOverviewTranslations.durationInMin}
        </TableCell>
        <TableCell>{quarterOverviewTranslations.evseUid}</TableCell>
        <TableCell align="right">
          {quarterOverviewTranslations.totalHTDelmonicos}
        </TableCell>
        <TableCell align="right">
          {quarterOverviewTranslations.totalTTCDelmonicos}
        </TableCell>
        <TableCell>{quarterOverviewTranslations.transactionId}</TableCell>
        <TableCell>{quarterOverviewTranslations.iTransactionId}</TableCell>
        <TableCell align="right">
          {quarterOverviewTranslations.checkoutFees}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
