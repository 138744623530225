import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DisplayTextModel } from 'src/core/model/tariff.model';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export function useDisplayTextFormValidation(): Record<
  keyof DisplayTextModel,
  RegisterOptions
> {
  const { t } = useTranslation();

  const translations: Record<keyof DisplayTextModel, string> = t(
    'model.display-text',
    {
      returnObjects: true,
    },
  );

  const formValidationRules = useFormValidation();

  return useMemo(
    () => ({
      language: {
        ...formValidationRules.required({
          field: translations.language,
        }),
      },
      text: {
        ...formValidationRules.required({
          field: translations.text,
        }),
      },
    }),
    [formValidationRules, translations],
  );
}
