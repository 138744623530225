import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LOGIN_OTP_LENGTH } from 'src/config/form-validation-rules';
import { VerifyOtpModel } from 'src/core/model/login.model';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export type EmailOtpForm = {
  emailAddress: string;
  code: string;
};

export function useEmailOtpFormValidation(): Record<
  keyof EmailOtpForm,
  RegisterOptions
> {
  const { t } = useTranslation();

  const loginTranslations: Record<keyof VerifyOtpModel, string> = t(
    'model.verify-otp',
    {
      returnObjects: true,
    },
  );

  const formValidationRules = useFormValidation();

  return useMemo(
    () => ({
      emailAddress: {
        ...formValidationRules.required({
          field: loginTranslations.emailAddress,
        }),
        ...formValidationRules.emailPattern({
          field: loginTranslations.emailAddress,
        }),
      },
      code: {
        ...formValidationRules.required({
          field: loginTranslations.code,
        }),
        ...formValidationRules.length({
          field: loginTranslations.code,
          value: LOGIN_OTP_LENGTH,
        }),
      },
    }),
    [formValidationRules, loginTranslations],
  );
}
