import QRCodeStyling, { FileExtension } from 'qr-code-styling';
import { useLayoutEffect, useMemo, useState } from 'react';
import { ConnectorAssetConfig } from 'src/config/qr-code-template/connector-asset.config';
import { QRCodeTemplateConfig } from 'src/config/qr-code-template/qr-code-template.config';
import { blobToBase64 } from 'src/core/helper/blob-to-base64';
import { QrCodeFormModel } from 'src/core/model/qr-code.model';

export type OnChangeProps = Pick<
  QrCodeFormModel,
  'template' | 'tinyUrl' | 'connector'
>;

type UseQrCodeReturn = {
  width: number;
  height: number;
  isError: boolean;
  onChange: (payload: OnChangeProps) => Promise<string | null>;
  onDownload: (extension: FileExtension) => void;
};

export function useQrCode(element: HTMLElement | null): UseQrCodeReturn {
  const qrCode = useMemo(() => new QRCodeStyling(), []);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [isError, setIsError] = useState(false);

  const handleChange = async (
    payload: OnChangeProps,
  ): Promise<string | null> => {
    setIsError(false);

    const baseOption =
      payload.template in QRCodeTemplateConfig
        ? QRCodeTemplateConfig[payload.template]
        : QRCodeTemplateConfig.delmonicos;

    const image =
      payload.connector in ConnectorAssetConfig
        ? ConnectorAssetConfig[payload.connector]
        : ConnectorAssetConfig.unknown;

    const options = { ...baseOption, data: payload.tinyUrl, image };

    if (!options.data) {
      return null;
    }

    setHeight(options.height ?? 0);
    setWidth(options.width ?? 0);

    try {
      qrCode?.update(options);

      const blobData = await qrCode?.getRawData('svg');
      return blobData ? await blobToBase64(blobData) : null;
    } catch {
      setIsError(true);
      return null;
    }
  };

  const handleDownload = (extension: FileExtension) => {
    qrCode?.download({ extension });
  };

  useLayoutEffect(() => {
    if (element) {
      qrCode?.append(element);
    }
  }, [qrCode, element]);

  return {
    width,
    height,
    isError,
    onChange: handleChange,
    onDownload: handleDownload,
  };
}
