import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CreateUserModel,
  UserFormModel,
  UserResponseModel,
} from 'src/core/model/user.model';
import { baseQueryBuilder } from './base-query.builder';
import {
  DataGridQueryModel,
  PaginationResponseModel,
} from '../model/data-grid.model';
import { mapDataGridQueryModelToApiQuery } from '../helper/map-data-grid-query-model-to-api-query';
import { buildPath } from '../helper/build-path';

type GenericEndpointModel = { endpoint: string };
type ListUserQueryModel = DataGridQueryModel & GenericEndpointModel;
type CreateUserPayloadModel = CreateUserModel & GenericEndpointModel;
type PatchUserPayloadModel = Partial<UserFormModel> &
  GenericEndpointModel & { id: UserResponseModel['id'] };

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryBuilder(),
  tagTypes: ['USER'],

  endpoints: (builder) => ({
    getListUser: builder.query<
      PaginationResponseModel<UserResponseModel>,
      ListUserQueryModel
    >({
      query: ({ endpoint, ...params }: ListUserQueryModel) =>
        `${endpoint}?${mapDataGridQueryModelToApiQuery(params).toString()}`,
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ id }) => ({ type: 'USER' as const, id })),
              { type: 'USER', id: 'PARTIAL' },
            ]
          : [{ type: 'USER', id: 'PARTIAL' }],
    }),

    createUser: builder.mutation<UserResponseModel, CreateUserPayloadModel>({
      query: ({ endpoint, ...payload }: CreateUserPayloadModel) => ({
        url: endpoint,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'USER', id: 'PARTIAL' }],
    }),

    patchUser: builder.mutation<UserResponseModel, PatchUserPayloadModel>({
      query: ({ endpoint, id, ...payload }: PatchUserPayloadModel) => ({
        url: buildPath(endpoint, id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (_, __, { id }: PatchUserPayloadModel) => [
        { type: 'USER', id },
        { type: 'USER', id: 'PARTIAL' },
      ],
    }),
  }),
});

export const {
  useGetListUserQuery,
  useCreateUserMutation,
  usePatchUserMutation,
} = userApi;
