import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/shared/button/button';
import { DisplayTextModel } from 'src/core/model/tariff.model';
import AddIcon from '@mui/icons-material/Add';

import { FieldArrayFormContainer } from './component/field-array-form-container';
import { DisplayTextForm } from './display-text-form/display-text.form';
import { FormSection } from './component/form-section';

const DEFAULT_DISPLAY_TEXT: DisplayTextModel = {
  language: '',
  text: '',
};

type Props = {
  readonly level?: number;
};

export function TariffAltTextFormManager(props: Props): React.JSX.Element {
  const { level = 0 } = props;
  const { t } = useTranslation();
  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext();

  const tariffAltTextErrors:
    | Merge<FieldError, FieldErrorsImpl<DisplayTextModel[]>>
    | undefined = errors.tariffAltText;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tariffAltText',
  });

  return (
    <FormSection
      level={level}
      title={t('tariff.form.tariff-alt-text.title', { count: fields.length })}
      showText={t('tariff.form.tariff-alt-text.show', {
        count: fields.length,
      })}
      canHide={fields.length > 0}>
      {fields.map((field, index) => (
        <FieldArrayFormContainer
          level={level + 1}
          key={field.id}
          title={t('tariff.form.tariff-alt-text.item.title', {
            index: index + 1,
          })}
          onRemove={() => remove(index)}>
          <DisplayTextForm
            prefixName={`tariffAltText.${index}`}
            errors={tariffAltTextErrors?.[index]}
            touchedFields={touchedFields.tariffAltText?.[index]}
          />
        </FieldArrayFormContainer>
      ))}

      <div>
        <Button
          startIcon={<AddIcon />}
          onClick={() => append(DEFAULT_DISPLAY_TEXT)}>
          {t('tariff.form.tariff-alt-text.append')}
        </Button>
      </div>
    </FormSection>
  );
}
