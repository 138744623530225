import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  TRIGRAM_LENGTH,
} from 'src/config/form-validation-rules';
import { UserFormModel, UserResponseModel } from 'src/core/model/user.model';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export function useUserFormValidation(): Record<
  keyof UserFormModel,
  RegisterOptions
> {
  const { t } = useTranslation();

  const userTranslations: Record<keyof UserResponseModel, string> = t(
    'model.user',
    {
      returnObjects: true,
    },
  );
  const formValidationRules = useFormValidation();

  return useMemo(
    () => ({
      firstName: {
        ...formValidationRules.required({ field: userTranslations.firstName }),
        ...formValidationRules.minLength({
          field: userTranslations.firstName,
          value: NAME_MIN_LENGTH,
        }),
        ...formValidationRules.maxLength({
          field: userTranslations.firstName,
          value: NAME_MAX_LENGTH,
        }),
      },
      lastName: {
        ...formValidationRules.required({ field: userTranslations.lastName }),
        ...formValidationRules.minLength({
          field: userTranslations.lastName,
          value: NAME_MIN_LENGTH,
        }),
        ...formValidationRules.maxLength({
          field: userTranslations.lastName,
          value: NAME_MAX_LENGTH,
        }),
      },
      trigram: {
        ...formValidationRules.required({ field: userTranslations.trigram }),
        ...formValidationRules.length({
          field: userTranslations.trigram,
          value: TRIGRAM_LENGTH,
        }),
      },
      emailAddress: {
        ...formValidationRules.required({
          field: userTranslations.emailAddress,
        }),
        ...formValidationRules.emailPattern({
          field: userTranslations.emailAddress,
        }),
      },
    }),
    [formValidationRules, userTranslations],
  );
}
