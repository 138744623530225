import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript -> https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-0.html#non-null-assertion-operator
root.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
);
