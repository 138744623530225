import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CUSTOMER_LOCK_DURATION_MAX,
  CUSTOMER_LOCK_DURATION_MIN,
} from 'src/config/form-validation-rules';
import { CustomerLockFormModel } from 'src/core/model/customer.model';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export function useCustomerFormValidation(): Record<
  keyof CustomerLockFormModel,
  RegisterOptions
> {
  const { t } = useTranslation();

  const customerLockTranslations: Record<keyof CustomerLockFormModel, string> =
    t('model.customer-lock', {
      returnObjects: true,
    });

  const formValidationRules = useFormValidation();

  return useMemo(
    () => ({
      duration: {
        ...formValidationRules.max({
          field: customerLockTranslations.duration,
          value: CUSTOMER_LOCK_DURATION_MAX,
        }),

        ...formValidationRules.min({
          field: customerLockTranslations.duration,
          value: CUSTOMER_LOCK_DURATION_MIN,
        }),
      },
    }),
    [formValidationRules, customerLockTranslations],
  );
}
