import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import { QrCodeFormModel } from 'src/core/model/qr-code.model';
import { DEFAULT_TEMPLATE } from 'src/config/qr-code-template/qr-code-template.config';
import { useTranslation } from 'react-i18next';
import {
  Backdrop,
  CircularProgress,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DEFAULT_CONNECTOR_ASSET } from 'src/config/qr-code-template/connector-asset.config';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useApiError } from 'src/core/helper/use-api-error';
import { usePutQrCodeMutation } from 'src/core/service/charge-point.service';

import { QrCodePreview } from '../qr-code-preview/qr-code-preview';
import { QrCodeForm } from '../qr-code-form/qr-code-form';
import { useQrCode } from '../use-qr-code';

type Props = {
  readonly chargePointId: string;
  readonly tinyId?: string | null;
  readonly tinyUrl?: string | null;
  readonly onSuccess?: () => void;
};

export function QrCodeDialog(props: Props): React.JSX.Element {
  const { chargePointId, tinyId, tinyUrl, onSuccess = () => {} } = props;
  const { t } = useTranslation();

  const qrCodeRef = useRef<HTMLDivElement>(null);

  const { height, width, isError, onChange, onDownload } = useQrCode(
    qrCodeRef.current,
  );

  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>('');

  const [
    submitQRCode,
    { isLoading, isError: isPutQrCodeError, error: putQrCodeError, isSuccess },
  ] = usePutQrCodeMutation();

  const { status: putQrCodeStatus, message: putQrCodeMessage } =
    useApiError(putQrCodeError);

  const handleSubmit = (payload: QrCodeFormModel) => {
    onChange(payload).then((asset) => {
      if (!asset) {
        setSubmitErrorMessage(t('qr-code.form.error.asset-not-generated'));
        return;
      }

      submitQRCode({
        chargePointId,
        asset,
        tinyId: payload.tinyId,
        tinyUrl: payload.tinyUrl,
      });
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: 'absolute',
          }}
          open={!tinyId || !tinyUrl}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle>{t('qr-code.generator.title')}</DialogTitle>

        <DialogContent>
          <Stack direction="row" gap={4}>
            <Box sx={{ flex: 1 }}>
              {tinyId && tinyUrl && (
                <QrCodeForm
                  defaultValues={{
                    tinyId,
                    tinyUrl,
                    chargePointId,
                    template: DEFAULT_TEMPLATE,
                    connector: DEFAULT_CONNECTOR_ASSET,
                  }}
                  pending={isLoading}
                  errorMessage={submitErrorMessage}
                  onSubmit={handleSubmit}
                  onChange={onChange}
                />
              )}
            </Box>
            <QrCodePreview
              ref={qrCodeRef}
              height={height}
              width={width}
              isError={isError}
              onDownload={onDownload}
            />
          </Stack>
        </DialogContent>
      </Box>

      {isPutQrCodeError && (
        <Snackbar
          variant="error"
          message={t('error.put-qr-code-mutation', {
            status: putQrCodeStatus,
            message: putQrCodeMessage,
          })}
        />
      )}
    </>
  );
}
