import {
  FilterDefinitionModel,
  FilterFormItemProps,
  FilterValueModel,
} from 'src/core/model/data-grid.model';
import { DatePicker } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { BaseNonStaticPickerProps } from '@mui/x-date-pickers/internals';
import dayjs from 'dayjs';

import { FilterItem } from './filter-item';
import { useRangeChip } from './use-range-chip';

type UseDateRangeFilterProps = {
  label: string;
  labelMin?: string;
  labelMax?: string;
  keyMin: string;
  keyMax: string;
  datePickerFormat?: BaseNonStaticPickerProps['format'];
  valueFormatter?: (value: FilterValueModel) => string;
};

export function useDateRangeFilter(
  hookProps: UseDateRangeFilterProps,
): FilterDefinitionModel {
  const { t } = useTranslation();

  const {
    label,
    labelMin = t('data-grid.filter.dateBefore'),
    labelMax = t('data-grid.filter.dateAfter'),
    keyMin,
    keyMax,
    datePickerFormat = 'DD/MM/YYYY',
    valueFormatter = (value) =>
      t('data-grid.row.date', { value: new Date(value.toString()) }),
  } = hookProps;

  const getRangeChip = useRangeChip({ label, keyMin, keyMax, valueFormatter });

  return {
    getFilter: (props: FilterFormItemProps) => {
      const { values, setValues } = props;

      return (
        <FilterItem key={`${keyMin}-${keyMax}`} label={label}>
          <Stack direction="row" gap={2}>
            <DatePicker
              label={labelMin}
              format={datePickerFormat}
              onChange={(value) =>
                setValues((prev) => ({
                  ...prev,
                  ...(value
                    ? {
                        [keyMin]: value.toISOString(),
                      }
                    : null),
                }))
              }
              value={values[keyMin] ? dayjs(values[keyMin].toString()) : null}
            />

            <DatePicker
              label={labelMax}
              format="DD/MM/YYYY"
              onChange={(value) =>
                setValues((prev) => ({
                  ...prev,
                  ...(value ? { [keyMax]: value.toISOString() } : null),
                }))
              }
              value={values[keyMin] ? dayjs(values[keyMin].toString()) : null}
            />
          </Stack>
        </FilterItem>
      );
    },

    getChip: getRangeChip,
  };
}
