import { GridColTypeDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNumberColumn } from './use-number-column';

export function useCurrencyColumn(): GridColTypeDef {
  const { t } = useTranslation();
  const numberColumn = useNumberColumn();

  return {
    ...numberColumn,
    valueFormatter: (
      params: GridValueFormatterParams<number | null>,
    ): string =>
      params.value !== null
        ? t('data-grid.row.currency', {
            value: params.value,
          })
        : '-',
  };
}
