import { GridColTypeDef, GridValueGetterParams } from '@mui/x-data-grid';

export function useNumberColumn(): GridColTypeDef {
  return {
    type: 'number',
    width: 80,
    valueGetter: (params: GridValueGetterParams<any, number>): number | null =>
      params.value ?? null,
  };
}
