import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  deserializeDataGridQueryModel,
  serializeDataGridQueryModel,
} from 'src/feature/data-grid/use-data-grid-search-params/serialize-data-grid-query-model';
import { DataGridQueryModel } from 'src/core/model/data-grid.model';
import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';

type DataGridSearchParamsHookReturn = {
  model: DataGridQueryModel;
  onModelChange: (payload: DataGridQueryModel) => void;
  onReset: () => void;
};

export function useDataGridSearchParams(
  defaultValue: DataGridQueryModel = DEFAULT_DATA_GRID_MODEL,
): DataGridSearchParamsHookReturn {
  const defaultSerializedValue = useMemo(
    () => serializeDataGridQueryModel(defaultValue),
    [defaultValue],
  );

  const [searchParams, setSearchParams] = useSearchParams(
    defaultSerializedValue,
  );

  return useMemo(() => {
    const model = deserializeDataGridQueryModel(searchParams);

    return {
      model,

      onModelChange: (payload) =>
        setSearchParams(serializeDataGridQueryModel({ ...model, ...payload })),

      onReset: () => setSearchParams(defaultSerializedValue),
    };
  }, [searchParams, setSearchParams, defaultSerializedValue]);
}
