import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiError } from 'src/core/helper/use-api-error';
import { OcpiLocationModel } from 'src/core/model/ocpi.model';
import { useGetListLocationQuery } from 'src/core/service/location.service';
import { Autocomplete, AutocompleteProps } from 'src/shared/form/autocomplete';

import { Snackbar } from 'src/shared/snackbar/snackbar';

type Props = Pick<
  AutocompleteProps<OcpiLocationModel>,
  'onChange' | 'label' | 'errorMessage' | 'isTouched'
> & {
  readonly cpoUrl?: string | null;
};

export function LocationSelect(props: Props): React.JSX.Element {
  const { cpoUrl, ...autocompleteProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  const {
    data: locationList,
    error,
    isError,
    isLoading,
  } = useGetListLocationQuery(cpoUrl ?? '', { skip: !isOpen && !cpoUrl });
  const { status, message } = useApiError(error);
  const { t } = useTranslation();

  return (
    <>
      <Autocomplete
        disabled={!cpoUrl}
        isOpen={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        options={locationList}
        isLoading={isLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.id}
        {...autocompleteProps}
      />

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.get-list-location-query', {
            status,
            message,
          })}
        />
      )}
    </>
  );
}
