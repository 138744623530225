import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from './button';

type CancelButtonProps = Readonly<Pick<ButtonProps, 'onClick'>>;
export function CancelButton(props: CancelButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <Button variant="outlined" {...props}>
      {t('form.cancel-button')}
    </Button>
  );
}
