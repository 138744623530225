import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { SupportedLocaleEnum } from 'src/core/model/enum/supported-locale.enum';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useSelectLanguage } from './use-select-language';

export function SelectLanguage(): React.JSX.Element {
  const { locale, onChange, isError } = useSelectLanguage();

  const { t } = useTranslation();

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="language-select-label">
          {t('dashboard.user-presentation.select-language.label')}
        </InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select-id"
          value={locale}
          label={t('dashboard.user-presentation.select-language.label')}
          onChange={onChange}>
          <MenuItem value="browser">
            {t('dashboard.user-presentation.select-language.browser')}
          </MenuItem>
          <MenuItem value={SupportedLocaleEnum.fr}>
            {t('dashboard.user-presentation.select-language.french')}
          </MenuItem>
          <MenuItem value={SupportedLocaleEnum.en}>
            {t('dashboard.user-presentation.select-language.english')}
          </MenuItem>
        </Select>
      </FormControl>

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.patch-current-user-mutation')}
        />
      )}
    </>
  );
}
