import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CancelButton } from 'src/shared/button/cancel.button';
import { SubmitButton } from 'src/shared/button/submit.button';
import { CustomerLockFormModel } from 'src/core/model/customer.model';
import { Slider, Typography } from '@mui/material';
import {
  CUSTOMER_LOCK_DURATION_MAX,
  CUSTOMER_LOCK_DURATION_MIN,
} from 'src/config/form-validation-rules';

import { useCustomerFormValidation } from './use-customer-lock.form-validation';

type CustomerFormViewProps = {
  readonly pending: boolean;
  readonly onSubmit: (payload: CustomerLockFormModel) => void;
  readonly onCancel: () => void;
};

export function CustomerLockFormView(
  props: CustomerFormViewProps,
): React.JSX.Element {
  const { pending, onSubmit, onCancel } = props;

  const { t } = useTranslation();
  const formValidation = useCustomerFormValidation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<CustomerLockFormModel>({
    mode: 'onChange',
    defaultValues: {
      duration: 10,
    },
  });

  const duration = watch('duration') ?? 0;

  const customerTranslations: Record<keyof CustomerLockFormModel, string> = t(
    'model.customer-lock',
    {
      returnObjects: true,
    },
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Typography>{customerTranslations.duration}</Typography>

        <Controller
          control={control}
          name="duration"
          {...formValidation.duration}
          render={({ field: { onChange, value, ref } }) => (
            <Slider
              ref={ref}
              defaultValue={0}
              max={CUSTOMER_LOCK_DURATION_MAX}
              min={CUSTOMER_LOCK_DURATION_MIN}
              valueLabelDisplay="auto"
              value={value}
              onChange={(_, newValue: number | number[]) =>
                onChange(newValue as number)
              }
            />
          )}
        />
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onCancel} />
        <SubmitButton loading={pending} disabled={!isValid}>
          {t(`customer.lock.submit-button`, { count: duration })}
        </SubmitButton>
      </DialogActions>
    </form>
  );
}
