import { GridColTypeDef } from '@mui/x-data-grid';
import { useCopyColumn } from './use-copy-column';

export function usePseudoColumn(): GridColTypeDef {
  const copyColumn = useCopyColumn();

  return {
    ...copyColumn,
    width: 160,
  };
}
