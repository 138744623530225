import { useState } from 'react';

type DialogHookReturn = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export function useDialog(): DialogHookReturn {
  const [isOpen, setIsOpen] = useState(false);

  return {
    isOpen,
    onOpen: () => setIsOpen(true),
    onClose: () => setIsOpen(false),
  };
}
