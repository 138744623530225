import { useTranslation } from 'react-i18next';
import { CustomerResponseModel } from 'src/core/model/customer.model';
import { FilterDefinitionModel } from 'src/core/model/data-grid.model';
import { useTextFilter } from 'src/feature/data-grid/filter/generic-filter/use-text-filter';

export function useCustomerFilter(): FilterDefinitionModel[] {
  const { t } = useTranslation();

  const customerTranslations: Record<keyof CustomerResponseModel, string> = t(
    'model.customer',
    {
      returnObjects: true,
    },
  );

  const pseudoFilter = useTextFilter('pseudo', customerTranslations.pseudo);
  const firstNameFilter = useTextFilter(
    'firstName',
    customerTranslations.firstName,
  );
  const lastNameFilter = useTextFilter(
    'lastName',
    customerTranslations.lastName,
  );

  const emailAddressFilter = useTextFilter(
    'emailAddress',
    customerTranslations.emailAddress,
  );

  return [pseudoFilter, firstNameFilter, lastNameFilter, emailAddressFilter];
}
