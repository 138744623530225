import {
  Card,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { UserIdentity } from 'src/feature/dashboard/component/user-presentation/user-identity/user-identity';
import { SubTitle } from 'src/shared/typography/title';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTranslation } from 'react-i18next';
import { useGetRole } from 'src/shared/role/use-get-role';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { RowStack } from 'src/shared/row-stack/row-stack';
import { useGetCurrentUserQuery } from 'src/core/service/current-user.service';
import { Snackbar } from 'src/shared/snackbar/snackbar';

import { SelectLanguage } from './select-language/select-language';
import { UpdatePasswordButton } from '../../../update-password/update-password.button';

interface UserPresentationProps {
  readonly sx?: SxProps;
}

export function UserPresentation(
  props: UserPresentationProps,
): React.JSX.Element {
  const { data: user, isError } = useGetCurrentUserQuery();
  const { sx } = props;
  const getRole = useGetRole();

  const { t } = useTranslation();

  return (
    <>
      <Card
        elevation={1}
        sx={{
          p: 4,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        }}>
        <SubTitle icon={<AccountCircleOutlinedIcon />}>
          {t('dashboard.user-presentation.title')}
        </SubTitle>

        <Stack gap={2} direction={{ md: 'column', lg: 'row' }}>
          <Stack
            flexDirection="column"
            sx={{
              flex: 1,
              backgroundColor: 'primary.light',
              p: 2,
              gap: 2,
              alignItems: 'start',
              justifyContent: 'center',
              borderRadius: 2,
            }}>
            <UserIdentity
              trigram={user?.trigram}
              firstName={user?.firstName}
              lastName={user?.lastName}
            />

            <RowStack>
              <EmailOutlinedIcon />
              <Typography sx={{ flex: 1 }}>
                {user?.emailAddress ?? <Skeleton />}
              </Typography>
            </RowStack>
          </Stack>

          <Stack
            flexDirection="column"
            sx={{
              flex: 1,
              p: 2,
              gap: 2,
              alignItems: 'start',
              justifyContent: 'center',
            }}>
            <RowStack>
              <AdminPanelSettingsOutlinedIcon />
              <TextField
                fullWidth
                label={t('dashboard.user-presentation.role')}
                variant="outlined"
                value={getRole(user?.role)}
                disabled
              />
            </RowStack>

            <RowStack>
              <FlagOutlinedIcon />
              <SelectLanguage />
            </RowStack>

            <RowStack>
              <LockOutlinedIcon />
              <Typography sx={{ flex: 1 }}>
                {t('dashboard.user-presentation.password.label')}
              </Typography>
              <UpdatePasswordButton />
            </RowStack>
          </Stack>
        </Stack>
      </Card>

      {isError && (
        <Snackbar variant="error" message={t('error.get-current-user-query')} />
      )}
    </>
  );
}
