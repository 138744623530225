import { createApi } from '@reduxjs/toolkit/query/react';

import { PRE_CAPTURE_AMOUNT_ENDPOINT } from 'src/config/api-routes';
import { baseQueryBuilder } from './base-query.builder';
import { PreCaptureAmountListResponseModel } from '../model/pre-capture-amount.model';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',

  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getListPreCaptureAmount: builder.query<
      PreCaptureAmountListResponseModel,
      void
    >({
      query: () => PRE_CAPTURE_AMOUNT_ENDPOINT,
    }),
  }),
});

export const { useGetListPreCaptureAmountQuery } = paymentApi;
