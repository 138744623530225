import {
  Autocomplete as MUIAutocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

export type AutocompleteProps<T> = Pick<
  MuiTextFieldProps,
  'label' | 'autoFocus' | 'id' | 'margin'
> & {
  readonly onChange: (value: T | null) => void;
  readonly errorMessage?: string | null;
  readonly isTouched?: boolean;
  readonly isLoading?: boolean;
  readonly options?: T[];
  readonly isOptionEqualToValue: (option: T, value: T) => boolean;
  readonly getOptionLabel?: (option: T) => string;
  readonly isOpen?: boolean;
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  readonly disabled?: boolean;
};

export function Autocomplete<T>(
  props: AutocompleteProps<T>,
): React.JSX.Element {
  const {
    onChange,
    label,
    errorMessage,
    isTouched,
    isLoading = false,
    options = [],
    isOptionEqualToValue,
    getOptionLabel,
    isOpen,
    onOpen,
    onClose,
    margin = 'dense',
    disabled,
  } = props;

  return (
    <MUIAutocomplete
      disabled={disabled}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onChange={(_, newValue) => onChange(newValue)}
      options={options}
      loading={isLoading}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          margin={margin}
          label={label}
          error={isTouched && !!errorMessage}
          helperText={isTouched && errorMessage}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
