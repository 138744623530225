import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChargingSessionStatusModel } from 'src/core/model/charging-session.model';
import { OrderStatusModel } from 'src/core/model/order.model';
import { useGetChargingSessionStatusText } from 'src/shared/charging-status-card/use-get-charging-session-status-text';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import {
  MergedStatusModel,
  mergeChargingSessionAndInvoiceStatus,
} from './merge-charging-session-and-invoice-status';
import { SkeletonTable } from '../skeleton/skeleton-table';

type ChargingStatusCardProps = CardProps & {
  orderStatusList?: OrderStatusModel[];
  chargingSessionStatusList?: ChargingSessionStatusModel[];
  isPending?: boolean;
};

export function ChargingStatusCard(
  props: ChargingStatusCardProps,
): React.JSX.Element {
  const {
    orderStatusList = [],
    chargingSessionStatusList = [],
    isPending = false,
    sx,
    ...cardProps
  } = props;

  const { t } = useTranslation();

  const getChargingSessionStatus = useGetChargingSessionStatusText();

  const mergedSortStatusList: MergedStatusModel[] = useMemo(
    () =>
      mergeChargingSessionAndInvoiceStatus(
        chargingSessionStatusList,
        orderStatusList,
      ).sort((a, b) => a.updatedAt.getTime() - b.updatedAt.getTime()),
    [chargingSessionStatusList, orderStatusList],
  );

  return (
    <Card sx={{ overflow: 'unset', ...sx }} {...cardProps}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary.light' }}>
            <LowPriorityIcon color="primary" />
          </Avatar>
        }
        title={t('charging-session.status.title')}
      />

      <TableContainer component={CardContent} sx={{ maxWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('charging-session.status.date')}</TableCell>
              <TableCell>{t('charging-session.status.order')}</TableCell>
              <TableCell>{t('charging-session.status.session')}</TableCell>
              <TableCell>
                {t('charging-session.status.ocpiSessionId')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isPending && <SkeletonTable rows={4} cols={4} />}

            {!isPending && mergedSortStatusList.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  {t('charging-session.status.fallback')}
                </TableCell>
              </TableRow>
            )}

            {!isPending &&
              mergedSortStatusList.map((row) => (
                <TableRow key={row.updatedAt.getTime()}>
                  <Tooltip
                    title={t('charging-session.status.updated-at-full', {
                      value: row.updatedAt,
                    })}>
                    <TableCell>
                      {t('charging-session.status.updated-at', {
                        value: row.updatedAt,
                      })}
                    </TableCell>
                  </Tooltip>

                  <TableCell
                    colSpan={2}
                    sx={{
                      paddingLeft: row.type === 'invoice' ? 2 : 18,
                    }}>
                    {row.type === 'invoice' &&
                      t(`model.enum.invoice-status.${row.status}`, row.status)}

                    {row.type === 'charging-session' &&
                      getChargingSessionStatus(row.status)}
                  </TableCell>
                  <TableCell>{row.ocpiSessionId}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
