import Dialog from '@mui/material/Dialog';

import { useGetCurrentUserQuery } from 'src/core/service/current-user.service';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Snackbar } from 'src/shared/snackbar/snackbar';

import { useUpdatePassword } from './use-update-password';
import { UpdatePasswordForm } from './update-password.form';

export function MustResetPassword(): React.JSX.Element {
  const { onSubmit, isLoading, isError } = useUpdatePassword({});

  const { data, isError: isGetCurrentUserError } = useGetCurrentUserQuery();
  const { t } = useTranslation();

  return (
    <>
      <Outlet />

      <Dialog maxWidth="xs" fullWidth open={!!data?.mustResetPassword}>
        <UpdatePasswordForm
          title={t('update-password.must-reset.title')}
          caption={t('update-password.must-reset.caption')}
          isError={isError}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      </Dialog>

      {isGetCurrentUserError && (
        <Snackbar variant="error" message={t('error.get-current-user-query')} />
      )}
    </>
  );
}
