import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';

import {
  CHARGE_POINT_API,
  CHARGE_POINT_QR_CODE_ENDPOINT,
} from 'src/config/api-routes';
import { baseQueryBuilder } from './base-query.builder';
import {
  ChargePointFormModel,
  ChargePointResponseModel,
} from '../model/charge-point.model';
import {
  DataGridQueryModel,
  PaginationResponseModel,
} from '../model/data-grid.model';
import { mapDataGridQueryModelToApiQuery } from '../helper/map-data-grid-query-model-to-api-query';
import {
  QrCodeGenerateIdentifierResponseModel,
  QrCodeModel,
} from '../model/qr-code.model';

type PutQrCodeModel = QrCodeModel & { chargePointId: string };

export const chargePointApi = createApi({
  reducerPath: 'chargePointApi',
  baseQuery: baseQueryBuilder(),
  tagTypes: ['CHARGE-POINT'],

  endpoints: (builder) => ({
    getListChargePoint: builder.query<
      PaginationResponseModel<ChargePointResponseModel>,
      DataGridQueryModel
    >({
      query: (params: DataGridQueryModel) =>
        `${CHARGE_POINT_API}?${mapDataGridQueryModelToApiQuery(
          params,
        ).toString()}`,
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ chargePointId }) => ({
                type: 'CHARGE-POINT' as const,
                chargePointId,
              })),
              { type: 'CHARGE-POINT', id: 'PARTIAL' },
            ]
          : [{ type: 'CHARGE-POINT', id: 'PARTIAL' }],
    }),

    createChargePoint: builder.mutation<
      ChargePointResponseModel,
      ChargePointFormModel
    >({
      query: (body: ChargePointResponseModel) => ({
        url: CHARGE_POINT_API,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'CHARGE-POINT', id: 'PARTIAL' }],
    }),

    putQrCode: builder.mutation<
      QrCodeGenerateIdentifierResponseModel,
      PutQrCodeModel
    >({
      query: ({ chargePointId, ...body }: PutQrCodeModel) => ({
        url: generatePath(CHARGE_POINT_QR_CODE_ENDPOINT, { chargePointId }),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { chargePointId }: PutQrCodeModel) => [
        { type: 'CHARGE-POINT', id: chargePointId },
        { type: 'CHARGE-POINT', id: 'PARTIAL' },
      ],
    }),
  }),
});

export const {
  useGetListChargePointQuery,
  useCreateChargePointMutation,
  usePutQrCodeMutation,
} = chargePointApi;
