import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiToolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { DRAWER_MENU_WIDTH } from 'src/config/app.config';
import { Breadcrumb } from 'src/shared/breadcrumb/breadcrumb';

interface CustomAppBarProps extends MuiAppBarProps {
  readonly isMobile: boolean;
  readonly open: boolean;
}

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})<CustomAppBarProps>(({ theme, open, isMobile }) => ({
  zIndex: isMobile ? theme.zIndex.appBar : theme.zIndex.drawer + 1,
  background: theme.palette.background.default,
  display: 'block',
  color: 'unset',
  height: theme.spacing(8),
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    !isMobile && {
      marginLeft: DRAWER_MENU_WIDTH,
      width: `calc(100% - ${DRAWER_MENU_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  height: theme.spacing(8),
  width: '100%',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

interface AppBarProps {
  readonly isMenuOpen: boolean;
  readonly isMobile: boolean;
  readonly onOpenMenu: () => void;
}

export function AppBar(props: AppBarProps): React.JSX.Element {
  const { isMenuOpen, isMobile, onOpenMenu } = props;

  return (
    <CustomAppBar
      elevation={0}
      isMobile={isMobile}
      position="fixed"
      open={isMenuOpen}>
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={onOpenMenu}
          sx={{
            ml: -2,
            mr: 5,
            ...(isMenuOpen && { display: 'none' }),
          }}>
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 0 }}>
          <Breadcrumb />
        </Box>
      </Toolbar>
    </CustomAppBar>
  );
}
