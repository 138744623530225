import { useCallback, useEffect, useMemo, useState } from 'react';
import { OrderResponseModel } from 'src/core/model/order.model';
import { EnhancedQuarterOverviewModel } from 'src/core/model/quarter-overview.model';
import { QuarterOverviewStateEnum } from 'src/core/model/enum/quarter-overview-state.enum';
import { useGetListOrderQuery } from 'src/core/service/order.service';
import { OrderSectionProps } from './order-section';

type Props = {
  readonly sessionId: string;
  readonly onChange: (
    data: Partial<EnhancedQuarterOverviewModel>,
    iTransactionId: string,
  ) => void;
};

type UseEnhanceWithOrder = OrderSectionProps & {
  state: QuarterOverviewStateEnum;
};

export function useEnhanceWithOrder(props: Props): UseEnhanceWithOrder {
  const { onChange, sessionId } = props;

  const {
    data: paginatedOrderList,
    isLoading,
    isError,
  } = useGetListOrderQuery({
    filter: { sessionId },
  });
  const [selectedId, setSelectedId] = useState<string>();
  const [state, setState] = useState<QuarterOverviewStateEnum>(
    QuarterOverviewStateEnum.INIT,
  );

  const list = useMemo(
    () => paginatedOrderList?.docs ?? [],
    [paginatedOrderList],
  );

  const handleSelect = useCallback(
    (order: OrderResponseModel) => {
      setSelectedId(order.id);
      setState(QuarterOverviewStateEnum.SUCCESS);
      onChange(
        {
          evseUid: order.evseUid,
          totalHTDelmonicos: order.totalHT,
          totalTTCDelmonicos: order.totalTTC,
          iTransactionId: order.iTransactionId,
        },
        order.iTransactionId,
      );
    },
    [onChange],
  );

  useEffect(() => {
    if (state === QuarterOverviewStateEnum.SUCCESS) {
      return;
    }

    if (isLoading) {
      setState(QuarterOverviewStateEnum.LOADING);
      return;
    }

    if (isError) {
      setState(QuarterOverviewStateEnum.QUERY_ERROR);
      return;
    }

    if (list.length > 1) {
      setState(QuarterOverviewStateEnum.MULTIPLE_ITEM_FOUND);
      return;
    }

    const order = list.at(0);

    if (!order) {
      setState(QuarterOverviewStateEnum.NO_ITEM_FOUND);
      return;
    }

    handleSelect(order);
  }, [list, handleSelect, isLoading, isError, state]);

  return useMemo(
    () => ({
      list,
      selectedId,
      onSelect: handleSelect,
      state,
    }),
    [list, selectedId, handleSelect, state],
  );
}
