import { CheckoutBreakdownTypeEnum } from './enum/checkout-breakdown-type.enum';

export const QUARTER_OVERVIEW_MODEL_LENGTH = 12;
export const CHECKOUT_FEE_MODEL_LENGTH = 23;

export type QuarterOverviewModel = {
  id: string;
  date: string;
  chargePointName: string;
  connectorId: string;
  customerId: string;
  name: string;
  externalCardId: string;
  internalCardId: string;
  global: string;
  energyDelivered: number;
  totalHT: number;
  durationInMin: number;
};

export type EnhancedQuarterOverviewModel = QuarterOverviewModel & {
  totalHTDelmonicos?: number;
  totalTTCDelmonicos?: number;
  evseUid?: string;
  transactionId?: string;
  iTransactionId?: string;
  checkoutFees?: number;
};

export type CheckoutFeeModel = {
  breakdownType: CheckoutBreakdownTypeEnum;
  iTransactionId: string;
  totalHT: number;
};
