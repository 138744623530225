import { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmspWebAppLogResponseModel } from 'src/core/model/log.model';
import { useCopyColumn } from 'src/feature/data-grid/column/use-copy-column';
import { useDatetimeColumn } from 'src/feature/data-grid/column/use-datetime-column';
import { useDidColumn } from 'src/feature/data-grid/column/use-did-column';
import { useJsonColumn } from 'src/feature/data-grid/column/use-json-column';
import { useTextColumn } from 'src/feature/data-grid/column/use-text-column';

export function useEmspWebAppLogColumn(): GridColDef[] {
  const { t } = useTranslation();

  const jsonColumn = useJsonColumn();
  const textColumn = useTextColumn();
  const datetimeColumn = useDatetimeColumn();
  const didColumn = useDidColumn();
  const copyColumn = useCopyColumn();

  const logTranslations: Record<keyof EmspWebAppLogResponseModel, string> = t(
    'model.emsp-web-app-log',
    {
      returnObjects: true,
    },
  );

  return useMemo(
    () => [
      {
        ...textColumn,
        field: 'level',
        width: 120,
        headerName: logTranslations.level,
        sortable: false,
      },
      {
        ...datetimeColumn,
        field: 'date',
        headerName: logTranslations.date,
        sortable: false,
      },
      {
        ...jsonColumn,
        field: 'headers',
        headerName: logTranslations.headers,
        sortable: false,
      },
      {
        ...didColumn,
        field: 'did',
        headerName: logTranslations.did,
        sortable: false,
      },
      {
        ...copyColumn,
        field: 'ipAddress',
        width: 240,
        headerName: logTranslations.ipAddress,
        sortable: false,
      },
    ],
    [
      jsonColumn,
      textColumn,
      logTranslations,
      datetimeColumn,
      didColumn,
      copyColumn,
    ],
  );
}
