import { ListItemIcon, ListItemText, PopoverProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { InAppStorage } from 'src/core/storage/in-app-storage';
import { useDispatch } from 'react-redux';
import { disconnect } from 'src/core/authentication/authentication.slice';
import { useNavigate } from 'react-router-dom';

interface UserMenuProps {
  readonly anchor: HTMLElement | null;
  readonly anchorOrigin?: PopoverProps['anchorOrigin'];
  readonly onClose: () => void;
}

export function UserMenu(props: UserMenuProps): React.JSX.Element {
  const { anchor, anchorOrigin, onClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  function handleDisconnect() {
    InAppStorage.jwt = null;
    dispatch(disconnect());
    navigate(0);
  }

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={onClose}
      anchorOrigin={anchorOrigin}>
      <MenuItem onClick={handleDisconnect}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('shared.layout.user-menu.log-out')}</ListItemText>
      </MenuItem>
    </Menu>
  );
}
