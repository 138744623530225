/*
    User form validation rules
    Validate user forms as : registration, login, update user informations
 */
export const PASSWORD_MIN_LENGTH = 2;
export const PASSWORD_MAX_LENGTH = 40;

export const EMAIL_PATTERN =
  // RegExp taken from https://emailregex.com - disable eslint to use exactly the RegExp provided by the RFC.
  // eslint-disable-next-line no-useless-escape
  /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

export const LOGIN_OTP_LENGTH = 4;

export const TRIGRAM_LENGTH = 3;

export const NAME_MIN_LENGTH = 2;
export const NAME_MAX_LENGTH = 40;

export const UUID_PATTERN =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export const DEFAULT_TARIFF_STRENGTH = 1;

export const COMMENT_MAX_LENGTH = 200;

export const TINY_URL_MAX_LENGTH = 46;

export const CUSTOMER_LOCK_DURATION_MIN = 0;
export const CUSTOMER_LOCK_DURATION_MAX = 60;
