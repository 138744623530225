import { DisplayTextModel } from 'src/core/model/tariff.model';
import { useTranslation } from 'react-i18next';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { TextField } from 'src/shared/form/text-field';
import { MenuItem } from '@mui/material';
import { SupportedLocaleEnum } from 'src/core/model/enum/supported-locale.enum';
import { Select } from 'src/shared/form/select';
import { useDisplayTextFormValidation } from './use-display-text.form-validation';

type Props = {
  prefixName: string;
  errors?: FieldErrors<DisplayTextModel>;
  touchedFields?: Partial<
    Readonly<{
      [x: string]: any;
    }>
  >;
};

export function DisplayTextForm(props: Readonly<Props>): React.JSX.Element {
  const { prefixName, errors, touchedFields } = props;
  const { t } = useTranslation();
  const { register, control } = useFormContext();

  const translations: Record<keyof DisplayTextModel, string> = t(
    'model.display-text',
    {
      returnObjects: true,
    },
  );

  const formValidation = useDisplayTextFormValidation();

  return (
    <>
      <Controller
        control={control}
        rules={formValidation.language}
        name={`${prefixName}.language`}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            errorMessage={errors?.language?.message as string}
            isTouched={touchedFields?.language}
            label={translations.language}
            labelId={`${prefixName}.language-select`}
            value={value}
            onChange={onChange}
            ref={ref}
            size="small">
            {Object.keys(SupportedLocaleEnum).map((key) => (
              <MenuItem value={key} key={key}>
                {t(`model.enum.supported-locale.${key}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      <TextField
        size="small"
        {...register(`${prefixName}.text`, formValidation.text)}
        id={`${prefixName}.text`}
        label={translations.text}
        errorMessage={errors?.text?.message as string}
        isTouched={touchedFields?.text}
      />
    </>
  );
}
