import { useTranslation } from 'react-i18next';
import { Link as RouterLink, matchPath } from 'react-router-dom';
import { Button } from '@mui/material';
import { FlatRoutingConfiguration } from 'src/config/routing.config';

type BreadcrumbItemProps = {
  readonly path: string;
  readonly subPath?: string;
};

export function BreadcrumbItem(
  props: BreadcrumbItemProps,
): React.JSX.Element | null {
  const { t } = useTranslation();
  const { path, subPath = t('unknown') } = props;

  const routerItem = FlatRoutingConfiguration.find(
    (item) => !!matchPath(item.route, path),
  );
  const label = routerItem?.labelKey ? t(routerItem.labelKey) : subPath;

  return (
    <Button
      component={RouterLink}
      to={path}
      variant="text"
      startIcon={routerItem?.icon}
      sx={{ color: 'text.secondary' }}>
      {label}
    </Button>
  );
}
