import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitle } from 'src/shared/typography/title';
import EvStationIcon from '@mui/icons-material/EvStation';
import { useCreateChargePointMutation } from 'src/core/service/charge-point.service';
import { ChargePointFormModel } from 'src/core/model/charge-point.model';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useApiError } from 'src/core/helper/use-api-error';

import { ChargePointFormView } from '../charge-point-form/charge-point-form.view';

type ChargePointCreateProps = {
  readonly onCancel: () => void;
  readonly onSuccess: () => void;
};

export function ChargePointCreate(
  props: ChargePointCreateProps,
): React.JSX.Element {
  const { onCancel, onSuccess } = props;
  const { t } = useTranslation();

  const [createChargePoint, { isLoading, isError, error }] =
    useCreateChargePointMutation();

  const { message, status } = useApiError(error);

  const handleCreate = (payload: ChargePointFormModel) => {
    createChargePoint(payload).then(() => onSuccess());
  };

  return (
    <>
      <DialogTitle icon={<EvStationIcon />}>
        {t('charge-point.create.title')}
      </DialogTitle>

      <ChargePointFormView
        formType="create"
        onCancel={onCancel}
        onSubmit={handleCreate}
        pending={isLoading}
      />

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.create-charge-point-mutation', { message, status })}
        />
      )}
    </>
  );
}
