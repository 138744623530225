import { useEffect, useState } from 'react';
import {
  CheckoutFeeModel,
  QuarterOverviewModel,
} from 'src/core/model/quarter-overview.model';
import { QuarterOverviewFileTypeEnum } from 'src/core/model/enum/quarter-overview-file-type.enum';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Logger } from 'src/core/logger/logger';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'src/shared/button/button';
import {
  parse,
  parseToCheckoutFeeModel,
  parseToQuarterOverviewModel,
} from '../quarter-overview.utils';

type Props = {
  readonly sx?: SxProps;
  readonly file: File;
  readonly onLmsUpload: (data: QuarterOverviewModel[]) => void;
  readonly onCheckoutUpload: (data: CheckoutFeeModel[]) => void;
};

type QuarterOverviewFileUploadManagerForm = {
  fileType: QuarterOverviewFileTypeEnum;
};

export function QuarterOverviewFileUploadManager(
  props: Props,
): React.JSX.Element {
  const { file, onCheckoutUpload, onLmsUpload, sx } = props;
  const { t } = useTranslation();

  const [parsedLines, setParsedLines] = useState<any[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<QuarterOverviewFileUploadManagerForm>({
    mode: 'onChange',
  });

  const onSubmit = (data: QuarterOverviewFileUploadManagerForm) => {
    switch (data.fileType) {
      case QuarterOverviewFileTypeEnum.CHECKOUT:
        onCheckoutUpload(parsedLines.map(parseToCheckoutFeeModel));
        return;

      case QuarterOverviewFileTypeEnum.LMS:
        onLmsUpload(parsedLines.map(parseToQuarterOverviewModel));
        return;

      default:
        Logger.warn('File type must be selected before submition.');
    }
  };

  useEffect(() => {
    parse(file)
      .then((result) => {
        setParsedLines(result);
      })
      .catch((error) => {
        Logger.error(error);
      });
  }, [file]);

  return (
    <Stack sx={sx} direction="column" gap={2} alignItems="center">
      <Box sx={{ flex: 1, textAlign: 'center' }}>
        <FilePresentIcon sx={{ fontSize: 64 }} />

        <Typography>{file.name}</Typography>
      </Box>

      {isSubmitted ? (
        <CheckCircleIcon color="success" />
      ) : (
        <Stack
          direction="column"
          alignItems="center"
          width="100%"
          gap={1}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="fileType"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth error={!!errors.fileType}>
                <InputLabel id={`file-type-${file.name}-select-label`}>
                  {t('quarter-overview.upload.file-type-label')}
                </InputLabel>

                <Select
                  labelId={`file-type-${file.name}-select-label`}
                  id={`file-type-${file.name}-select`}
                  value={value ?? ''}
                  label={t('quarter-overview.upload.file-type-label')}
                  onChange={(event) =>
                    onChange(
                      event.target.value as QuarterOverviewFileTypeEnum | null,
                    )
                  }>
                  {Object.values(QuarterOverviewFileTypeEnum).map((item) => (
                    <MenuItem key={item} value={item}>
                      {t(`model.enum.quarter-overview-file-type.${item}`)}
                    </MenuItem>
                  ))}
                </Select>

                {errors.fileType && (
                  <FormHelperText>
                    {t('quarter-overview.upload.file-type-error')}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Button type="submit" variant="outlined">
            {t('quarter-overview.upload.submit-button')}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
