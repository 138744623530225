import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';
import { DataGridQueryModel } from 'src/core/model/data-grid.model';

const SEARCH_PARAM_KEYS = {
  paginate: 'paginate',
  sort: 'sort',
  textSearch: 'textSearch',
  filter: 'filter',
};

export function serializeDataGridQueryModel(
  params: DataGridQueryModel,
): URLSearchParams {
  const searchParams = new URLSearchParams();

  if (params.paginate && Object.keys(params.paginate).length) {
    searchParams.set(
      SEARCH_PARAM_KEYS.paginate,
      JSON.stringify(params.paginate),
    );
  }

  if (params?.sort?.length) {
    searchParams.set(SEARCH_PARAM_KEYS.sort, JSON.stringify(params.sort));
  }

  if (params.textSearch) {
    searchParams.set(SEARCH_PARAM_KEYS.textSearch, params.textSearch);
  }

  if (params.filter && Object.keys(params.filter).length) {
    searchParams.set(SEARCH_PARAM_KEYS.filter, JSON.stringify(params.filter));
  }

  return searchParams;
}

export function deserializeDataGridQueryModel(
  searchParams: URLSearchParams,
): DataGridQueryModel {
  const query: DataGridQueryModel = {};
  const paginate =
    searchParams.get(SEARCH_PARAM_KEYS.paginate) ??
    JSON.stringify(DEFAULT_DATA_GRID_MODEL.paginate);

  const sort =
    searchParams.get(SEARCH_PARAM_KEYS.sort) ??
    JSON.stringify(DEFAULT_DATA_GRID_MODEL.sort);

  const textSearch =
    searchParams.get(SEARCH_PARAM_KEYS.textSearch) ??
    DEFAULT_DATA_GRID_MODEL.textSearch;

  const filter =
    searchParams.get(SEARCH_PARAM_KEYS.filter) ??
    JSON.stringify(DEFAULT_DATA_GRID_MODEL.filter);

  try {
    query.paginate = JSON.parse(paginate);
  } catch {
    query.paginate = DEFAULT_DATA_GRID_MODEL.paginate;
  }

  try {
    query.sort = JSON.parse(sort);
  } catch {
    query.sort = DEFAULT_DATA_GRID_MODEL.sort;
  }

  query.textSearch = textSearch;

  try {
    query.filter = JSON.parse(filter);
  } catch {
    query.filter = DEFAULT_DATA_GRID_MODEL.filter;
  }

  return query;
}
