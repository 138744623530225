import { GridColTypeDef } from '@mui/x-data-grid';
import { useCopyColumn } from './use-copy-column';

export function useDidColumn(): GridColTypeDef {
  const identifierColumn = useCopyColumn();

  return {
    ...identifierColumn,
    width: 220,
  };
}
