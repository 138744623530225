import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getJwt } from 'src/core/authentication/authentication.slice';
import { ConfigurationService } from 'src/core/configuration/configuration.service';
import type { RootState } from 'src/core/store';

export function appendAuthorizationHeader(
  headers: Headers,
  { getState }: { getState: () => unknown },
): Headers {
  const jwt = getJwt(getState() as RootState);

  if (jwt) {
    headers.set('Authorization', `Bearer ${jwt}`);
  }

  return headers;
}

export function baseQueryBuilder(): BaseQueryFn {
  return fetchBaseQuery({
    baseUrl: ConfigurationService.getApi(),
    prepareHeaders: appendAuthorizationHeader,
  });
}
