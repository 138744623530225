import { useEffect, useMemo, useState } from 'react';
import {
  CheckoutFeeModel,
  EnhancedQuarterOverviewModel,
} from 'src/core/model/quarter-overview.model';
import { sum } from 'radash';
import { QuarterOverviewStateEnum } from 'src/core/model/enum/quarter-overview-state.enum';
import { CheckoutBreakdownTypeEnum } from 'src/core/model/enum/checkout-breakdown-type.enum';
import { CheckoutSectionProps } from './checkout-section';

type Props = {
  readonly rawCheckoutList?: CheckoutFeeModel[];
  readonly iTransactionId?: string;
  readonly onChange: (data: Partial<EnhancedQuarterOverviewModel>) => void;
};

type UseEnhanceWithCheckout = CheckoutSectionProps & {
  state: QuarterOverviewStateEnum;
};

export function useEnhanceWithCheckout(props: Props): UseEnhanceWithCheckout {
  const { rawCheckoutList = [], iTransactionId, onChange } = props;

  const [list, setList] = useState<CheckoutFeeModel[]>([]);
  const [totalHT, setTotalHT] = useState<number>(0);

  const [state, setState] = useState<QuarterOverviewStateEnum>(
    QuarterOverviewStateEnum.INIT,
  );

  useEffect(() => {
    if (
      rawCheckoutList.length === 0 ||
      !iTransactionId ||
      state === QuarterOverviewStateEnum.SUCCESS
    ) {
      return;
    }

    const nextList = rawCheckoutList
      .filter(
        (item) =>
          item.breakdownType !== CheckoutBreakdownTypeEnum.PAYOUT &&
          item.breakdownType !== CheckoutBreakdownTypeEnum.CAPTURE &&
          item.breakdownType !== CheckoutBreakdownTypeEnum.PARTIAL_CAPTURE,
      )
      .filter((item) => item.iTransactionId === iTransactionId);

    if (nextList.length === 0) {
      setState(QuarterOverviewStateEnum.NO_ITEM_FOUND);
      return;
    }

    setList(nextList);

    const checkoutFees = sum(nextList, (item) => item.totalHT);
    setTotalHT(checkoutFees);

    setState(QuarterOverviewStateEnum.SUCCESS);

    onChange({ checkoutFees });
  }, [rawCheckoutList, iTransactionId, onChange, state]);

  return useMemo(
    () => ({
      list,
      totalHT,
      state,
    }),
    [list, totalHT, state],
  );
}
