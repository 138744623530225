import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { InAppStorage } from 'src/core/storage/in-app-storage';
import { AppBar } from 'src/shared/layout/dashboard-layout/app-bar/app-bar';
import { DrawerMenuContainer } from 'src/shared/layout/dashboard-layout/drawer-menu-container/drawer-menu-container';

export function DashboardLayout(): React.JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isDrawerMenuOpen, setIsDrawerMenuOpen] = useState(
    InAppStorage.keepMenuOpen,
  );
  const handleOpenMenuDrawer = () => {
    setIsDrawerMenuOpen(true);
    InAppStorage.keepMenuOpen = true;
  };
  const handleCloseDrawerMenu = () => {
    setIsDrawerMenuOpen(false);
    InAppStorage.keepMenuOpen = false;
  };

  const [isMobileDrawerMenuOpen, setIsMobileDrawerMenuOpen] = useState(false);
  const handleOpenMobileDrawerMenu = () => setIsMobileDrawerMenuOpen(true);
  const handleCloseMobileDrawerMenu = () => setIsMobileDrawerMenuOpen(false);

  return (
    <Box
      sx={{
        display: 'flex',
        minWidth: theme.breakpoints.values.sm,
        height: '100%',
      }}>
      <AppBar
        isMobile={isMobile}
        isMenuOpen={isMobile ? isMobileDrawerMenuOpen : isDrawerMenuOpen}
        onOpenMenu={
          isMobile ? handleOpenMobileDrawerMenu : handleOpenMenuDrawer
        }
      />

      <DrawerMenuContainer
        isMobile={isMobile}
        isOpen={isMobile ? isMobileDrawerMenuOpen : isDrawerMenuOpen}
        onClose={isMobile ? handleCloseMobileDrawerMenu : handleCloseDrawerMenu}
      />

      <Outlet />
    </Box>
  );
}
