import { Drawer, IconButton, useTheme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useState } from 'react';
import { FilterForm, FilterFormProps } from './filter-form';

export function FilterDrawer(
  filterFormProps: Readonly<FilterFormProps>,
): React.JSX.Element | null {
  const { filterDefinition = [] } = filterFormProps;
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  if (filterDefinition.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)}>
        <FilterListIcon />
      </IconButton>

      <Drawer
        sx={{ zIndex: theme.zIndex.drawer + 2 }}
        PaperProps={{ sx: { padding: 4, rowGap: 4, width: 400 } }}
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <FilterForm {...filterFormProps} />
      </Drawer>
    </>
  );
}
