import { useTranslation } from 'react-i18next';
import { ChargingSessionResponseModel } from 'src/core/model/charging-session.model';
import { FilterDefinitionModel } from 'src/core/model/data-grid.model';
import { ChargingSessionsFilterStatusEnum } from 'src/core/model/enum/charging-session-status.enum';
import { useCheckboxFilter } from 'src/feature/data-grid/filter/generic-filter/use-checkbox-filter';
import { useDateRangeFilter } from 'src/feature/data-grid/filter/generic-filter/use-date-range-filter';
import { useNumberRangeFilter } from 'src/feature/data-grid/filter/generic-filter/use-number-range-filter';
import { useTextFilter } from 'src/feature/data-grid/filter/generic-filter/use-text-filter';

export function useChargingSessionFilter(): FilterDefinitionModel[] {
  const { t } = useTranslation();

  const chargingSessionTranslations: Record<
    keyof ChargingSessionResponseModel,
    string
  > = t('model.charging-session', {
    returnObjects: true,
  });

  const pseudoFilter = useTextFilter(
    'pseudo',
    chargingSessionTranslations.pseudo,
  );

  const totalTTCFilter = useNumberRangeFilter({
    label: chargingSessionTranslations.totalTTC,
    keyMin: 'totalTTCMin',
    keyMax: 'totalTTCMax',
    valueFormatter: (value) => t('data-grid.row.currency', { value }),
  });

  const totalEnergyDeliveredFilter = useNumberRangeFilter({
    label: chargingSessionTranslations.energyDelivered,
    keyMin: 'energyDeliveredMin',
    keyMax: 'energyDeliveredMax',
    valueFormatter: (value) => t('data-grid.row.energy-delivered', { value }),
  });

  const updatedAtFilter = useDateRangeFilter({
    label: chargingSessionTranslations.updatedAt,
    keyMin: 'updatedAfter',
    keyMax: 'updatedBefore',
  });

  const stateFilter = useCheckboxFilter({
    field: 'state',
    label: chargingSessionTranslations.status,
    options: [
      ChargingSessionsFilterStatusEnum.isPending,
      ChargingSessionsFilterStatusEnum.isTerminated,
    ],
    valueFormatter: (value): string =>
      t(`model.enum.charging-sessions-filter-status-enum.${value}`),
  });

  return [
    pseudoFilter,
    totalTTCFilter,
    totalEnergyDeliveredFilter,
    updatedAtFilter,
    stateFilter,
  ];
}
