import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckoutFeeModel } from 'src/core/model/quarter-overview.model';

export type CheckoutSectionProps = {
  readonly list: CheckoutFeeModel[];
  readonly totalHT: number;
};

export function CheckoutSection(
  props: CheckoutSectionProps,
): React.JSX.Element {
  const { list, totalHT } = props;
  const { t } = useTranslation();

  const checkoutTranslations: Record<keyof CheckoutFeeModel, string> = t(
    'model.checkout-fee',
    {
      returnObjects: true,
    },
  );

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        {t('quarter-overview.checkout-section.title', {
          count: list.length,
        })}
      </Typography>

      {list.length > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{checkoutTranslations.iTransactionId}</TableCell>
              <TableCell>{checkoutTranslations.breakdownType}</TableCell>
              <TableCell align="right">
                {checkoutTranslations.totalHT}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list.map((checkoutFee) => (
              <TableRow key={checkoutFee.iTransactionId}>
                <TableCell>{checkoutFee.iTransactionId}</TableCell>
                <TableCell>{checkoutFee.breakdownType}</TableCell>

                <TableCell align="right">
                  {t('data-grid.row.currency', {
                    value: checkoutFee.totalHT,
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell>
                {t('quarter-overview.checkout-section.total')}
              </TableCell>
              <TableCell align="right">
                {t('data-grid.row.currency', {
                  value: totalHT,
                })}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </div>
  );
}
