import { createApi } from '@reduxjs/toolkit/query/react';

import { LOG_API } from 'src/config/api-routes';
import { baseQueryBuilder } from './base-query.builder';
import {
  DataGridQueryModel,
  PaginationResponseModel,
} from '../model/data-grid.model';
import {
  groupOcpiFilters,
  mapDataGridQueryModelToApiQuery,
} from '../helper/map-data-grid-query-model-to-api-query';
import { LogModel, LogResponseModel } from '../model/log.model';
import { mapLogResponseModelToLogModel } from '../helper/map-log-response-model-to-log-model';

export const logApi = createApi({
  reducerPath: 'logApi',
  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getListLog: builder.query<
      PaginationResponseModel<LogModel>,
      DataGridQueryModel
    >({
      query: (params: DataGridQueryModel) =>
        `${LOG_API}?${mapDataGridQueryModelToApiQuery({
          ...params,
          filter: params.filter
            ? groupOcpiFilters(params.filter)
            : params.filter,
        }).toString()}`,
      transformResponse: (
        response: PaginationResponseModel<LogResponseModel>,
      ): PaginationResponseModel<LogModel> => {
        const { docs, ...paginatedResponse } = response;

        return {
          ...paginatedResponse,
          docs: docs?.map(
            (logResponseModel) =>
              mapLogResponseModelToLogModel(logResponseModel) ?? [],
          ),
        };
      },
    }),
  }),
});

export const { useGetListLogQuery } = logApi;
