import { GridColTypeDef } from '@mui/x-data-grid';
import { useTextColumn } from './use-text-column';

export function useNameColumn(): GridColTypeDef {
  const textColumn = useTextColumn();

  return {
    ...textColumn,
    width: 120,
  };
}
