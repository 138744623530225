import { useCreateUserMutation } from 'src/core/service/user.service';
import { UserFormModel } from 'src/core/model/user.model';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { DialogTitle } from 'src/shared/typography/title';
import { WithUserId } from 'src/core/helper/with-user-id';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useApiError } from 'src/core/helper/use-api-error';
import { useEffect } from 'react';
import { UserFormView } from '../user-form/user-form.view';

export type CreateGenericUserProps = {
  readonly userId: string;
  readonly onCancel: () => void;
  readonly onSuccess: () => void;
};

type UserCreateProps = CreateGenericUserProps & {
  readonly title: string;
  readonly endpoint: string;
};

function UserCreate(props: UserCreateProps): React.JSX.Element {
  const { title, endpoint, userId, onCancel, onSuccess } = props;
  const { t } = useTranslation();

  const [createUser, { isSuccess, isLoading, isError, error }] =
    useCreateUserMutation();
  const { status, message, code } = useApiError(error);

  const handleSubmit = (payload: UserFormModel): void => {
    createUser({
      endpoint,
      ...payload,
      supervisorId: userId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  });

  return (
    <>
      <DialogTitle icon={<PersonAddIcon />}>{title}</DialogTitle>

      <UserFormView
        formType="create"
        onSubmit={handleSubmit}
        pending={isLoading}
        onCancel={onCancel}
        errorCode={code}
      />

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.create-user-mutation', { status, message })}
        />
      )}
    </>
  );
}

export const CreateUser = WithUserId(UserCreate);
