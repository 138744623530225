import { SxProps, styled } from '@mui/material/styles';
import { ChangeEvent, useRef, useState } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type StyledDropzoneProps = {
  isDragging?: boolean;
};

const StyledDropzone = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragging',
})<StyledDropzoneProps>(({ theme, isDragging }) => ({
  border: `0.2rem dashed ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(4),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.25s background-color ease-in-out',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.default,

  ...(isDragging && {
    backgroundColor: theme.palette.primary.light,
  }),

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

type Props = {
  readonly label?: string;
  readonly accept?: string;
  readonly onChange: (files: FileList) => void;
  readonly sx?: SxProps;
};

export function Dropzone(props: Props): React.JSX.Element {
  const { t } = useTranslation();
  const {
    label = t('shared.file-upload.dropzone'),
    onChange,
    accept,
    sx,
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onChange(event.target.files);
    }
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDragging(false);
    onChange(event.dataTransfer.files);
  };

  const handleDropzoneClick = () => {
    inputRef.current?.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setIsDragging(true);
  };

  return (
    <StyledDropzone
      sx={sx}
      isDragging={isDragging}
      onDragOver={handleDragOver}
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
      onDrop={handleDrop}
      onClick={handleDropzoneClick}>
      <VisuallyHiddenInput
        ref={inputRef}
        type="file"
        multiple
        onChange={handleInputChange}
        accept={accept}
      />

      <FileUploadIcon sx={{ fontSize: 64 }} />

      <Typography variant="h5">{label}</Typography>
    </StyledDropzone>
  );
}
