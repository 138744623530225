/* eslint-disable react/destructuring-assignment */
import { ReactNode } from 'react';
import {
  FilterDefinitionModel,
  FilterQueryModel,
  FilterValueModel,
} from 'src/core/model/data-grid.model';
import { FilterChip } from '../active-filter/filter-chip';

type UseRangeChipProps = {
  label: string;
  keyMin: string;
  keyMax: string;
  valueFormatter?: (value: FilterValueModel) => string;
};

export function useRangeChip(
  props: UseRangeChipProps,
): FilterDefinitionModel['getChip'] {
  const {
    label,
    keyMin,
    keyMax,
    valueFormatter = (value) => value.toString(),
  } = props;

  return (filterQuery: FilterQueryModel, onDelete): ReactNode => {
    if (!filterQuery[keyMin] && !filterQuery[keyMax]) {
      return null;
    }

    const before = filterQuery[keyMin]
      ? `${valueFormatter(filterQuery[keyMin])} <=`
      : '';

    const after = filterQuery[keyMax]
      ? `<= ${valueFormatter(filterQuery[keyMax])}`
      : '';

    return (
      <FilterChip
        key={`${keyMin}-${keyMax}`}
        label={`${before} ${label} ${after}`}
        onDelete={() => {
          onDelete(keyMin, keyMax);
        }}
      />
    );
  };
}
