import { TariffRestrictionModel } from 'src/core/model/tariff.model';
import { useTranslation } from 'react-i18next';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { TextField } from 'src/shared/form/text-field';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TIME_RESTRICTION_FORMAT } from 'src/config/app.config';
import { useTariffRestrictionFormValidation } from './use-tariff-restriction.form-validation';

type Props = {
  prefixName: string;
  errors?: FieldErrors<TariffRestrictionModel>;
  touchedFields?: Partial<
    Readonly<{
      [x: string]: any;
    }>
  >;
};

export function TariffRestrictionForm(
  props: Readonly<Props>,
): React.JSX.Element {
  const { prefixName, errors, touchedFields } = props;
  const { t } = useTranslation();
  const { register, control } = useFormContext();

  const translations: Record<keyof TariffRestrictionModel, string> = t(
    'model.tariff-restriction',
    {
      returnObjects: true,
    },
  );

  const formValidation = useTariffRestrictionFormValidation();

  return (
    <>
      <Controller
        control={control}
        rules={formValidation.startTime}
        name={`${prefixName}.startTime`}
        render={({ field: { onChange, value, ref } }) => {
          return (
            <TimePicker
              ampm={false}
              ref={ref}
              label={translations.startTime}
              onChange={(newValue) =>
                onChange(newValue?.format(TIME_RESTRICTION_FORMAT) ?? null)
              }
              value={value ? dayjs(value, TIME_RESTRICTION_FORMAT) : null}
              slotProps={{
                textField: {
                  size: 'small',
                  margin: 'dense',
                  error: errors?.startTime?.message && touchedFields?.startTime,
                  helperText: errors?.startTime?.message,
                },
              }}
            />
          );
        }}
      />

      <Controller
        control={control}
        rules={formValidation.endTime}
        name={`${prefixName}.endTime`}
        render={({ field: { onChange, value, ref } }) => {
          return (
            <TimePicker
              ampm={false}
              ref={ref}
              label={translations.endTime}
              onChange={(newValue) =>
                onChange(newValue?.format(TIME_RESTRICTION_FORMAT) ?? null)
              }
              value={value ? dayjs(value, TIME_RESTRICTION_FORMAT) : null}
              slotProps={{
                textField: {
                  size: 'small',
                  margin: 'dense',
                  error: errors?.endTime?.message && touchedFields?.endTime,
                  helperText: errors?.endTime?.message,
                },
              }}
            />
          );
        }}
      />

      <TextField
        size="small"
        type="number"
        {...register(`${prefixName}.minKwh`, formValidation.minKwh)}
        id={`${prefixName}.minKwh`}
        label={translations.minKwh}
        errorMessage={errors?.minKwh?.message as string}
        isTouched={touchedFields?.minKwh}
        endAdornment="kWh"
      />

      <TextField
        size="small"
        type="number"
        {...register(`${prefixName}.maxKwh`, formValidation.maxKwh)}
        id={`${prefixName}.maxKwh`}
        label={translations.maxKwh}
        errorMessage={errors?.maxKwh?.message as string}
        isTouched={touchedFields?.maxKwh}
        endAdornment="kWh"
      />
    </>
  );
}
