import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { VerifyOtpModel } from 'src/core/model/login.model';
import TextField from '@mui/material/TextField';
import { SubmitButton } from 'src/shared/button/submit.button';
import {
  EmailOtpForm,
  useEmailOtpFormValidation,
} from './use-email-otp.form-validation';
import { BodyLayout } from '../../component/login-layout/body.layout';

type EmailOtpStepViewProps = {
  readonly emailAddress?: string;
  readonly pending: boolean;
  readonly isError: boolean;
  readonly onSubmit: (payload: EmailOtpForm) => void;
};

export function EmailOtpStepView(
  props: EmailOtpStepViewProps,
): React.JSX.Element {
  const { emailAddress = '', pending, isError, onSubmit } = props;

  const { t } = useTranslation();
  const formValidation = useEmailOtpFormValidation();

  const loginTranslations: Record<keyof VerifyOtpModel, string> = t(
    'model.verify-otp',
    {
      returnObjects: true,
    },
  );

  const {
    handleSubmit,
    control,
    formState: { touchedFields, errors, isValid },
  } = useForm<EmailOtpForm>({
    mode: 'onChange',
    defaultValues: {
      emailAddress,
    },
  });

  const handleCodeChange =
    (callback: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const onlyNumbers = event.target.value.replace(/(\D)*/gm, '');
      return callback(onlyNumbers);
    };

  return (
    <BodyLayout component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Typography component="h1" variant="h2">
          {t('login.email-otp-step.title')}
        </Typography>

        <Typography variant="subtitle1">
          {t('login.email-otp-step.subtitle')}
        </Typography>
      </Stack>

      <Stack gap={3}>
        <Controller
          control={control}
          name="code"
          rules={formValidation.code}
          render={({ field: { onChange, ref, onBlur, value } }) => (
            <TextField
              autoFocus
              label={loginTranslations.code}
              id="otp"
              margin="dense"
              fullWidth
              error={touchedFields?.code && !!errors?.code?.message}
              helperText={touchedFields?.code && errors?.code?.message}
              ref={ref}
              onChange={handleCodeChange(onChange)}
              onBlur={onBlur}
              value={value ?? ''}
            />
          )}
        />

        {isError && (
          <Typography sx={{ color: 'error.main' }}>
            {t('login.email-otp-step.error-otp')}
          </Typography>
        )}
      </Stack>

      <SubmitButton
        loading={pending}
        disabled={!isValid}
        fullWidth
        sx={{ mt: 3, mb: 2 }}>
        {t('login.email-otp-step.submit-button')}
      </SubmitButton>
    </BodyLayout>
  );
}
