import {
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

type ChargingSessionListItemProps = Omit<ListItemProps, 'children'> & {
  startedAt?: string;
  totalKwh?: number;
  locationName?: string;
  city?: string;
};

export function ChargingSessionListItem(
  props: Readonly<ChargingSessionListItemProps>,
): React.JSX.Element {
  const { startedAt, totalKwh, locationName, city, ...listItemProps } = props;
  const { t } = useTranslation();

  return (
    <ListItem {...listItemProps}>
      <ListItemIcon>
        <ElectricBoltIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          startedAt
            ? t('charging-session.list-item.primary', {
                value: new Date(startedAt),
              })
            : t('charging-session.card.fallback')
        }
        secondary={
          totalKwh || locationName || city
            ? t('charging-session.list-item.secondary', {
                totalKwh: totalKwh ?? '-',
                locationName,
                city,
              })
            : t('charging-session.card.fallback')
        }
      />
    </ListItem>
  );
}
