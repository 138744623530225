import Dialog from '@mui/material/Dialog';

import { useUpdatePassword } from './use-update-password';
import { UpdatePasswordForm } from './update-password.form';

type UpdatePasswordDialogProps = {
  readonly title?: string;
  readonly caption?: string;
  readonly isOpen: boolean;
  readonly onClose?: () => void;
};

export function UpdatePasswordDialog(
  props: UpdatePasswordDialogProps,
): React.JSX.Element {
  const { isOpen, onClose = () => {}, ...formProps } = props;

  const { onSubmit, isLoading, isError } = useUpdatePassword({
    onSuccess: onClose,
  });

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <UpdatePasswordForm
        isError={isError}
        onSubmit={onSubmit}
        isLoading={isLoading}
        {...formProps}
      />
    </Dialog>
  );
}
