import { DEVELOPER_ENDPOINT } from 'src/config/api-routes';
import { useLayoutEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { useDialog } from 'src/core/helper/use-dialog';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import MuiButton from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useSetPageActionContext } from 'src/shared/layout/page-layout/page-layout.context-provider';
import { UserResponseModel } from 'src/core/model/user.model';
import { UserDataGrid } from '../../generic-user/user-data-grid/user.data-grid';
import { CreateDeveloper } from '../developer-create/developer-create';
import { DeveloperUpdate } from '../developer-update/developer-update';

export function DeveloperList(): React.JSX.Element {
  const {
    isOpen: isCreateDialogOpen,
    onOpen: onOpenCreateDialog,
    onClose: onCloseCreateDialog,
  } = useDialog();
  const [userUpdate, setUserUpdate] = useState<UserResponseModel | null>(null);

  const { t } = useTranslation();
  const setPageAction = useSetPageActionContext();

  const handleCloseUserUpdateDialog = () => {
    setUserUpdate(null);
  };

  useLayoutEffect(() => {
    const createDeveloperButton = (
      <MuiButton
        key="create-developer"
        onClick={onOpenCreateDialog}
        variant="contained">
        <AddOutlinedIcon />
        {t('form.create-button')}
      </MuiButton>
    );

    setPageAction([createDeveloperButton]);
  }, [t, onOpenCreateDialog, setPageAction]);

  return (
    <>
      <UserDataGrid
        endpoint={DEVELOPER_ENDPOINT}
        onUpdateUser={setUserUpdate}
      />

      <Dialog open={isCreateDialogOpen} onClose={onCloseCreateDialog}>
        <CreateDeveloper
          onCancel={onCloseCreateDialog}
          onSuccess={onCloseCreateDialog}
        />
      </Dialog>

      <Dialog open={!!userUpdate} onClose={handleCloseUserUpdateDialog}>
        {userUpdate && (
          <DeveloperUpdate
            user={userUpdate}
            onCancel={handleCloseUserUpdateDialog}
            onSuccess={handleCloseUserUpdateDialog}
          />
        )}
      </Dialog>
    </>
  );
}
