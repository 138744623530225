import Dialog from '@mui/material/Dialog';
import MuiButton from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useDialog } from 'src/core/helper/use-dialog';
import { useTranslation } from 'react-i18next';
import { useSetPageActionContext } from 'src/shared/layout/page-layout/page-layout.context-provider';
import { QrCodeManager } from 'src/feature/qr-code/qr-code-manager/qr-code-manager';
import { useLayoutEffect, useState } from 'react';
import { ChargePointResponseModel } from 'src/core/model/charge-point.model';
import { ChargePointCreate } from '../charge-point-create/charge-point-create';
import { ChargePointDataGrid } from '../charge-point-data-grid/charge-point.data-grid';

export function ChargePointList(): React.JSX.Element {
  const { isOpen, onOpen, onClose } = useDialog();
  const { t } = useTranslation();

  const setPageAction = useSetPageActionContext();

  const [chargePointToGenerateQRCode, setChargePointToGenerateQRCode] =
    useState<ChargePointResponseModel | null>(null);

  const handleCloseQRcodeDialog = () => {
    setChargePointToGenerateQRCode(null);
  };

  useLayoutEffect(() => {
    const createChargePointButton = (
      <MuiButton key="create-charge-point" onClick={onOpen} variant="contained">
        <AddOutlinedIcon />
        {t('form.create-button')}
      </MuiButton>
    );

    setPageAction([createChargePointButton]);
  }, [t, onOpen, setPageAction]);

  return (
    <>
      <ChargePointDataGrid onGenerateQRCode={setChargePointToGenerateQRCode} />

      <Dialog open={isOpen}>
        <ChargePointCreate onCancel={onClose} onSuccess={onClose} />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="md"
        open={!!chargePointToGenerateQRCode}
        onClose={handleCloseQRcodeDialog}>
        {chargePointToGenerateQRCode && (
          <QrCodeManager
            chargePointId={chargePointToGenerateQRCode.chargePointId}
            tinyId={chargePointToGenerateQRCode.qrCode?.tinyId}
            tinyUrl={chargePointToGenerateQRCode.qrCode?.tinyUrl}
            onSuccess={handleCloseQRcodeDialog}
          />
        )}
      </Dialog>
    </>
  );
}
