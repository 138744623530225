import { useTranslation } from 'react-i18next';
import { FilterDefinitionModel } from 'src/core/model/data-grid.model';
import { UserResponseModel } from 'src/core/model/user.model';
import { useDateRangeFilter } from 'src/feature/data-grid/filter/generic-filter/use-date-range-filter';
import { useTextFilter } from 'src/feature/data-grid/filter/generic-filter/use-text-filter';

export function useUserFilter(): FilterDefinitionModel[] {
  const { t } = useTranslation();

  const userTranslations: Record<keyof UserResponseModel, string> = t(
    'model.user',
    {
      returnObjects: true,
    },
  );

  const idFilter = useTextFilter('id', userTranslations.id);
  const firstNameFilter = useTextFilter(
    'firstName',
    userTranslations.firstName,
  );
  const lastNameFilter = useTextFilter('lastName', userTranslations.lastName);
  const trigramFilter = useTextFilter('trigram', userTranslations.trigram);
  const supervisorIdFilter = useTextFilter(
    'supervisorId',
    userTranslations.supervisorId,
  );

  const updatedAtFilter = useDateRangeFilter({
    label: userTranslations.updatedAt,
    keyMin: 'updatedAfter',
    keyMax: 'updatedBefore',
  });

  const createdAtFilter = useDateRangeFilter({
    label: userTranslations.createdAt,
    keyMin: 'createdAfter',
    keyMax: 'createdBefore',
  });

  return [
    idFilter,
    firstNameFilter,
    lastNameFilter,
    trigramFilter,
    supervisorIdFilter,
    updatedAtFilter,
    createdAtFilter,
  ];
}
