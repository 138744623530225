import { GridColTypeDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TextOverflow } from 'src/shared/typography/text-overflow';

export function useTextColumn(): GridColTypeDef {
  return {
    type: 'string',
    renderCell: (
      params: GridRenderCellParams<any, string>,
    ): React.JSX.Element => (
      <TextOverflow maxWidth={params.colDef.computedWidth}>
        {params.formattedValue}
      </TextOverflow>
    ),
  };
}
