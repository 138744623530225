import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Title } from 'src/shared/typography/title';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import {
  PageActionContextProvider,
  usePageActionContext,
} from './page-layout.context-provider';

const Main = styled('main')(({ theme }) => ({
  flexGrow: theme.spacing(1),
  padding: theme.spacing(3),
  paddingTop: 0,
  overflowX: 'hidden',
  height: '100%',
  width: '100%',

  display: 'flex',
  gap: theme.spacing(4),
  flexDirection: 'column',
}));

function PageLayout(
  props: Readonly<React.PropsWithChildren<{}>>,
): React.JSX.Element {
  const { children } = props;

  return (
    <Main>
      <Toolbar />
      <PageActionContextProvider>{children}</PageActionContextProvider>
    </Main>
  );
}

export type PageHeaderProps = {
  readonly labelKey: string;
  readonly icon: ReactNode;
};

function PageHeader(props: PageHeaderProps): React.JSX.Element {
  const { icon, labelKey: title } = props;
  const { t } = useTranslation();

  const actions = usePageActionContext();

  return (
    <Stack justifyContent="space-between" direction="row">
      <Title icon={icon}>{t(title)}</Title>
      <Stack direction="row" gap={1}>
        {actions}
      </Stack>
    </Stack>
  );
}

PageLayout.Header = PageHeader;

export { PageLayout };
