import { DataGridQueryModel } from 'src/core/model/data-grid.model';

export const PAGE_SIZE_OPTIONS: number[] = [10, 25, 50];

export const MAX_PAGE_SIZE = 200;

export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];

export const DEFAULT_DATA_GRID_MODEL: DataGridQueryModel = {
  paginate: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  },
  sort: [],
  textSearch: '',
  filter: {},
};
