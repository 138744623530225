import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserResponseModel } from 'src/core/model/user.model';
import { useGetRole } from 'src/shared/role/use-get-role';
import { useEmailColumn } from 'src/feature/data-grid/column/use-email-column';
import { useUidColumn } from 'src/feature/data-grid/column/use-uid-column';
import { useNameColumn } from 'src/feature/data-grid/column/use-name-column';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useDatetimeColumn } from 'src/feature/data-grid/column/use-datetime-column';
import { useTextColumn } from 'src/feature/data-grid/column/use-text-column';

type UserUserColumnProps = {
  readonly onUpdateUser: (user: UserResponseModel) => void;
};

export function useUserColumn(props: UserUserColumnProps): GridColDef[] {
  const { onUpdateUser } = props;

  const { t } = useTranslation();

  const userTranslations: Record<keyof UserResponseModel, string> = t(
    'model.user',
    {
      returnObjects: true,
    },
  );

  const getRole = useGetRole();

  const emailColumn = useEmailColumn();
  const uidColumn = useUidColumn();
  const nameColumn = useNameColumn();
  const dateColumn = useDatetimeColumn();
  const textColumn = useTextColumn();

  return useMemo(
    () => [
      {
        ...uidColumn,
        field: 'id',
        headerName: userTranslations.id,
      },
      {
        ...textColumn,
        field: 'trigram',
        headerName: userTranslations.trigram,
      },
      {
        ...nameColumn,
        field: 'firstName',
        headerName: userTranslations.firstName,
      },
      {
        ...nameColumn,
        field: 'lastName',
        headerName: userTranslations.lastName,
      },
      {
        ...emailColumn,
        field: 'emailAddress',
        headerName: userTranslations.emailAddress,
      },
      {
        ...textColumn,
        field: 'role',
        headerName: userTranslations.role,
        sortable: false,
        width: 140,
        valueGetter: (
          params: GridValueGetterParams<UserResponseModel>,
        ): string => getRole(params.value),
      },
      {
        ...uidColumn,
        field: 'supervisorId',
        sortable: false,
        headerName: userTranslations.supervisorId,
      },
      {
        ...dateColumn,
        field: 'updatedAt',
        headerName: userTranslations.updatedAt,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params: GridRowParams<UserResponseModel>) => [
          onUpdateUser && (
            <GridActionsCellItem
              label={t('user.data-grid.cta.update-button')}
              icon={<EditOutlinedIcon />}
              onClick={() => onUpdateUser(params.row)}
            />
          ),
        ],
      },
    ],
    [
      t,
      userTranslations,
      emailColumn,
      getRole,
      uidColumn,
      nameColumn,
      dateColumn,
      onUpdateUser,
      textColumn,
    ],
  );
}
