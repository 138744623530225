import { Suspense, useEffect, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { ConfigurationService } from 'src/core/configuration/configuration.service';
import { Logger } from 'src/core/logger/logger';
import { DefaultController } from 'src/router/default.controller';
import { Login } from 'src/feature/login/login';
import { APP_PATH } from 'src/config/app-paths';
import { DashboardLayout } from 'src/shared/layout/dashboard-layout/dashboard.layout';
import { MustResetPassword } from 'src/feature/update-password/must-reset-password';
import { FlatRoutingConfiguration } from 'src/config/routing.config';
import { generateRouterFromConfiguration } from './generate-router-from-configuration';
import { AuthenticatedController } from './authenticated.controller';

export const Router = (): React.JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    if (!ConfigurationService.isProduction()) {
      Logger.debug(location);
    }
  }, [location]);

  const routing = useMemo(
    () => generateRouterFromConfiguration(FlatRoutingConfiguration),
    [],
  );

  return (
    <Suspense>
      <Routes>
        <Route path={APP_PATH.LOGIN} element={<Login />} />

        {/* Authenticated area */}
        <Route element={<AuthenticatedController />}>
          <Route element={<MustResetPassword />}>
            <Route element={<DashboardLayout />}>{routing}</Route>
          </Route>
        </Route>

        {/* Default route  */}
        <Route path="*" element={<DefaultController />} />
      </Routes>
    </Suspense>
  );
};
