import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Copyright } from 'src/shared/copyright/copyright';

type LoginViewProps = Readonly<React.PropsWithChildren<{}>>;

export function LoginLayout(props: LoginViewProps): React.JSX.Element {
  const { children } = props;

  return (
    <Container
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: 6,
      }}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="center"
        gap={8}
        alignItems="center">
        <Stack
          direction="row"
          flex={1}
          justifyContent="center"
          gap={8}
          alignItems="center">
          {children}
        </Stack>
      </Stack>

      <Copyright />
    </Container>
  );
}
