import { TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InvoicedProductModel } from 'src/core/model/order.model';
import { SkeletonTable } from '../skeleton/skeleton-table';

type InvoiceTableBodyProps = {
  articleList?: InvoicedProductModel[];
  isPending?: boolean;
};

export function InvoiceTableBody(
  props: Readonly<InvoiceTableBodyProps>,
): React.JSX.Element {
  const { articleList = [], isPending = false } = props;
  const { t } = useTranslation();

  if (isPending) {
    return (
      <TableBody>
        <SkeletonTable rows={3} cols={5} />
      </TableBody>
    );
  }

  if (articleList.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={5} align="center">
            {t('invoice.card.fallback')}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {articleList.map((row: InvoicedProductModel) => (
        <TableRow key={row.type}>
          <TableCell>{row.description}</TableCell>

          <TableCell align="right">
            {t('invoice.card.quantity', {
              value: row.quantity,
              unity: row.unit,
            })}
          </TableCell>

          <TableCell align="right">
            {t('invoice.card.quantity', {
              value: row.invoicedQuantity,
              unity: row.unit,
            })}
          </TableCell>

          <TableCell align="right">
            {t('invoice.card.price', {
              value: row.pricePerQuantity,
            })}
          </TableCell>

          <TableCell align="right">
            {t('invoice.card.price', {
              value: row.invoicedPrice,
            })}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
