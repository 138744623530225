import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChargingSessionResponseModel } from 'src/core/model/charging-session.model';
import { useDatetimeColumn } from 'src/feature/data-grid/column/use-datetime-column';
import { usePseudoColumn } from 'src/feature/data-grid/column/use-pseudo-column';
import { useEnergyDeliveredColumn } from 'src/feature/data-grid/column/use-energy-delivered-column';
import { useUidColumn } from 'src/feature/data-grid/column/use-uid-column';
import { ChargingSessionStatusEnum } from 'src/core/model/enum/charging-session-status.enum';
import { APP_PATH } from 'src/config/app-paths';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTextColumn } from 'src/feature/data-grid/column/use-text-column';
import { useCurrencyColumn } from 'src/feature/data-grid/column/use-currency-column';
import { useGetChargingSessionStatusText } from 'src/shared/charging-status-card/use-get-charging-session-status-text';

export function useChargingSessionColumn(): GridColDef[] {
  const { t } = useTranslation();

  const chargingSessionTranslations: Record<
    keyof ChargingSessionResponseModel,
    string
  > = t('model.charging-session', {
    returnObjects: true,
  });

  const textColumn = useTextColumn();
  const uidColumn = useUidColumn();
  const datetimeColumn = useDatetimeColumn();
  const pseudoColumn = usePseudoColumn();
  const energyDeliveredColumn = useEnergyDeliveredColumn();
  const currencyColumn = useCurrencyColumn();
  const getSessionStatus = useGetChargingSessionStatusText();

  return useMemo(
    () => [
      {
        ...textColumn,
        field: 'locationName',
        width: 160,
        headerName: chargingSessionTranslations.locationName,
      },
      {
        ...textColumn,
        field: 'locationCity',
        width: 160,
        headerName: chargingSessionTranslations.locationCity,
      },
      {
        ...datetimeColumn,
        field: 'startedAt',
        headerName: chargingSessionTranslations.startedAt,
      },
      {
        ...datetimeColumn,
        field: 'stoppedAt',
        headerName: chargingSessionTranslations.stoppedAt,
      },
      {
        ...energyDeliveredColumn,
        field: 'energyDelivered',
        headerName: chargingSessionTranslations.energyDelivered,
      },
      {
        ...currencyColumn,
        field: 'totalTTC',
        headerName: chargingSessionTranslations.totalTTC,
      },
      {
        ...pseudoColumn,
        field: 'pseudo',
        headerName: chargingSessionTranslations.pseudo,
      },
      {
        ...uidColumn,
        field: 'orderId',
        headerName: chargingSessionTranslations.orderId,
      },
      {
        ...textColumn,
        field: 'status',
        width: 160,
        sortable: false,
        headerName: chargingSessionTranslations.status,
        valueGetter: (
          params: GridValueGetterParams<
            ChargingSessionResponseModel,
            ChargingSessionResponseModel['status']
          >,
        ): ChargingSessionStatusEnum | null => {
          return (
            [...(params.value ?? [])]
              .sort(
                (a, b) =>
                  new Date(a.updatedAt).getTime() -
                  new Date(b.updatedAt).getTime(),
              )
              .pop()?.type ?? null
          );
        },
        valueFormatter: (
          params: GridValueFormatterParams<ChargingSessionStatusEnum | null>,
        ) => (params.value !== null ? getSessionStatus(params.value) : null),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,

        getActions: (params: GridRowParams<ChargingSessionResponseModel>) => [
          <GridActionsCellItem
            key="link-to-detail"
            component={RouterLink}
            target="_blank"
            label={t('charging-session.data-grid.cta.view-detail')}
            icon={<OpenInNewIcon />}
            to={generatePath(APP_PATH.CHARGING_SESSION_DETAIL, {
              chargingSessionId: params.row.id,
            })}
          />,
        ],
      },
    ],
    [
      chargingSessionTranslations,
      uidColumn,
      datetimeColumn,
      pseudoColumn,
      energyDeliveredColumn,
      getSessionStatus,
      t,
      textColumn,
      currencyColumn,
    ],
  );
}
