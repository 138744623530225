import { PropsWithChildren, useState } from 'react';
import { Collapse, Stack, TableCell, TableRow } from '@mui/material';
import {
  QuarterOverviewTableRow,
  QuarterOverviewTableRowProps,
} from './quarter-overview.table-row';

export function CollapsibleQuarterOverviewTableRow(
  props: PropsWithChildren<QuarterOverviewTableRowProps>,
): React.JSX.Element {
  const { children, ...quarterOverviewTableRowProps } = props;

  const [isRowExpand, setIsRowExpand] = useState(false);

  return (
    <>
      <QuarterOverviewTableRow
        isOpen={isRowExpand}
        setIsOpen={setIsRowExpand}
        sx={{ '& > *': { borderBottom: 'unset' } }}
        {...quarterOverviewTableRowProps}
      />

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
          <Collapse in={isRowExpand} timeout="auto" unmountOnExit>
            <Stack margin={1} direction="column" gap={2}>
              {children}
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
