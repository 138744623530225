import {
  FilterDefinitionModel,
  FilterFormItemProps,
  FilterValueModel,
} from 'src/core/model/data-grid.model';
import { DatePicker, DateView } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { BaseNonStaticPickerProps } from '@mui/x-date-pickers/internals';

import { FilterItem } from './filter-item';
import { useSimpleChip } from './use-simple-chip';

type UseDateFilterProps = {
  field: string;
  label: string;
  valueFormatter?: (value: FilterValueModel) => string;
  datePickerFormat?: BaseNonStaticPickerProps['format'];
  datePickerOnChangeFormatTemplate?: string;
  datePickerViews?: DateView[];
};

export function useDateFilter(
  hookProps: UseDateFilterProps,
): FilterDefinitionModel {
  const { t } = useTranslation();

  const {
    label,
    field,
    datePickerFormat = 'DD/MM/YYYY',
    datePickerViews,
    datePickerOnChangeFormatTemplate = datePickerFormat,
    valueFormatter = (value) =>
      t('data-grid.row.date', { value: new Date(value.toString()) }),
  } = hookProps;

  const getChip = useSimpleChip({ field, label, valueFormatter });

  return {
    getFilter: (props: FilterFormItemProps) => {
      const { values, setValues } = props;

      return (
        <FilterItem key={field} label={label}>
          <DatePicker
            label={label}
            format={datePickerFormat}
            views={datePickerViews}
            onChange={(value) =>
              setValues((prev) => ({
                ...prev,
                ...(value
                  ? { [field]: value.format(datePickerOnChangeFormatTemplate) }
                  : null),
              }))
            }
            value={values[field] ? dayjs(values[field].toString()) : null}
          />
        </FilterItem>
      );
    },

    getChip,
  };
}
