import { Link } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {
  TextOverflow,
  TextOverflowProps,
} from 'src/shared/typography/text-overflow';

type EmailTextProps = TextOverflowProps & {
  readonly to: string;
};

export function EmailText(props: EmailTextProps): React.JSX.Element {
  const { to, maxWidth = 300 } = props;

  const maxWidthProps: number | 'unset' =
    maxWidth === 'unset' ? 'unset' : maxWidth - 48;

  return (
    <div>
      <TextOverflow maxWidth={maxWidthProps}>{to}</TextOverflow>
      <Link href={`mailto:${to}`}>
        <SendIcon sx={{ ml: 1, verticalAlign: 'top' }} />
      </Link>
    </div>
  );
}
