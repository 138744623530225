import { ChargingSessionStatusModel } from 'src/core/model/charging-session.model';
import { ChargingSessionStatusEnum } from 'src/core/model/enum/charging-session-status.enum';
import { InvoiceStatusEnum } from 'src/core/model/enum/invoice-status.enum';
import { OrderStatusModel } from 'src/core/model/order.model';

export type MergedStatusModel = {
  updatedAt: Date;
  ocpiSessionId?: string;
} & (
  | {
      type: 'charging-session';
      status: ChargingSessionStatusEnum;
    }
  | {
      type: 'invoice';
      status: InvoiceStatusEnum;
    }
);

export function mergeChargingSessionAndInvoiceStatus(
  chargingSessionStatusList: ChargingSessionStatusModel[],
  orderStatusList: OrderStatusModel[],
): MergedStatusModel[] {
  return [
    ...chargingSessionStatusList.map(
      (item): MergedStatusModel => ({
        updatedAt: new Date(item.updatedAt),
        type: 'charging-session',
        status: item.type,
        ocpiSessionId: item.ocpiSessionId,
      }),
    ),
    ...orderStatusList.map(
      (item): MergedStatusModel => ({
        updatedAt: new Date(item.createdAt),
        type: 'invoice',
        status: item.status,
      }),
    ),
  ];
}
