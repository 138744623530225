import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceResponseModel } from 'src/core/model/invoice.model';
import { InvoiceStatusEnum } from 'src/core/model/enum/invoice-status.enum';
import { useUidColumn } from 'src/feature/data-grid/column/use-uid-column';
import { useCurrencyColumn } from 'src/feature/data-grid/column/use-currency-column';
import { useDatetimeColumn } from 'src/feature/data-grid/column/use-datetime-column';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { APP_PATH } from 'src/config/app-paths';
import { useTextColumn } from 'src/feature/data-grid/column/use-text-column';
import { useEnergyDeliveredColumn } from 'src/feature/data-grid/column/use-energy-delivered-column';
import { useCopyColumn } from 'src/feature/data-grid/column/use-copy-column';
import { usePseudoColumn } from 'src/feature/data-grid/column/use-pseudo-column';

export function useInvoiceColumn(): GridColDef[] {
  const { t } = useTranslation();

  const uidColumn = useUidColumn();
  const currencyColumn = useCurrencyColumn();
  const datetimeColumn = useDatetimeColumn();
  const textColumn = useTextColumn();
  const copyColumn = useCopyColumn();
  const energyDeliveredColumn = useEnergyDeliveredColumn();
  const pseudoColumn = usePseudoColumn();

  const invoiceTranslations: Record<keyof InvoiceResponseModel, string> = t(
    'model.invoice',
    {
      returnObjects: true,
    },
  );

  return useMemo(
    () => [
      {
        ...copyColumn,
        field: 'invoiceId',
        headerName: invoiceTranslations.invoiceId,
        width: 180,
      },
      {
        ...uidColumn,
        field: 'orderId',
        headerName: invoiceTranslations.orderId,
      },
      {
        ...copyColumn,
        field: 'quoteId',
        headerName: invoiceTranslations.quoteId,
        width: 140,
      },
      {
        ...pseudoColumn,
        field: 'customerPseudo',
        headerName: invoiceTranslations.customerPseudo,
      },
      {
        ...textColumn,
        field: 'status',
        headerName: invoiceTranslations.status,
        width: 200,

        valueFormatter: (
          params: GridValueFormatterParams<InvoiceStatusEnum>,
        ): string =>
          t(`model.enum.invoice-status.${params.value}`, params.value),
      },
      {
        ...textColumn,
        field: 'chargePointName',
        headerName: invoiceTranslations.chargePointName,
        width: 200,
      },
      {
        ...datetimeColumn,
        field: 'createdAt',
        headerName: invoiceTranslations.createdAt,
      },
      {
        ...energyDeliveredColumn,
        field: 'energyDelivered',
        headerName: invoiceTranslations.energyDelivered,
      },
      {
        ...currencyColumn,
        field: 'totalTTC',
        headerName: invoiceTranslations.totalTTC,
      },
      {
        ...textColumn,
        width: 200,
        sortable: false,
        field: 'remarks',
        headerName: invoiceTranslations.remarks,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,

        getActions: (params: GridRowParams<InvoiceResponseModel>) => [
          params.row.invoiceId && (
            <GridActionsCellItem
              key="link-to-html"
              component={RouterLink}
              target="_blank"
              label={t('invoice.data-grid.cta.preview-pdf')}
              icon={<OpenInNewIcon />}
              to={generatePath(APP_PATH.INVOICE_HTML, {
                invoiceId: params.row.invoiceId,
              })}
            />
          ),
        ],
      },
    ],
    [
      t,
      invoiceTranslations,
      uidColumn,
      currencyColumn,
      datetimeColumn,
      textColumn,
      energyDeliveredColumn,
      copyColumn,
      pseudoColumn,
    ],
  );
}
