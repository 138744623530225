import { useGetListChargePointQuery } from 'src/core/service/charge-point.service';
import { DataGrid } from 'src/feature/data-grid/data-grid';
import { useDataGridSearchParams } from 'src/feature/data-grid/use-data-grid-search-params/use-data-grid-search-params';
import { ChargePointResponseModel } from 'src/core/model/charge-point.model';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useTranslation } from 'react-i18next';
import { PreCaptureAmountModel } from 'src/core/model/pre-capture-amount.model';
import { useChargePointColumn } from './use-charge-point-column';
import { withPreCaptureAmount } from '../pre-capture-amount/with-pre-capture-amount';

type ChargePointDataGridProps = {
  readonly onGenerateQRCode: (chargePoint: ChargePointResponseModel) => void;
  readonly preCaptureAmountList: PreCaptureAmountModel[];
};

function ChargePointDataGridWithoutPreCaptureAmountList(
  props: ChargePointDataGridProps,
): React.JSX.Element {
  const { onGenerateQRCode, preCaptureAmountList } = props;

  const { t } = useTranslation();
  const columns = useChargePointColumn({
    onGenerateQRCode,
    preCaptureAmountList,
  });
  const { model, onModelChange } = useDataGridSearchParams();

  const {
    data: paginatedData,
    isFetching,
    isError,
  } = useGetListChargePointQuery(model);

  return (
    <>
      <DataGrid
        getRowId={(row) => {
          return row.chargePointId;
        }}
        columns={columns}
        loading={isFetching}
        rows={paginatedData?.docs ?? []}
        rowCount={paginatedData?.totalDocs ?? 0}
        model={model}
        onModelChange={onModelChange}
      />
      {isError && (
        <Snackbar
          variant="error"
          message={t('error.get-list-charge-point-query')}
        />
      )}
    </>
  );
}

export const ChargePointDataGrid = withPreCaptureAmount(
  ChargePointDataGridWithoutPreCaptureAmountList,
);
