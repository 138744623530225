import { usePatchUserMutation } from 'src/core/service/user.service';
import { UserFormModel, UserResponseModel } from 'src/core/model/user.model';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DialogTitle } from 'src/shared/typography/title';
import { useTranslation } from 'react-i18next';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useApiError } from 'src/core/helper/use-api-error';
import { useEffect } from 'react';
import { UserFormView } from '../user-form/user-form.view';

export type UpdateGenericUserProps = {
  readonly user: UserResponseModel;
  readonly onCancel: () => void;
  readonly onSuccess: () => void;
};

type UserUpdateProps = UpdateGenericUserProps & {
  readonly title: string;
  readonly endpoint: string;
};

export function UserUpdate(props: UserUpdateProps): React.JSX.Element {
  const { title, endpoint, user, onCancel, onSuccess } = props;
  const { t } = useTranslation();

  const [patchUser, { isSuccess, isLoading, isError, error }] =
    usePatchUserMutation();
  const { status, message, code } = useApiError(error);

  const handleSubmit = (payload: UserFormModel): void => {
    patchUser({
      endpoint,
      id: user.id,
      ...payload,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  });

  return (
    <>
      <DialogTitle icon={<EditOutlinedIcon />}>{title}</DialogTitle>

      <UserFormView
        defaultValues={user}
        errorCode={code}
        formType="update"
        onSubmit={handleSubmit}
        pending={isLoading}
        onCancel={onCancel}
      />

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.patch-user-mutation', { status, message })}
        />
      )}
    </>
  );
}
