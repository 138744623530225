import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrderResponseModel } from 'src/core/model/order.model';

export type OrderSectionProps = {
  readonly selectedId?: string;
  readonly list: OrderResponseModel[];
  readonly onSelect: (order: OrderResponseModel) => void;
};

export function OrderSection(props: OrderSectionProps): React.JSX.Element {
  const { selectedId, list, onSelect } = props;
  const { t } = useTranslation();

  const orderTranslations: Record<keyof OrderResponseModel, string> = t(
    'model.order',
    {
      returnObjects: true,
    },
  );

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        {t('quarter-overview.order-section.title', {
          count: list.length,
        })}
      </Typography>

      {list.length > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{orderTranslations.chargePointName}</TableCell>
              <TableCell>{orderTranslations.sessionStart}</TableCell>
              <TableCell>{orderTranslations.sessionStop}</TableCell>
              <TableCell align="right">
                {orderTranslations.energyDelivered}
              </TableCell>
              <TableCell align="right">{orderTranslations.totalHT}</TableCell>
              <TableCell>{orderTranslations.iTransactionId}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list.map((order) => (
              <TableRow key={order.id} selected={selectedId === order.id}>
                <TableCell>
                  <Radio
                    checked={selectedId === order.id}
                    onChange={() => onSelect(order)}
                  />
                </TableCell>

                <TableCell>{order.chargePointName}</TableCell>

                <TableCell>
                  {t('data-grid.row.date', { value: order.sessionStart })}
                </TableCell>

                <TableCell>
                  {t('data-grid.row.date', { value: order.sessionStop })}
                </TableCell>

                <TableCell align="right">
                  {t('data-grid.row.energy-delivered', {
                    value: order.energyDelivered,
                  })}
                </TableCell>

                <TableCell align="right">
                  {t('data-grid.row.currency', {
                    value: order.totalHT,
                  })}
                </TableCell>

                <TableCell>{order.iTransactionId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
