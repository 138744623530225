import { useTranslation } from 'react-i18next';
import { FilterDefinitionModel } from 'src/core/model/data-grid.model';
import { OrderResponseModel } from 'src/core/model/order.model';
import { useDateRangeFilter } from 'src/feature/data-grid/filter/generic-filter/use-date-range-filter';
import { useNumberRangeFilter } from 'src/feature/data-grid/filter/generic-filter/use-number-range-filter';

export function useOrderFilter(): FilterDefinitionModel[] {
  const { t } = useTranslation();

  const orderTranslations: Record<keyof OrderResponseModel, string> = t(
    'model.invoice',
    {
      returnObjects: true,
    },
  );

  const amountFilter = useNumberRangeFilter({
    label: orderTranslations.totalTTC,
    keyMin: 'totalTTCMin',
    keyMax: 'totalTTCMax',
    valueFormatter: (value) => t('data-grid.row.currency', { value }),
  });

  const updatedAtFilter = useDateRangeFilter({
    label: orderTranslations.updatedAt,
    keyMin: 'updatedAfter',
    keyMax: 'updatedBefore',
  });

  return [amountFilter, updatedAtFilter];
}
