type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export const removeEmptyValues = (
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  return Object.fromEntries(
    Object.entries(allValues).filter((entry) => {
      const value = entry[1];

      if (value === null) {
        return false;
      }

      if (typeof value === 'object' || Array.isArray(value)) {
        return removeEmptyValues(value as UnknownArrayOrObject);
      }

      if (value === '') {
        return false;
      }

      return true;
    }),
  );
};
