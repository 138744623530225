import Stack from '@mui/material/Stack';

import { UserPresentation } from './component/user-presentation/user-presentation';
import { UserSupervisor } from './component/user-presentation/user-supervisor/user-supervisor';

export function DashboardView(): React.JSX.Element {
  return (
    <Stack direction={{ md: 'column', lg: 'row' }} gap={4}>
      <UserPresentation sx={{ flex: { md: 'unset', lg: 2 } }} />

      <UserSupervisor sx={{ flex: { md: 'unset', lg: 1 } }} />
    </Stack>
  );
}
