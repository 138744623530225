import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CUSTOMER_DETAIL_ENDPOINT,
  CUSTOMER_ENDPOINT,
  CUSTOMER_LOCK_ENDPOINT,
  CUSTOMER_LOCK_STATUS_ENDPOINT,
  CUSTOMER_SYNC_SUBSCRIPTION,
  CUSTOMER_UNLOCK_ENDPOINT,
} from 'src/config/api-routes';
import { generatePath } from 'react-router-dom';

import {
  DataGridQueryModel,
  PaginationResponseModel,
} from '../model/data-grid.model';
import {
  CustomerLockFormModel,
  CustomerLockStatusModel,
  CustomerResponseModel,
} from '../model/customer.model';
import { baseQueryBuilder } from './base-query.builder';
import { mapDataGridQueryModelToApiQuery } from '../helper/map-data-grid-query-model-to-api-query';

export type CustomerLockBody = CustomerLockFormModel & {
  customerId: string;
};

export const customerApi = createApi({
  reducerPath: 'customerApi',
  tagTypes: ['CUSTOMER-LOCK', 'CUSTOMER-DETAILS'],

  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getListCustomer: builder.query<
      PaginationResponseModel<CustomerResponseModel>,
      DataGridQueryModel
    >({
      query: (params: DataGridQueryModel) =>
        `${CUSTOMER_ENDPOINT}?${mapDataGridQueryModelToApiQuery(
          params,
        ).toString()}`,
    }),

    getOneCustomer: builder.query<CustomerResponseModel, string | null>({
      query: (customerId: string | null) =>
        generatePath(CUSTOMER_DETAIL_ENDPOINT, { customerId }),
      providesTags: (_, __, customerId: string | null) =>
        customerId ? [{ type: 'CUSTOMER-DETAILS', id: customerId }] : [],
    }),

    getLockStatus: builder.query<CustomerLockStatusModel, string | null>({
      query: (customerId: string | null) =>
        generatePath(CUSTOMER_LOCK_STATUS_ENDPOINT, { customerId }),
      providesTags: (_, __, params: string | null) =>
        params
          ? [{ type: 'CUSTOMER-LOCK', id: params }]
          : [{ type: 'CUSTOMER-LOCK', id: 'LIST' }],
    }),

    lockCustomer: builder.mutation<CustomerLockStatusModel, CustomerLockBody>({
      query: ({ customerId, ...body }: CustomerLockBody) => ({
        url: generatePath(CUSTOMER_LOCK_ENDPOINT, { customerId }),
        method: 'POST',
        body,
      }),

      // update lock-status cache with query response
      async onQueryStarted(
        { customerId }: CustomerLockBody,
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            customerApi.util.upsertQueryData('getLockStatus', customerId, data),
          );
        } catch {
          // Nothing to do.
        }
      },
    }),

    unlockCustomer: builder.mutation<CustomerLockStatusModel, string>({
      query: (customerId: string) => ({
        url: generatePath(CUSTOMER_UNLOCK_ENDPOINT, { customerId }),
        method: 'POST',
      }),

      // update lock-status cache with query response
      async onQueryStarted(customerId: string, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            customerApi.util.upsertQueryData('getLockStatus', customerId, data),
          );
        } catch {
          // Nothing to do.
        }
      },
    }),

    syncSubscription: builder.mutation<string[], string>({
      query: (customerId: string) => ({
        url: generatePath(CUSTOMER_SYNC_SUBSCRIPTION, { customerId }),
        method: 'POST',
      }),

      async onQueryStarted(customerId: string, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            customerApi.util.updateQueryData(
              'getOneCustomer',
              customerId,
              (customer) => ({
                ...customer,
                emspSubscriptionIds: data,
              }),
            ),
          );
        } catch {
          // Nothing to do.
        }
      },
    }),
  }),
});

export const {
  useGetListCustomerQuery,
  useGetOneCustomerQuery,
  useGetLockStatusQuery,
  useLockCustomerMutation,
  useUnlockCustomerMutation,
  useSyncSubscriptionMutation,
} = customerApi;
