import { InputAdornment, styled } from '@mui/material';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

const Input = styled(MuiTextField)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

type TextFieldProps = UseFormRegisterReturn &
  Pick<
    MuiTextFieldProps,
    'label' | 'autoComplete' | 'autoFocus' | 'type' | 'id' | 'margin' | 'size'
  > & {
    readonly readOnly?: boolean;
    readonly errorMessage?: string | null;
    readonly isTouched?: boolean;
    readonly startAdornment?: ReactNode;
    readonly endAdornment?: ReactNode;
  };

export function TextFieldWithoutRef(
  props: TextFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
): React.JSX.Element {
  const {
    errorMessage,
    margin = 'dense',
    isTouched = false,
    readOnly = false,
    startAdornment,
    endAdornment,
    ...textFieldProps
  } = props;

  return (
    <Input
      {...textFieldProps}
      ref={ref}
      InputProps={{
        readOnly,

        ...(startAdornment
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
            }
          : null),

        ...(endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }
          : null),
      }}
      margin={margin}
      fullWidth
      error={isTouched && !!errorMessage}
      helperText={isTouched && errorMessage}
    />
  );
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  TextFieldWithoutRef,
);
