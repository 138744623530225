import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CURRENT_USER_ENDPOINT,
  CURRENT_USER_SUPERVISOR_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
} from 'src/config/api-routes';
import {
  PatchUserModel,
  UpdatePasswordModel,
  UserResponseModel,
  UserSupervisorSupervisorModel,
} from 'src/core/model/user.model';
import { baseQueryBuilder } from './base-query.builder';

export const currentUserApi = createApi({
  reducerPath: 'currentUserApi',
  baseQuery: baseQueryBuilder(),
  tagTypes: ['CURRENT_USER', 'CURRENT_USER_SUPERVISOR'],

  endpoints: (builder) => ({
    getCurrentUser: builder.query<UserResponseModel, void>({
      query: () => CURRENT_USER_ENDPOINT,
      providesTags: ['CURRENT_USER'],
    }),

    getCurrentUserSupervisor: builder.query<
      UserSupervisorSupervisorModel,
      void
    >({
      query: () => CURRENT_USER_SUPERVISOR_ENDPOINT,
      providesTags: ['CURRENT_USER_SUPERVISOR'],
    }),

    updatePassword: builder.mutation<UserResponseModel, UpdatePasswordModel>({
      query: (body: UpdatePasswordModel) => ({
        url: UPDATE_PASSWORD_ENDPOINT,
        method: 'PUT',
        body,
      }),

      // update current user cache with query response
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUser } = await queryFulfilled;
          dispatch(
            currentUserApi.util.upsertQueryData(
              'getCurrentUser',
              undefined,
              updatedUser,
            ),
          );
        } catch {
          // Nothing to do.
        }
      },
    }),

    patchCurrentUser: builder.mutation<UserResponseModel, PatchUserModel>({
      query: (body: PatchUserModel) => ({
        url: CURRENT_USER_ENDPOINT,
        method: 'PATCH',
        body,
      }),

      // update current user cache with query response
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUser } = await queryFulfilled;
          dispatch(
            currentUserApi.util.upsertQueryData(
              'getCurrentUser',
              undefined,
              updatedUser,
            ),
          );
        } catch {
          // Nothing to do.
        }
      },
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useUpdatePasswordMutation,
  useGetCurrentUserSupervisorQuery,
  usePatchCurrentUserMutation,
} = currentUserApi;
