import { useTranslation } from 'react-i18next';
import { FilterDefinitionModel } from 'src/core/model/data-grid.model';
import { LogEventNameEnum } from 'src/core/model/enum/log-event-name.enum';
import { LogFilterModel } from 'src/core/model/log.model';
import { useCheckboxFilter } from 'src/feature/data-grid/filter/generic-filter/use-checkbox-filter';
import { useTextFilter } from 'src/feature/data-grid/filter/generic-filter/use-text-filter';

export function useOcpiLogFilter(): FilterDefinitionModel[] {
  const { t } = useTranslation();
  const logTranslations: Record<keyof LogFilterModel, string> = t(
    'model.filter-log',
    {
      returnObjects: true,
    },
  );

  const sessionIdFilter = useTextFilter('sessionId', logTranslations.sessionId);
  const cdrIdFilter = useTextFilter('cdrId', logTranslations.cdrId);
  const locationIdcdrId = useTextFilter(
    'locationId',
    logTranslations.locationId,
  );
  const evseIdFilter = useTextFilter('evseId', logTranslations.evseId);
  const evseUidFilter = useTextFilter('evseUid', logTranslations.evseUid);
  const connectorIdFilter = useTextFilter(
    'connectorId',
    logTranslations.connectorId,
  );
  const commandIdFilter = useTextFilter('commandId', logTranslations.commandId);
  const eventNameFilter = useCheckboxFilter({
    field: 'eventName',
    label: logTranslations.eventName,
    options: Object.values(LogEventNameEnum),
  });

  return [
    sessionIdFilter,
    cdrIdFilter,
    locationIdcdrId,
    evseIdFilter,
    evseUidFilter,
    connectorIdFilter,
    commandIdFilter,
    eventNameFilter,
  ];
}
