import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { PropsWithChildren } from 'react';

type FilterItemProps = {
  label: string;
};

export function FilterItem(
  props: Readonly<PropsWithChildren<FilterItemProps>>,
): React.JSX.Element {
  const { label, children } = props;

  return (
    <Stack rowGap={4}>
      <Stack rowGap={2}>
        <Typography sx={{ fontWeight: '700' }}>{label}</Typography>
        {children}
      </Stack>

      <Divider />
    </Stack>
  );
}
