import { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Logger } from 'src/core/logger/logger';
import { SupportedLocaleEnum } from 'src/core/model/enum/supported-locale.enum';
import { InAppStorage } from 'src/core/storage/in-app-storage';
import { usePatchCurrentUserMutation } from 'src/core/service/current-user.service';

export type SelectableLocaleType = SupportedLocaleEnum | 'browser';

type SelectLanguageHookReturn = {
  locale: SelectableLocaleType;
  onChange: (event: SelectChangeEvent<SelectableLocaleType>) => void;
  isError: boolean;
};

export function useSelectLanguage(): SelectLanguageHookReturn {
  const { i18n } = useTranslation();
  const [isError, setIsError] = useState(false);

  const [patchUser] = usePatchCurrentUserMutation();

  const currentLanguage = i18n.language as SupportedLocaleEnum;
  const [selectedLocale, setSelectedLocale] = useState<SelectableLocaleType>(
    InAppStorage.locale ? currentLanguage : 'browser',
  );

  const handleSetLocale = (newLocale: SelectableLocaleType): void => {
    setSelectedLocale(newLocale);
    InAppStorage.locale = newLocale === 'browser' ? null : newLocale;
  };

  const handleChange = (event: SelectChangeEvent<SelectableLocaleType>) => {
    const oldLocale = selectedLocale;
    const newLocale = event.target.value as SelectableLocaleType;
    handleSetLocale(newLocale);

    i18n
      .changeLanguage()
      .then(() => {
        patchUser({ language: i18n.resolvedLanguage as SupportedLocaleEnum });
      })
      .catch((error) => {
        Logger.error(error);
        setIsError(true);
        handleSetLocale(oldLocale);
      });
  };

  return {
    locale: selectedLocale,
    onChange: handleChange,
    isError,
  };
}
