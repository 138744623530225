import { Fragment, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useDownloadInvoicePdfMutation,
  useGetInvoiceHtmlQuery,
} from 'src/core/service/invoice.service';
import { useSetPageActionContext } from 'src/shared/layout/page-layout/page-layout.context-provider';
import DownloadIcon from '@mui/icons-material/Download';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useApiError } from 'src/core/helper/use-api-error';
import { Button } from 'src/shared/button/button';
import { InvoicePlaceholder } from './invoice-placeholder';

export function InvoicePreview(): React.JSX.Element {
  const { invoiceId } = useParams();
  const { t } = useTranslation();
  const [
    downloadInvoice,
    { isError: isDownloadError, error, isLoading: isDownloadInvoiceLoading },
  ] = useDownloadInvoicePdfMutation();
  const { status, message } = useApiError(error);

  const setPageAction = useSetPageActionContext();

  useLayoutEffect(() => {
    if (!invoiceId) {
      return;
    }

    const downloadInvoiceButton = (
      <Fragment key="download-invoice">
        <Button
          loading={isDownloadInvoiceLoading}
          onClick={() => downloadInvoice(invoiceId)}
          variant="contained">
          <DownloadIcon />
          {t('invoice.preview.download-button')}
        </Button>

        {isDownloadError && (
          <Snackbar
            variant="error"
            message={t('error.download-invoice-pdf-mutation', {
              status,
              message,
            })}
          />
        )}
      </Fragment>
    );

    setPageAction([downloadInvoiceButton]);
  }, [
    t,
    invoiceId,
    downloadInvoice,
    setPageAction,
    isDownloadError,
    status,
    message,
    isDownloadInvoiceLoading,
  ]);

  const { data: invoiceHtml, isError: isGetInvoiceHtmlError } =
    useGetInvoiceHtmlQuery(invoiceId ?? null, {
      skip: !invoiceId,
    });

  if (!invoiceHtml) {
    return (
      <>
        <InvoicePlaceholder isError={isGetInvoiceHtmlError} />
        {isGetInvoiceHtmlError && (
          <Snackbar
            variant="error"
            message={t('error.get-invoice-html-query')}
          />
        )}
      </>
    );
  }

  return (
    <iframe
      srcDoc={invoiceHtml}
      title={t('invoice.preview.title')}
      style={{
        border: 'none',
        width: '100%',
        height: 'calc(100% - 72px)',
      }}
    />
  );
}
