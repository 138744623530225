import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Stack, StackProps } from '@mui/material';
import { Button } from 'src/shared/button/button';
import { useForm } from 'react-hook-form';

type SearchFormModel = {
  search?: string;
};

type SearchProps = {
  readonly initialValue?: string;
  readonly onSearch: (searchText?: string) => void;
  readonly sx?: StackProps['sx'];
};

export function Search(props: SearchProps): React.JSX.Element {
  const { initialValue, onSearch, sx } = props;
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<SearchFormModel>({
    mode: 'onChange',
    defaultValues: { search: initialValue },
  });

  return (
    <Stack
      component="form"
      gap={2}
      direction="row"
      alignItems="center"
      noValidate
      sx={sx}
      onSubmit={handleSubmit((form) => onSearch(form.search))}>
      <TextField
        {...register('search')}
        fullWidth
        label={t('data-grid.search.label')}
      />
      <Button variant="contained" type="submit">
        {t('data-grid.search.submit-button')}
      </Button>
    </Stack>
  );
}
