import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import { DRAWER_MENU_WIDTH } from 'src/config/app.config';
import { DrawerMenu } from 'src/shared/layout/dashboard-layout/drawer-menu/drawer-menu';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_MENU_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: DRAWER_MENU_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface DrawerMenuContainerProps {
  isMobile: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export function DrawerMenuContainer(
  props: DrawerMenuContainerProps,
): React.JSX.Element {
  const { isMobile, isOpen, onClose } = props;

  return isMobile ? (
    <Drawer
      variant="temporary"
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}>
      <DrawerMenu onClose={onClose} />
    </Drawer>
  ) : (
    <Drawer variant="permanent" open={isOpen}>
      <DrawerMenu onClose={onClose} />
    </Drawer>
  );
}
