import { useTranslation } from 'react-i18next';
import { useApiError } from 'src/core/helper/use-api-error';
import { useSyncSubscriptionMutation } from 'src/core/service/customer.service';
import { Button } from 'src/shared/button/button';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import SyncIcon from '@mui/icons-material/Sync';
import { Tooltip } from '@mui/material';

type Props = {
  readonly customerId?: string;
  readonly isEmailAddressVerified?: boolean;
};

export function CustomerSyncSubscriptionButton(
  props: Props,
): React.JSX.Element {
  const { customerId, isEmailAddressVerified = false } = props;

  const { t } = useTranslation();

  const [sync, { isLoading, isError, error }] = useSyncSubscriptionMutation();
  const { status, message } = useApiError(error);

  return (
    <>
      {customerId && (
        <Tooltip
          title={
            !isEmailAddressVerified
              ? t('customer.sync-subscription.button-disabled-tooltip')
              : ''
          }>
          <span>
            <Button
              disabled={!isEmailAddressVerified}
              startIcon={<SyncIcon />}
              loading={isLoading}
              onClick={() => sync(customerId)}>
              {t('customer.sync-subscription.button')}
            </Button>
          </span>
        </Tooltip>
      )}

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.sync-subscription-mutation', { status, message })}
        />
      )}
    </>
  );
}
