import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';

type ActionContextModel = ReactNode[];
type SetActionContextModel = (payload: ActionContextModel) => void;

const PageActionContext = createContext<ActionContextModel>([]);

const SetPageActionContext = createContext<SetActionContextModel>(() => {});

export function PageActionContextProvider(
  props: Readonly<PropsWithChildren>,
): React.JSX.Element {
  const { children } = props;

  const [actions, setActions] = useState<ActionContextModel>([]);

  return (
    <PageActionContext.Provider value={actions}>
      <SetPageActionContext.Provider value={setActions}>
        {children}
      </SetPageActionContext.Provider>
    </PageActionContext.Provider>
  );
}

export function usePageActionContext(): ActionContextModel {
  return useContext(PageActionContext);
}

export function useSetPageActionContext(): SetActionContextModel {
  return useContext(SetPageActionContext);
}
