import { frFR as frFRCore, enUS as enUSCore } from '@mui/material/locale';
import { frFR as frFRDataGrid, enUS as enUSDataGrid } from '@mui/x-data-grid';
import {
  frFR as frFRDatePicker,
  enUS as enUSDatePicker,
} from '@mui/x-date-pickers/locales';

import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedLocaleEnum } from 'src/core/model/enum/supported-locale.enum';
import { AppStyle } from './app-style';

const localeKeyToMaterialLocale: Record<SupportedLocaleEnum, object[]> = {
  [SupportedLocaleEnum.en]: [enUSCore, enUSDataGrid, enUSDatePicker],
  [SupportedLocaleEnum.fr]: [frFRCore, frFRDataGrid, frFRDatePicker],
};

export function ThemeProvider(
  props: Readonly<PropsWithChildren<{}>>,
): React.JSX.Element {
  const { children } = props;
  const { i18n } = useTranslation();

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#2196F3',
          light: '#2196F314',
          dark: '#1769aa',
        },
      },
      components: {
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: '16px',
            },
          },
        },
      },
    },
    ...(localeKeyToMaterialLocale[
      i18n.resolvedLanguage as SupportedLocaleEnum
    ] ?? localeKeyToMaterialLocale[SupportedLocaleEnum.fr]),
  );

  return (
    <MUIThemeProvider theme={theme}>
      <AppStyle />
      {children}
    </MUIThemeProvider>
  );
}
