import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { TariffRestrictionModel } from 'src/core/model/tariff.model';

export function useTariffRestrictionFormValidation(): Record<
  keyof TariffRestrictionModel,
  RegisterOptions
> {
  return useMemo(
    () => ({
      startTime: {},
      endTime: {},
      minKwh: {},
      maxKwh: {},
    }),
    [],
  );
}
