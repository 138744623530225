import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMAIL_PATTERN, UUID_PATTERN } from 'src/config/form-validation-rules';

type FormValidationProps = {
  field: string;
};

type FormValidationPropsWithValue = FormValidationProps & {
  value: number;
};

type FormValidationHookReturn = {
  required: (props: FormValidationProps) => {
    required: RegisterOptions['required'];
  };
  length: (props: FormValidationPropsWithValue) => {
    minLength: RegisterOptions['minLength'];
    maxLength: RegisterOptions['maxLength'];
  };
  maxLength: (props: FormValidationPropsWithValue) => {
    maxLength: RegisterOptions['maxLength'];
  };
  minLength: (props: FormValidationPropsWithValue) => {
    minLength: RegisterOptions['minLength'];
  };
  min: (props: FormValidationPropsWithValue) => { min: RegisterOptions['min'] };
  max: (props: FormValidationPropsWithValue) => { max: RegisterOptions['max'] };
  uidPattern: (props: FormValidationProps) => {
    pattern: RegisterOptions['pattern'];
  };
  emailPattern: (props: FormValidationProps) => {
    pattern: RegisterOptions['pattern'];
  };
};

export function useFormValidation(): FormValidationHookReturn {
  const { t } = useTranslation();

  return {
    required: (props) => ({
      required: {
        value: true,
        message: t('form.required', props),
      },
    }),

    length: ({ field, value }) => ({
      minLength: {
        value,
        message: t('form.length', {
          field,
          value,
        }),
      },
      maxLength: {
        value,
        message: t('form.length', {
          field,
          value,
        }),
      },
    }),

    minLength: ({ field, value }) => ({
      minLength: {
        value,
        message: t('form.min-length', {
          field,
          value,
        }),
      },
    }),

    maxLength: ({ field, value }) => ({
      maxLength: {
        value,
        message: t('form.max-length', {
          field,
          value,
        }),
      },
    }),

    min: ({ field, value }) => ({
      min: {
        value,
        message: t('form.min', {
          field,
          value,
        }),
      },
    }),

    max: ({ field, value }) => ({
      max: {
        value,
        message: t('form.max', {
          field,
          value,
        }),
      },
    }),

    uidPattern: ({ field }) => ({
      pattern: {
        value: UUID_PATTERN,
        message: t('form.uid-pattern', { field }),
      },
    }),

    emailPattern: ({ field }) => ({
      pattern: {
        value: EMAIL_PATTERN,
        message: t('form.email-pattern', { field }),
      },
    }),
  };
}
