import { Box, BoxProps } from '@mui/material';

export function BodyLayout(props: Readonly<BoxProps>): React.JSX.Element {
  const { children, sx, ...boxProps } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        width: '450px',
        ...sx,
      }}
      {...boxProps}>
      {children}
    </Box>
  );
}
