import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  ChargePointFormModel,
  ChargePointResponseModel,
} from 'src/core/model/charge-point.model';
import { removeEmptyValues } from 'src/shared/form/remove-empty-values';
import { TextField } from 'src/shared/form/text-field';
import { CancelButton } from 'src/shared/button/cancel.button';
import { SubmitButton } from 'src/shared/button/submit.button';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { PreCaptureAmountModel } from 'src/core/model/pre-capture-amount.model';
import { useState } from 'react';
import {
  OcpiCpoModel,
  OcpiEvseModel,
  OcpiLocationModel,
} from 'src/core/model/ocpi.model';
import { useChargePointFormValidation } from './use-charge-point.form-validation';
import { withPreCaptureAmount } from '../pre-capture-amount/with-pre-capture-amount';
import { CpoSelect } from './cpo-select';
import { LocationSelect } from './location-select';
import { EvseSelect } from './evse-select';
import { ConnectorSelect } from './connector-select';

type ChargePointFormViewProps = {
  readonly formType: 'create' | 'update';
  readonly defaultValues?: ChargePointFormModel;
  readonly pending: boolean;
  readonly onSubmit: (payload: ChargePointFormModel) => void;
  readonly onCancel: () => void;
  readonly preCaptureAmountList: PreCaptureAmountModel[];
};

export function ChargePointFormViewWithoutPreCaptureAmountList(
  props: ChargePointFormViewProps,
): React.JSX.Element {
  const {
    formType,
    defaultValues,
    pending,
    onSubmit,
    onCancel,
    preCaptureAmountList,
  } = props;

  const { t } = useTranslation();
  const formValidation = useChargePointFormValidation();

  const {
    register,
    control,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<ChargePointFormModel>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      tariffStrength: preCaptureAmountList.at(0)?.strength,
    },
  });

  const [ocpiCpo, setOcpiCpo] = useState<OcpiCpoModel | null>(null);
  const [ocpiLocation, setOcpiLocation] = useState<OcpiLocationModel | null>(
    null,
  );
  const [ocpiEvse, setOcpiEvse] = useState<OcpiEvseModel | null>(null);

  const chargePointTranslations: Record<
    keyof ChargePointResponseModel,
    string
  > = t('model.charge-point', {
    returnObjects: true,
  });

  const handleSubmitOnlyDirtyField = (payload: ChargePointFormModel) => {
    onSubmit(removeEmptyValues(payload) as ChargePointFormModel);
  };

  return (
    <form noValidate onSubmit={handleSubmit(handleSubmitOnlyDirtyField)}>
      <DialogContent>
        <Controller
          control={control}
          name="cpoId"
          rules={formValidation.cpoId}
          render={({ field: { onChange } }) => (
            <CpoSelect
              label={chargePointTranslations.cpoId}
              errorMessage={errors?.cpoId?.message}
              isTouched={touchedFields?.cpoId}
              onChange={(newValue) => {
                onChange(newValue?.id ?? null);
                setOcpiCpo(newValue ?? null);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="locationId"
          rules={formValidation.locationId}
          render={({ field: { onChange } }) => (
            <LocationSelect
              cpoUrl={ocpiCpo?.endpoint}
              label={chargePointTranslations.locationId}
              errorMessage={errors?.locationId?.message}
              isTouched={touchedFields?.locationId}
              onChange={(newValue) => {
                onChange(newValue?.id ?? null);
                setOcpiLocation(newValue ?? null);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="evseUid"
          rules={formValidation.evseUid}
          render={({ field: { onChange } }) => (
            <EvseSelect
              evseList={ocpiLocation?.evses ?? []}
              label={chargePointTranslations.evseUid}
              errorMessage={errors?.evseUid?.message}
              isTouched={touchedFields?.evseUid}
              onChange={(newValue) => {
                onChange(newValue?.evse_id ?? null);
                setOcpiEvse(newValue ?? null);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="connectorId"
          rules={formValidation.connectorId}
          render={({ field: { onChange } }) => (
            <ConnectorSelect
              connectorList={ocpiEvse?.connectors ?? []}
              label={chargePointTranslations.connectorId}
              errorMessage={errors?.connectorId?.message}
              isTouched={touchedFields?.connectorId}
              onChange={(newValue) => {
                onChange(newValue?.id ?? null);
              }}
            />
          )}
        />

        <FormControl fullWidth margin="dense">
          <InputLabel id="tariff-strength-select-label-id">
            {chargePointTranslations.tariffStrength}
          </InputLabel>

          <Controller
            control={control}
            name="tariffStrength"
            render={({ field: { onChange: onSelect, value, ref } }) => (
              <Select
                ref={ref}
                labelId="tariff-strength-select-label-id"
                id="tariff-strength-select-id"
                label={chargePointTranslations.tariffStrength}
                value={value}
                onChange={onSelect}>
                {preCaptureAmountList.map(({ strength, preCaptureAmount }) => (
                  <MenuItem value={strength} key={strength}>
                    {t('charge-point.form.pre-capture-amount', {
                      value: preCaptureAmount,
                    })}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <TextField
          {...register('comment', formValidation.comment)}
          label={chargePointTranslations.comment}
          errorMessage={errors?.comment?.message}
          isTouched={touchedFields?.comment}
        />

        <TextField
          {...register('themeId', formValidation.themeId)}
          label={chargePointTranslations.themeId}
          errorMessage={errors?.themeId?.message}
          isTouched={touchedFields?.themeId}
        />
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onCancel} />
        <SubmitButton loading={pending} disabled={!isValid}>
          {t(`form.${formType}-button`)}
        </SubmitButton>
      </DialogActions>
    </form>
  );
}

export const ChargePointFormView = withPreCaptureAmount(
  ChargePointFormViewWithoutPreCaptureAmountList,
);
