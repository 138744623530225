import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChargePointResponseModel } from 'src/core/model/charge-point.model';
import { useTextColumn } from 'src/feature/data-grid/column/use-text-column';
import { useUidColumn } from 'src/feature/data-grid/column/use-uid-column';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { PreCaptureAmountModel } from 'src/core/model/pre-capture-amount.model';
import { useCurrencyColumn } from 'src/feature/data-grid/column/use-currency-column';

type UseChargePointColumnProps = {
  onGenerateQRCode: (chargePoint: ChargePointResponseModel) => void;
  preCaptureAmountList: PreCaptureAmountModel[];
};

export function useChargePointColumn(
  props: UseChargePointColumnProps,
): GridColDef[] {
  const { onGenerateQRCode, preCaptureAmountList } = props;

  const { t } = useTranslation();
  const uidColumn = useUidColumn();
  const textColumn = useTextColumn();
  const currencyColumn = useCurrencyColumn();

  const chargePointTranslations: Record<
    keyof ChargePointResponseModel,
    string
  > = t('model.charge-point', {
    returnObjects: true,
  });

  return useMemo(
    () => [
      {
        ...uidColumn,
        field: 'chargePointId',
        headerName: chargePointTranslations.chargePointId,
      },
      {
        ...uidColumn,
        field: 'cpoId',
        headerName: chargePointTranslations.cpoId,
      },
      {
        ...uidColumn,
        field: 'locationId',
        headerName: chargePointTranslations.locationId,
      },
      {
        ...uidColumn,
        field: 'evseUid',
        headerName: chargePointTranslations.evseUid,
      },
      {
        ...currencyColumn,
        width: 120,
        field: 'tariffStrength',
        headerName: chargePointTranslations.tariffStrength,
        valueGetter: (
          params: GridValueGetterParams<ChargePointResponseModel>,
        ): number | null =>
          preCaptureAmountList.find((item) => item.strength === params.value)
            ?.preCaptureAmount ?? null,
      },
      {
        ...uidColumn,
        field: 'connectorId',
        headerName: chargePointTranslations.connectorId,
      },
      {
        ...textColumn,
        field: 'comment',
        width: 300,
        sortable: false,
        headerName: chargePointTranslations.comment,
      },
      {
        ...uidColumn,
        field: 'themeId',
        headerName: chargePointTranslations.themeId,
      },
      {
        field: 'qrCode',
        type: 'boolean',
        headerName: chargePointTranslations.qrCode,
        width: 120,
        valueGetter: (
          params: GridValueGetterParams<ChargePointResponseModel>,
        ): boolean => !!params.row.qrCode,
      },
      {
        field: 'mQrCode',
        type: 'boolean',
        headerName: chargePointTranslations.mQrCode,
        width: 120,
        valueGetter: (
          params: GridValueGetterParams<ChargePointResponseModel>,
        ): boolean => !!params.row.mQrCode,
      },
      {
        field: 'tariffOverloadingConditions',
        headerName: chargePointTranslations.tariffOverloadingConditions,
        type: 'boolean',
        width: 120,
        valueGetter: (
          params: GridValueGetterParams<ChargePointResponseModel>,
        ): boolean => !!params.row.tariffOverloadingConditions,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params: GridRowParams<ChargePointResponseModel>) => [
          <GridActionsCellItem
            key="generate-qr-code"
            label={t('charge-point.data-grid.cta.generate-qr-code')}
            icon={<QrCodeIcon />}
            onClick={() => onGenerateQRCode(params.row)}
          />,
        ],
      },
    ],
    [
      chargePointTranslations,
      uidColumn,
      textColumn,
      currencyColumn,
      preCaptureAmountList,
      onGenerateQRCode,
      t,
    ],
  );
}
