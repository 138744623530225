import MUIAvatar, { AvatarProps as MUIAvatarProps } from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { stringToColor } from 'src/shared/avatar/avatar.utils';

interface AvatarProps extends MUIAvatarProps {
  readonly trigram?: string;
  readonly size?: number;
}

export function Avatar(props: AvatarProps): React.JSX.Element {
  const { trigram, sx, size = 40, ...avatarProps } = props;

  if (!trigram) {
    return (
      <Skeleton
        variant="circular"
        sx={{ flexShrink: 0, ...sx }}
        width={size}
        height={size}
      />
    );
  }

  return (
    <MUIAvatar
      aria-label="user"
      alt={trigram}
      sx={{ bgcolor: stringToColor(trigram), height: size, width: size, ...sx }}
      {...avatarProps}>
      {trigram.toUpperCase()}
    </MUIAvatar>
  );
}
