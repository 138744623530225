import { Box, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FilterQueryModel,
  FilterDefinitionModel,
} from 'src/core/model/data-grid.model';
import { SubmitButton } from 'src/shared/button/submit.button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FormEvent, useState } from 'react';
import { removeEmptyValues } from 'src/shared/form/remove-empty-values';

export type FilterFormProps = {
  filterQuery?: FilterQueryModel;
  filterDefinition?: FilterDefinitionModel[];
  onFilter: (model: FilterQueryModel) => void;
};

export function FilterForm(
  props: Readonly<FilterFormProps>,
): React.JSX.Element {
  const { filterQuery = {}, filterDefinition = [], onFilter } = props;
  const { t } = useTranslation();

  const [values, setValues] = useState<FilterQueryModel>(filterQuery);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onFilter(removeEmptyValues(values) as FilterQueryModel);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit} rowGap={4}>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <FilterAltIcon />
          {t('data-grid.filter.title')}
        </Typography>
        <Divider />
      </Box>

      {filterDefinition.map((filterItem: FilterDefinitionModel) =>
        filterItem.getFilter({ values, setValues }),
      )}

      {filterDefinition.length > 0 ? (
        <SubmitButton>{t('data-grid.filter.submit-button')}</SubmitButton>
      ) : (
        <Typography color="text.secondary" textAlign="center">
          {t('data-grid.filter.no-filter-configured')}
        </Typography>
      )}
    </Stack>
  );
}
