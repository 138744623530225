import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { JsonView } from './json-view';

function parsePayload(payload: any): any {
  try {
    return JSON.parse(payload);
  } catch {
    return payload;
  }
}

type JsonViewDialogProps = {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  payload: any;
};

export function JsonViewDialog(
  props: Readonly<JsonViewDialogProps>,
): React.JSX.Element {
  const { onClose, isOpen, title, payload } = props;

  return (
    <Dialog fullWidth maxWidth="xl" onClose={onClose} open={isOpen}>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>
        <JsonView src={parsePayload(payload)} />
      </DialogContent>
    </Dialog>
  );
}
