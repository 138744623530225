import React from 'react';
import { QrCodeDialog } from '../qr-code-dialog/qr-code-dialog';
import { QrCodeGenerator } from './qr-code-generator';

type Props = {
  readonly tinyId?: string;
  readonly tinyUrl?: string;
  readonly chargePointId: string;
  readonly onSuccess?: () => void;
};

export function QrCodeManager(props: Props): React.JSX.Element {
  const { tinyId, tinyUrl, chargePointId, onSuccess } = props;

  if (tinyId && tinyUrl) {
    return (
      <QrCodeDialog
        tinyId={tinyId}
        tinyUrl={tinyUrl}
        chargePointId={chargePointId}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <QrCodeGenerator chargePointId={chargePointId} onSuccess={onSuccess} />
  );
}
