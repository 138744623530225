import { UpdatePasswordModel } from 'src/core/model/user.model';
import { useUpdatePasswordMutation } from 'src/core/service/current-user.service';

type UpdatePasswordHookReturn = {
  isLoading: boolean;
  isError: boolean;
  onSubmit: (payload: UpdatePasswordModel) => void;
};

type UpdatePasswordHookProps = {
  onSuccess?: () => void;
};

export function useUpdatePassword(
  props: UpdatePasswordHookProps,
): UpdatePasswordHookReturn {
  const { onSuccess = () => {} } = props;
  const [updatePassword, { isLoading, isError }] = useUpdatePasswordMutation();

  const onSubmit = (payload: UpdatePasswordModel): void => {
    updatePassword(payload).then((result) => {
      if (!('error' in result)) {
        onSuccess();
      }
    });
  };

  return {
    onSubmit,
    isLoading,
    isError,
  };
}
