import {
  PriceComponentModel,
  TariffElementModel,
} from 'src/core/model/tariff.model';
import { FieldErrors } from 'react-hook-form';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TariffRestrictionForm } from './tariff-restriction-form/tariff-restriction.form';
import { PriceComponentFormManager } from './price-component.form-manager';
import { LevelTypography } from '../component/level-typography';

type Props = {
  prefixName: string;
  errors?: FieldErrors<TariffElementModel>;
  touchedFields?: Partial<
    Readonly<{
      [x: string]: any;
    }>
  >;
  level?: number;
};

export function TariffElementForm(props: Readonly<Props>): React.JSX.Element {
  const { prefixName, errors, touchedFields, level = 0 } = props;
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={3}>
      <Stack direction="column">
        <LevelTypography level={level} mb={1}>
          {t('tariff.form.tariff-element.tariff-restriction.title')}
        </LevelTypography>
        <TariffRestrictionForm
          prefixName={`${prefixName}.restrictions`}
          errors={errors?.restrictions}
          touchedFields={touchedFields?.restrictions}
        />
      </Stack>

      <PriceComponentFormManager
        level={level}
        prefixName={`${prefixName}.priceComponents`}
        errors={errors?.priceComponents as FieldErrors<PriceComponentModel>[]}
        touchedFields={touchedFields?.priceComponents}
      />
    </Stack>
  );
}
