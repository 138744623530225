import { useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { TextField } from 'src/shared/form/text-field';
import { SubmitButton } from 'src/shared/button/submit.button';
import { QrCodeFormModel } from 'src/core/model/qr-code.model';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { QRCodeTemplateConfig } from 'src/config/qr-code-template/qr-code-template.config';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ConnectorAssetConfig } from 'src/config/qr-code-template/connector-asset.config';

import { useQrCodeFormValidation } from './use-qr-code.form-validation';
import { OnChangeProps } from '../use-qr-code';

type QrCodeFormProps = {
  readonly defaultValues?: Partial<QrCodeFormModel>;
  readonly pending: boolean;
  readonly errorMessage?: string;
  readonly onChange: (payload: OnChangeProps) => void;
  readonly onSubmit: (payload: QrCodeFormModel) => void;
};

export function QrCodeForm(props: QrCodeFormProps): React.JSX.Element {
  const { defaultValues, pending, errorMessage, onChange, onSubmit } = props;

  const { t } = useTranslation();
  const formValidation = useQrCodeFormValidation();

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
    control,
    watch,
  } = useForm<QrCodeFormModel>({
    mode: 'onChange',
    defaultValues,
  });

  const [template, tinyUrl, connector] = watch([
    'template',
    'tinyUrl',
    'connector',
  ]);

  const qrCodeTranslations: Record<keyof QrCodeFormModel, string> = t(
    'model.qr-code',
    {
      returnObjects: true,
    },
  );

  useEffect(() => {
    onChange({ template, tinyUrl, connector });
  }, [template, tinyUrl, onChange, connector]);

  return (
    <Stack
      gap={2}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register('chargePointId', formValidation.chargePointId)}
        readOnly
        label={qrCodeTranslations.chargePointId}
        errorMessage={errors?.chargePointId?.message}
        isTouched={touchedFields?.chargePointId}
      />

      <TextField
        {...register('tinyId', formValidation.tinyId)}
        readOnly
        label={qrCodeTranslations.tinyId}
        errorMessage={errors?.tinyId?.message}
        isTouched={touchedFields?.tinyId}
      />

      <TextField
        {...register('tinyUrl', formValidation.tinyUrl)}
        readOnly
        label={qrCodeTranslations.tinyUrl}
        errorMessage={errors?.tinyUrl?.message}
        isTouched={touchedFields?.tinyUrl}
      />

      <FormControl fullWidth margin="dense">
        <InputLabel id="qr-code-template-select-id">
          {qrCodeTranslations.template}
        </InputLabel>

        <Controller
          control={control}
          name="template"
          render={({ field: { onChange: onSelect, value, ref } }) => (
            <Select
              ref={ref}
              labelId="qr-code-template-select"
              id="qr-code-template-select-id"
              label={qrCodeTranslations.template}
              value={value}
              onChange={onSelect}>
              {Object.keys(QRCodeTemplateConfig).map((key) => (
                <MenuItem value={key} key={key}>
                  {t(`model.enum.qr-code-template.${key}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>

      <FormControl fullWidth margin="dense">
        <InputLabel id="qr-code-connector-select-id">
          {qrCodeTranslations.connector}
        </InputLabel>

        <Controller
          control={control}
          name="connector"
          render={({ field: { onChange: onSelect, value, ref } }) => (
            <Select
              ref={ref}
              labelId="qr-code-connector-select"
              id="qr-code-connector-select-id"
              label={qrCodeTranslations.connector}
              value={value}
              onChange={onSelect}>
              {Object.keys(ConnectorAssetConfig).map((key) => (
                <MenuItem value={key} key={key}>
                  {t(`model.enum.connector-type.${key}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>

      {errorMessage && (
        <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
      )}

      <SubmitButton loading={pending} disabled={!isValid}>
        {t(`form.create-button`)}
      </SubmitButton>
    </Stack>
  );
}
