import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import { DialogTitle } from 'src/shared/typography/title';
import {
  CustomerLockBody,
  useLockCustomerMutation,
} from 'src/core/service/customer.service';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useApiError } from 'src/core/helper/use-api-error';
import { CustomerLockFormModel } from 'src/core/model/customer.model';
import { CustomerLockFormView } from './customer-lock-form.view';

type CustomerLockDialogProps = Omit<DialogProps, 'onClose'> & {
  customerId: string;
  onClose: () => void;
};

export function CustomerLockDialog(
  props: Readonly<CustomerLockDialogProps>,
): React.JSX.Element {
  const { customerId, onClose, ...dialogProps } = props;
  const { t } = useTranslation();

  const [lock, { isLoading, isError, error }] = useLockCustomerMutation();
  const { status, message } = useApiError(error);

  const handleLock = (payload: CustomerLockFormModel): void => {
    const body: CustomerLockBody = { customerId };

    if (payload.duration) {
      body.duration = payload.duration * 60;
    }

    lock(body);
  };

  return (
    <>
      <Dialog onClose={onClose} {...dialogProps}>
        <DialogTitle icon={<LockIcon />}>
          {t('customer.lock.title')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{t('customer.lock.subtitle')}</DialogContentText>
        </DialogContent>

        {customerId && (
          <CustomerLockFormView
            pending={isLoading}
            onCancel={onClose}
            onSubmit={handleLock}
          />
        )}
      </Dialog>

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.lock-customer-mutation', { status, message })}
        />
      )}
    </>
  );
}
