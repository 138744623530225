import { GridColTypeDef, GridValueGetterParams } from '@mui/x-data-grid';

export function useDatetimeColumn(): GridColTypeDef {
  return {
    type: 'dateTime',
    width: 160,
    valueGetter: (params: GridValueGetterParams): Date | null =>
      params.value ? new Date(params.value) : null,
  };
}
