import { useMemo } from 'react';
import { DataGrid } from 'src/feature/data-grid/data-grid';
import { useDataGridSearchParams } from 'src/feature/data-grid/use-data-grid-search-params/use-data-grid-search-params';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useTranslation } from 'react-i18next';
import { useGetListLogQuery } from 'src/core/service/log.service';
import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';
import { useGenericLogColumn } from './column/use-generic-log-column';
import { useOcpiLogColumn } from './column/use-ocpi-log-column';
import { useEmspWebAppLogColumn } from './column/use-emsp-web-app-log-column';
import { useOcpiLogFilter } from './filter/use-ocpi-log-filter';
import { useGenericLogFilter } from './filter/use-generic-log-filter';

export function LogDataGrid(): React.JSX.Element {
  const { t } = useTranslation();

  const genericLogColumn = useGenericLogColumn();
  const ocpiLogColumn = useOcpiLogColumn();
  const emspWebAppLogColumn = useEmspWebAppLogColumn();

  const columns = useMemo(
    () => [...genericLogColumn, ...ocpiLogColumn, ...emspWebAppLogColumn],
    [genericLogColumn, ocpiLogColumn, emspWebAppLogColumn],
  );

  const ocpiLogFilter = useOcpiLogFilter();
  const genericLogFilter = useGenericLogFilter();

  const filterDefinitionModel = [...ocpiLogFilter, ...genericLogFilter];

  const { model, onModelChange } = useDataGridSearchParams({
    ...DEFAULT_DATA_GRID_MODEL,
    sort: [
      {
        field: 'eventDate',
        sort: 'desc',
      },
    ],
  });

  const {
    data: paginatedData,
    isFetching,
    isError,
  } = useGetListLogQuery(model);

  return (
    <>
      <DataGrid
        getRowId={(row) => {
          return row.eventId;
        }}
        columns={columns}
        filterDefinitionModel={filterDefinitionModel}
        loading={isFetching}
        rows={paginatedData?.docs ?? []}
        rowCount={paginatedData?.totalDocs ?? 0}
        model={model}
        onModelChange={onModelChange}
      />

      {isError && (
        <Snackbar variant="error" message={t('error.get-list-log-query')} />
      )}
    </>
  );
}
