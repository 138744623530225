import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from 'src/config/form-validation-rules';
import { PasswordLoginModel } from 'src/core/model/login.model';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export type EmailPasswordForm = {
  emailAddress: string;
  password: string;
};

export function useEmailPasswordFormValidation(): Record<
  keyof EmailPasswordForm,
  RegisterOptions
> {
  const { t } = useTranslation();

  const loginTranslations: Record<keyof PasswordLoginModel, string> = t(
    'model.password-login',
    {
      returnObjects: true,
    },
  );

  const formValidationRules = useFormValidation();

  return useMemo(
    () => ({
      emailAddress: {
        ...formValidationRules.required({
          field: loginTranslations.emailAddress,
        }),
        ...formValidationRules.emailPattern({
          field: loginTranslations.emailAddress,
        }),
      },
      password: {
        ...formValidationRules.required({
          field: loginTranslations.password,
        }),
        ...formValidationRules.minLength({
          field: loginTranslations.password,
          value: PASSWORD_MIN_LENGTH,
        }),
        ...formValidationRules.maxLength({
          field: loginTranslations.password,
          value: PASSWORD_MAX_LENGTH,
        }),
      },
    }),
    [formValidationRules, loginTranslations],
  );
}
