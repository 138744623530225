import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from 'src/config/form-validation-rules';
import { UpdatePasswordModel } from 'src/core/model/user.model';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export type UpdatePasswordFormModel = UpdatePasswordModel & {
  passwordVerification: string;
};

export function useUpdatePasswordFormValidation(): Record<
  keyof UpdatePasswordFormModel,
  RegisterOptions
> {
  const { t } = useTranslation();

  const translations: Record<keyof UpdatePasswordFormModel, string> = t(
    'model.update-password',
    {
      returnObjects: true,
    },
  );

  const formValidationRules = useFormValidation();

  return useMemo(
    () => ({
      currentPassword: {
        ...formValidationRules.required({
          field: translations.currentPassword,
        }),
        ...formValidationRules.minLength({
          field: translations.currentPassword,
          value: PASSWORD_MIN_LENGTH,
        }),
        ...formValidationRules.maxLength({
          field: translations.currentPassword,
          value: PASSWORD_MAX_LENGTH,
        }),
      },
      password: {
        ...formValidationRules.required({
          field: translations.password,
        }),
        ...formValidationRules.minLength({
          field: translations.password,
          value: PASSWORD_MIN_LENGTH,
        }),
        ...formValidationRules.maxLength({
          field: translations.password,
          value: PASSWORD_MAX_LENGTH,
        }),
        validate: {
          samePassword: (value, formValues) =>
            value !== formValues.currentPassword ||
            t('update-password.must-be-different'),
        },
        deps: ['currentPassword'],
      },
      passwordVerification: {
        ...formValidationRules.required({
          field: translations.passwordVerification,
        }),
        ...formValidationRules.minLength({
          field: translations.passwordVerification,
          value: PASSWORD_MIN_LENGTH,
        }),
        ...formValidationRules.maxLength({
          field: translations.passwordVerification,
          value: PASSWORD_MAX_LENGTH,
        }),
        validate: {
          samePassword: (value, formValues) =>
            value === formValues.password || t('update-password.same-password'),
        },
        deps: ['password'],
      },
    }),
    [t, formValidationRules, translations],
  );
}
