import { useTranslation } from 'react-i18next';
import { useApiError } from 'src/core/helper/use-api-error';
import { PreCaptureAmountListResponseModel } from 'src/core/model/pre-capture-amount.model';
import { useGetListPreCaptureAmountQuery } from 'src/core/service/payment.service';
import { Snackbar } from 'src/shared/snackbar/snackbar';

export type Props = {
  readonly preCaptureAmountList: PreCaptureAmountListResponseModel;
};

type ComponentProps<T> = Omit<T, keyof Props>;

export function withPreCaptureAmount<T extends Props = Props>(
  WrappedComponent: React.ComponentType<T>,
): (props: ComponentProps<T>) => React.JSX.Element {
  const ComponentWithPreCaptureAmount = (
    props: ComponentProps<T>,
  ): React.JSX.Element => {
    const { t } = useTranslation();

    const {
      data: preCaptureAmountList,
      error,
      isError,
    } = useGetListPreCaptureAmountQuery();
    const { status, message } = useApiError(error);

    return (
      <>
        <WrappedComponent
          {...(props as T)}
          preCaptureAmountList={preCaptureAmountList ?? []}
        />

        {isError && (
          <Snackbar
            variant="error"
            message={t('error.get-list-pre-capture-amount-query', {
              status,
              message,
            })}
          />
        )}
      </>
    );
  };

  return ComponentWithPreCaptureAmount;
}
