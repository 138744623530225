import Chip from '@mui/material/Chip';

export type FilterChipProps = {
  label: string;
  onDelete?: () => void;
};

export function FilterChip(
  props: Readonly<FilterChipProps>,
): React.JSX.Element {
  const { label, onDelete = () => {} } = props;

  return (
    <Chip label={label} key={label} variant="outlined" onDelete={onDelete} />
  );
}
