import { DataGridQueryModel } from 'src/core/model/data-grid.model';
import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';

import { serializeDataGridQueryModel } from './serialize-data-grid-query-model';

export function generateDataGridRoute(
  route: string,
  query: DataGridQueryModel,
): string {
  const searchParams = serializeDataGridQueryModel({
    ...DEFAULT_DATA_GRID_MODEL,
    ...query,
  });
  return `${route}?${searchParams.toString()}`;
}
