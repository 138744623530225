import { useTranslation } from 'react-i18next';
import { useUnlockCustomerMutation } from 'src/core/service/customer.service';
import { Button, ButtonProps } from 'src/shared/button/button';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useApiError } from 'src/core/helper/use-api-error';

type CustomerUnlockButtonProps = Omit<
  ButtonProps,
  'children' | 'onClick' | 'loading' | 'disabled'
> & {
  customerId?: string;
};

export function CustomerUnlockButton(
  props: CustomerUnlockButtonProps,
): React.JSX.Element {
  const { customerId, ...buttonProps } = props;
  const { t } = useTranslation();
  const [unlock, { isLoading, isError, error }] = useUnlockCustomerMutation();
  const { status, message } = useApiError(error);

  const handleClick = () => {
    if (customerId) {
      unlock(customerId);
    }
  };

  return (
    <>
      <Button
        startIcon={<LockOpenIcon />}
        disabled={!customerId}
        loading={isLoading}
        onClick={handleClick}
        {...buttonProps}>
        {t('customer.lock.unlock-button')}
      </Button>

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.unlock-customer-mutation', { status, message })}
        />
      )}
    </>
  );
}
