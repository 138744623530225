import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from 'src/shared/button/button';
import LockIcon from '@mui/icons-material/Lock';
import { useState } from 'react';
import { CustomerLockDialog } from './customer-lock-dialog';

type CustomerLockButtonProps = Omit<
  ButtonProps,
  'children' | 'onClick' | 'loading' | 'disabled'
> & {
  customerId?: string;
};

export function CustomerLockButton(
  props: CustomerLockButtonProps,
): React.JSX.Element {
  const { customerId, ...buttonProps } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        startIcon={<LockIcon />}
        disabled={!customerId}
        onClick={() => setIsDialogOpen(true)}
        {...buttonProps}>
        {t('customer.lock.lock-button')}
      </Button>

      {customerId && (
        <CustomerLockDialog
          customerId={customerId}
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
}
