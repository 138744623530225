import {
  FilterDefinitionModel,
  FilterFormItemProps,
  FilterValueModel,
} from 'src/core/model/data-grid.model';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ReactNode } from 'react';

import { FilterItem } from './filter-item';
import { useSimpleChip } from './use-simple-chip';

type UseCheckboxFilterProps = {
  field: string;
  label: string;
  options?: string[];
  valueFormatter?: (value: FilterValueModel) => string;
};

export function useCheckboxFilter(
  hookProps: UseCheckboxFilterProps,
): FilterDefinitionModel {
  const {
    field,
    label,
    options = [],
    valueFormatter = (value) => value.toString(),
  } = hookProps;

  const getChip = useSimpleChip({ field, label, valueFormatter });

  return {
    getFilter: (props: FilterFormItemProps): ReactNode => {
      const { values, setValues } = props;

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [field]: event.target.value }));
      };

      return (
        <FilterItem key={field} label={label}>
          <FormControl>
            <RadioGroup
              aria-labelledby={field}
              name={field}
              value={values[field] ?? ''}
              onChange={handleChange}>
              {options.map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={valueFormatter(value)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </FilterItem>
      );
    },

    getChip,
  };
}
