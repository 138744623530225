export const DRAWER_MENU_WIDTH = 240;

export const I18N_DEFAULT_NS = 'translation';

export const OCPI_FILTERABLE_FIELDS: string[] = [
  'sessionId',
  'cdrId',
  'locationId',
  'evseId',
  'evseUid',
  'connectorId',
  'commandId',
  'eventName',
];
export const OCPI_FILTERABLE_FIELD_KEY = 'ocpiFilter';

export const CSV_MIME_TYPE = 'text/csv';
export const DELMONICOS_QUARTER_OVERVIEW_EXTERNAL_ID_CARD = 'FR-DLM';

export const QO_PARSE_INDEX = {
  id: 0,
  date: 1,
  chargePointName: 2,
  connectorId: 3,
  customerId: 4,
  name: 5,
  externalCardId: 6,
  internalCardId: 7,
  global: 8,
  energyDelivered: 9,
  totalHT: 10,
  durationInMin: 11,
};

export const QO_PARSE_CHECKOUT = {
  breakdownType: 22,
  iTransactionId: 14,
  totalHT: 24,
};
export const TIME_RESTRICTION_FORMAT = 'HH:mm';
