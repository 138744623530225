import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiError } from 'src/core/helper/use-api-error';
import { useGetListCpoQuery } from 'src/core/service/cpo.service';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { OcpiCpoModel } from 'src/core/model/ocpi.model';
import { Autocomplete, AutocompleteProps } from 'src/shared/form/autocomplete';

type Props = Pick<
  AutocompleteProps<OcpiCpoModel>,
  'onChange' | 'label' | 'errorMessage' | 'isTouched'
>;

export function CpoSelect(
  autocompleteProps: Readonly<Props>,
): React.JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const {
    data: cpoList,
    error,
    isError,
    isLoading,
  } = useGetListCpoQuery(null, { skip: !isOpen });
  const { status, message } = useApiError(error);
  const { t } = useTranslation();

  return (
    <>
      <Autocomplete
        isOpen={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        {...autocompleteProps}
        options={cpoList}
        isLoading={isLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.id} - ${option.endpoint}`}
      />

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.get-list-cpo-query', {
            status,
            message,
          })}
        />
      )}
    </>
  );
}
