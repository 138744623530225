import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { IconButton } from '@mui/material';

interface DrawerMenuItemCollapsibleProps {
  readonly icon: React.ReactNode;
  readonly label: string;
  readonly children: React.ReactNode;
  readonly onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export function DrawerMenuItemCollapsible(
  props: DrawerMenuItemCollapsibleProps,
): React.ReactNode {
  const { icon, label, children, onClick } = props;

  const [open, setOpen] = React.useState(false);
  const handleExpand = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpen((value) => !value);
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={onClick}>
          <ListItemIcon>{icon} </ListItemIcon>
          <Typography variant="inherit" noWrap sx={{ flex: 1 }}>
            {label}
          </Typography>
          <IconButton onClick={handleExpand}>
            {open ? <ExpandMore /> : <ChevronRightIcon />}
          </IconButton>
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 2 }}>
          {children}
        </List>
      </Collapse>
    </>
  );
}
