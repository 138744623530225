import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/shared/button/button';
import { TariffElementModel } from 'src/core/model/tariff.model';
import AddIcon from '@mui/icons-material/Add';

import { FieldArrayFormContainer } from './component/field-array-form-container';
import { TariffElementForm } from './tariff-element-form/tariff-element.form';
import { FormSection } from './component/form-section';

const DEFAULT_TARIFF_ELEMENT: TariffElementModel = {
  restrictions: {},
  priceComponents: [],
};

type Props = {
  readonly level?: number;
};

export function TariffElementFormManager(props: Props): React.JSX.Element {
  const { level = 0 } = props;
  const { t } = useTranslation();
  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext();

  const tariffElementErrors:
    | Merge<FieldError, FieldErrorsImpl<TariffElementModel[]>>
    | undefined = errors?.elements;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'elements',
  });

  return (
    <FormSection
      level={level}
      title={t('tariff.form.tariff-element.title', { count: fields.length })}
      showText={t('tariff.form.tariff-element.show', {
        count: fields.length,
      })}
      canHide={fields.length > 0}>
      {fields.map((field, index) => (
        <FieldArrayFormContainer
          level={level + 1}
          key={field.id}
          title={t('tariff.form.tariff-element.item.title', {
            index: index + 1,
          })}
          onRemove={() => remove(index)}>
          <TariffElementForm
            level={level + 2}
            prefixName={`elements.${index}`}
            errors={tariffElementErrors?.[index]}
            touchedFields={touchedFields?.elements?.[index]}
          />
        </FieldArrayFormContainer>
      ))}

      <div>
        <Button
          startIcon={<AddIcon />}
          onClick={() => append(DEFAULT_TARIFF_ELEMENT)}>
          {t('tariff.form.tariff-element.append')}
        </Button>
      </div>
    </FormSection>
  );
}
