import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { CustomerResponseModel } from 'src/core/model/customer.model';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import GroupIcon from '@mui/icons-material/Group';

import { SkeletonList } from '../skeleton/skeleton-list';

type CustomerCardProps = CardProps &
  Partial<CustomerResponseModel> & {
    isPending?: boolean;
  };

export function CustomerCard(
  props: Readonly<CustomerCardProps>,
): React.JSX.Element {
  const {
    firstName,
    lastName,
    pseudo,
    emailAddress,
    lastConnection,
    isEmailAddressVerified = false,
    isWebauthnEnabled = false,
    isPending = false,
    emspSubscriptionIds,
    sx,
    ...cardProps
  } = props;

  const { t } = useTranslation();

  const customerTranslations: Record<keyof CustomerResponseModel, string> = t(
    'model.customer',
    {
      returnObjects: true,
    },
  );

  return (
    <Card sx={{ overflow: 'unset', ...sx }} {...cardProps}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary.light' }}>
            <GroupIcon color="primary" />
          </Avatar>
        }
        title={t('customer.card.title')}
      />

      <CardContent>
        <List>
          {isPending ? (
            <SkeletonList />
          ) : (
            <>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  secondary={firstName ?? t('customer.card.fallback')}
                  primary={customerTranslations.firstName}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  secondary={lastName ?? t('customer.card.fallback')}
                  primary={customerTranslations.lastName}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <PermIdentityIcon />
                </ListItemIcon>
                <ListItemText
                  primary={customerTranslations.pseudo}
                  secondary={pseudo ?? t('customer.card.fallback')}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={customerTranslations.lastConnection}
                  secondary={
                    lastConnection
                      ? t('customer.card.lastConnection', {
                          value: new Date(lastConnection),
                        })
                      : t('customer.card.fallback')
                  }
                />
              </ListItem>

              <ListItem
                secondaryAction={
                  isEmailAddressVerified ? <CheckCircleIcon /> : <CancelIcon />
                }>
                <ListItemIcon>
                  <EmailOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={customerTranslations.emailAddress}
                  secondary={emailAddress ?? t('customer.card.fallback')}
                />
              </ListItem>

              <ListItem
                secondaryAction={
                  isWebauthnEnabled ? <CheckCircleIcon /> : <CancelIcon />
                }>
                <ListItemIcon>
                  <FingerprintIcon />
                </ListItemIcon>
                <ListItemText
                  primary={customerTranslations.isWebauthnEnabled}
                />
              </ListItem>
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
}
