import { APP_PATH } from 'src/config/app-paths';
import { RoutingConfigurationModel } from 'src/core/model/routing-configuration.model';
import { RoleEnum } from 'src/core/model/enum/role.enum';
import { InvoiceDataGrid } from 'src/feature/invoice/invoice-data-grid/invoice.data-grid';
import { OperatorList } from 'src/feature/user/operator/operator-list/operator-list';
import { AdministratorList } from 'src/feature/user/administrator/administrator-list/administrator-list';
import { DeveloperList } from 'src/feature/user/developer/developer-list/developer-list';
import { CustomerDataGrid } from 'src/feature/customer/customer-data-grid/customer.data-grid';
import { flatRoutingConfiguration } from 'src/core/helper/flat-routing-configuration';
import { ChargingSessionDataGrid } from 'src/feature/charging-session/charging-session-data-grid/charging-session.data-grid';
import { ChargePointList } from 'src/feature/charge-point/charge-point-list/charge-point-list';
import { InvoicePreview } from 'src/feature/invoice/invoice-preview/invoice-preview';
import { OrderDataGrid } from 'src/feature/order/order-data-grid/order.data-grid';
import { ChargingSessionDetail } from 'src/feature/charging-session/charging-session-detail/charging-session-detail';
import { CustomerDetail } from 'src/feature/customer/customer-detail/customer-detail';
import { LogDataGrid } from 'src/feature/log/log-data-grid/log.data-grid';
import { DashboardView } from 'src/feature/dashboard/dashboard.view';
import FaceIcon from '@mui/icons-material/Face';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AdbIcon from '@mui/icons-material/Adb';
import EvStationIcon from '@mui/icons-material/EvStation';
import BoltIcon from '@mui/icons-material/Bolt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import GroupIcon from '@mui/icons-material/Group';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { QuarterOverview } from 'src/feature/quarter-overview/quarter-overview';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { TariffDemo } from 'src/feature/tariff/demo/tariff-demo';

export const RoutingConfiguration: RoutingConfigurationModel[] = [
  {
    labelKey: 'dashboard.title',
    route: APP_PATH.DASHBOARD,
    controller: DashboardView,
    icon: <DashboardIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'invoice.list.title',
    route: APP_PATH.LIST_INVOICE,
    controller: InvoiceDataGrid,
    role: RoleEnum.OPERATOR,
    icon: <RequestQuoteIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'order.list.title',
    route: APP_PATH.LIST_ORDER,
    controller: OrderDataGrid,
    role: RoleEnum.OPERATOR,
    icon: <ShoppingCartIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'user.administrator.list.title',
    route: APP_PATH.LIST_ADMINISTRATOR,
    controller: AdministratorList,
    role: RoleEnum.SUPERADMIN,
    icon: <SupervisorAccountIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'user.operator.list.title',
    route: APP_PATH.LIST_OPERATOR,
    controller: OperatorList,
    role: RoleEnum.ADMIN,
    icon: <FaceIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'user.developer.list.title',
    route: APP_PATH.LIST_DEVELOPER,
    controller: DeveloperList,
    role: RoleEnum.SUPERADMIN,
    icon: <AdbIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'customer.list.title',
    route: APP_PATH.LIST_CUSTOMER,
    controller: CustomerDataGrid,
    role: RoleEnum.OPERATOR,
    icon: <GroupIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'customer.detail.title',
    route: APP_PATH.CUSTOMER_DETAIL,
    controller: CustomerDetail,
    role: RoleEnum.OPERATOR,
    icon: <GroupIcon />,
    showInMenu: false,
  },
  {
    labelKey: 'charging-session.list.title',
    route: APP_PATH.LIST_CHARGING_SESSION,
    controller: ChargingSessionDataGrid,
    role: RoleEnum.OPERATOR,
    icon: <BoltIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'charge-point.list.title',
    route: APP_PATH.LIST_CHARGE_POINT,
    controller: ChargePointList,
    role: RoleEnum.OPERATOR,
    icon: <EvStationIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'invoice.preview.title',
    route: APP_PATH.INVOICE_HTML,
    controller: InvoicePreview,
    role: RoleEnum.OPERATOR,
    icon: <RequestQuoteIcon />,
    showInMenu: false,
  },
  {
    labelKey: 'charging-session.detail.title',
    route: APP_PATH.CHARGING_SESSION_DETAIL,
    controller: ChargingSessionDetail,
    role: RoleEnum.OPERATOR,
    icon: <BoltIcon />,
    showInMenu: false,
  },
  {
    labelKey: 'log.list.title',
    route: APP_PATH.LIST_LOG,
    controller: LogDataGrid,
    role: RoleEnum.DEVELOPER,
    icon: <LogoDevIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'quarter-overview.title',
    route: APP_PATH.QUARTER_OVERVIEW,
    controller: QuarterOverview,
    role: RoleEnum.OPERATOR,
    icon: <AccountBalanceIcon />,
    showInMenu: true,
  },
  {
    labelKey: 'tariff.demo.title',
    route: APP_PATH.LIST_TARIFF,
    controller: TariffDemo,
    role: RoleEnum.OPERATOR,
    icon: <PriceCheckIcon />,
    showInMenu: true,
  },
];

export const FlatRoutingConfiguration =
  flatRoutingConfiguration(RoutingConfiguration);
