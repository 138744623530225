export enum CheckoutBreakdownTypeEnum {
  GATEWAY_FIXED_FEE = 'Gateway Fixed Fee',
  SCHEME_VARIABLE_FEE = 'Scheme Variable Fee',
  SCHEME_FIXED_FEE = 'Scheme Fixed Fee',
  INTERCHANGE_FIXED_FEE = 'Interchange Fixed Fee',
  PREMIUM_VARIABLE_FEE = 'Premium Variable Fee',

  PAYOUT = 'Payout',
  CAPTURE = 'Capture',
  PARTIAL_CAPTURE = 'Partial Capture',
}
