import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { SkeletonList } from 'src/shared/skeleton/skeleton-list';

type SubscriptionCardContentProps = {
  readonly subscriptionIds?: string[];
  readonly isLoading?: boolean;
};

function SubscriptionCardContent(
  props: SubscriptionCardContentProps,
): React.JSX.Element {
  const { isLoading = false, subscriptionIds = [] } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return <SkeletonList />;
  }

  if (subscriptionIds.length === 0) {
    return <Typography>{t('customer.subscription-card.no-item')}</Typography>;
  }

  return (
    <>
      {subscriptionIds.map((subscriptionId, index) => (
        <ListItem key={subscriptionId}>
          <ListItemIcon>
            <LoyaltyIcon />
          </ListItemIcon>
          <ListItemText
            secondary={subscriptionId}
            primary={t('customer.subscription-card.item-label', {
              index: index + 1,
            })}
          />
        </ListItem>
      ))}
    </>
  );
}

type Props = CardProps & SubscriptionCardContentProps;

export function CustomerSubscriptionCard(
  props: Readonly<Props>,
): React.JSX.Element {
  const { subscriptionIds = [], isLoading = false, sx, ...cardProps } = props;

  const { t } = useTranslation();

  return (
    <Card sx={{ overflow: 'unset', ...sx }} {...cardProps}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary.light' }}>
            <CardMembershipIcon color="primary" />
          </Avatar>
        }
        title={t('customer.subscription-card.title', {
          count: subscriptionIds.length,
        })}
      />

      <CardContent>
        <SubscriptionCardContent
          isLoading={isLoading}
          subscriptionIds={subscriptionIds}
        />
      </CardContent>
    </Card>
  );
}
