import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TariffModel } from 'src/core/model/tariff.model';
import { Button } from 'src/shared/button/button';
import { Divider, Stack } from '@mui/material';
import { TariffAltTextFormManager } from './tariff-alt-text.form-manager';
import { TariffElementFormManager } from './tariff-element.form-manager';

type Props = {
  readonly defaultValues?: TariffModel;
  readonly isLoading?: boolean;
  readonly onSubmit: (payload: TariffModel) => void;
};

export function TariffForm(props: Props): React.JSX.Element {
  const { defaultValues, isLoading = false, onSubmit } = props;
  const { t } = useTranslation();

  const methods = useForm<TariffModel>({
    mode: 'onChange',
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={4}>
          <TariffAltTextFormManager level={0} />

          <Divider />

          <TariffElementFormManager level={0} />

          <Divider />

          <div>
            <Button
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={!isValid}>
              {t('tariff.form.submit-button')}
            </Button>
          </div>
        </Stack>
      </form>
    </FormProvider>
  );
}
