import { FileExtension } from 'qr-code-styling';
import Card from '@mui/material/Card';
import {
  Box,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ForwardedRef, forwardRef, useState } from 'react';
import { Button } from 'src/shared/button/button';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';

type QrCodePreviewProps = {
  readonly width: number;
  readonly height: number;
  readonly isError: boolean;
  readonly onDownload: (extension: FileExtension) => void;
};

function QrCodePreviewWithoutRef(
  props: QrCodePreviewProps,
  ref: ForwardedRef<HTMLDivElement>,
): React.JSX.Element {
  const { width, height, isError, onDownload } = props;

  const [fileExt, setFileExt] = useState<FileExtension>('svg');
  const { t } = useTranslation();

  const handleExtensionChange = (event: SelectChangeEvent) => {
    setFileExt(event.target.value as FileExtension);
  };

  return (
    <Box sx={{ width: 'fit-content', flexBasis: 'fit-content' }}>
      <Card>
        <CardContent sx={{ width, height, boxSizing: 'content-box' }}>
          <div ref={ref} />
          {isError && (
            <Typography sx={{ color: 'red' }}>
              {t('qr-code.preview.error-in-generation')}
            </Typography>
          )}
        </CardContent>

        <CardActions sx={{ gap: 2, justifyContent: 'center' }}>
          <FormControl sx={{ width: 100 }} size="small">
            <InputLabel id="file-extension-select">
              {t('qr-code.preview.file-extension-label')}
            </InputLabel>

            <Select
              labelId="file-extension-select"
              id="file-extension-select-id"
              value={fileExt}
              label={t('qr-code.preview.file-extension-label')}
              onChange={handleExtensionChange}>
              <MenuItem value="svg">SVG</MenuItem>
              <MenuItem value="png">PNG</MenuItem>
              <MenuItem value="jpeg">JPEG</MenuItem>
              <MenuItem value="webp">Webp</MenuItem>
            </Select>
          </FormControl>

          <Button
            onClick={() => onDownload(fileExt)}
            variant="contained"
            endIcon={<DownloadIcon />}>
            {t('qr-code.preview.download-button')}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export const QrCodePreview = forwardRef<HTMLDivElement, QrCodePreviewProps>(
  QrCodePreviewWithoutRef,
);
