import { useCallback, useEffect, useMemo, useState } from 'react';
import { EnhancedQuarterOverviewModel } from 'src/core/model/quarter-overview.model';
import { ChargingSessionResponseModel } from 'src/core/model/charging-session.model';
import { useGetListChargingSessionQuery } from 'src/core/service/charging-session.service';
import { QuarterOverviewStateEnum } from 'src/core/model/enum/quarter-overview-state.enum';
import { ChargingSessionSectionProps } from './charging-session-section';

type Props = {
  readonly sessionId: string;
  readonly onChange: (data: Partial<EnhancedQuarterOverviewModel>) => void;
};

type UseEnhanceWithChargingSession = ChargingSessionSectionProps & {
  state: QuarterOverviewStateEnum;
};

export function useEnhanceWithChargingSession(
  props: Props,
): UseEnhanceWithChargingSession {
  const { onChange, sessionId } = props;

  const [selectedId, setSelectedId] = useState<string>();
  const [state, setState] = useState<QuarterOverviewStateEnum>(
    QuarterOverviewStateEnum.INIT,
  );

  const {
    data: paginatedChargingSessionList,
    isLoading,
    isError,
  } = useGetListChargingSessionQuery({
    filter: { ocpiSessionId: sessionId },
  });

  const list = useMemo(
    () => paginatedChargingSessionList?.docs ?? [],
    [paginatedChargingSessionList],
  );

  const handleSelect = useCallback(
    (chargingSession: ChargingSessionResponseModel) => {
      setSelectedId(chargingSession.id);
      setState(QuarterOverviewStateEnum.SUCCESS);
      onChange({
        transactionId: chargingSession.id,
      });
    },
    [onChange],
  );

  useEffect(() => {
    if (state === QuarterOverviewStateEnum.SUCCESS) {
      return;
    }

    if (isLoading) {
      setState(QuarterOverviewStateEnum.LOADING);
      return;
    }

    if (isError) {
      setState(QuarterOverviewStateEnum.QUERY_ERROR);
      return;
    }

    if (list.length > 1) {
      setState(QuarterOverviewStateEnum.MULTIPLE_ITEM_FOUND);
      return;
    }

    const chargingSession = list.at(0);

    if (!chargingSession) {
      setState(QuarterOverviewStateEnum.NO_ITEM_FOUND);
      return;
    }

    handleSelect(chargingSession);
  }, [list, handleSelect, isError, isLoading, state]);

  return useMemo(
    () => ({
      list,
      selectedId,
      onSelect: handleSelect,
      state,
    }),
    [list, selectedId, handleSelect, state],
  );
}
