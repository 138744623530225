import { createApi } from '@reduxjs/toolkit/query/react';

import {
  LOGIN_PASSWORD_ENDPOINT,
  LOGIN_SEND_OTP_ENDPOINT,
  LOGIN_VERIFY_OTP_ENDPOINT,
} from 'src/config/api-routes';
import { AccessTokenResponseModel } from 'src/core/model/access-token-response.model';
import {
  PasswordLoginModel,
  SendOtpModel,
  VerifyOtpModel,
} from 'src/core/model/login.model';
import { baseQueryBuilder } from './base-query.builder';

export const loginApi = createApi({
  reducerPath: 'loginApi',

  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    login: builder.mutation<AccessTokenResponseModel, PasswordLoginModel>({
      query: (body: PasswordLoginModel) => ({
        url: LOGIN_PASSWORD_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),

    sendOtp: builder.mutation<void, SendOtpModel>({
      query: (body: SendOtpModel) => ({
        url: LOGIN_SEND_OTP_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),

    verifyOtp: builder.mutation<AccessTokenResponseModel, VerifyOtpModel>({
      query: (body: VerifyOtpModel) => ({
        url: LOGIN_VERIFY_OTP_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLoginMutation, useSendOtpMutation, useVerifyOtpMutation } =
  loginApi;
