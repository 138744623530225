import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';

type SelectProps = Pick<
  MuiSelectProps,
  'children' | 'labelId' | 'id' | 'label' | 'value' | 'onChange'
> &
  Pick<FormControlProps, 'size'> & {
    readonly errorMessage?: string | null;
    readonly isTouched?: boolean;
  };

export function SelectWithoutRef(
  props: SelectProps,
  ref: ForwardedRef<HTMLSelectElement>,
): React.JSX.Element {
  const { errorMessage, isTouched, label, labelId, size, ...muiSelectProps } =
    props;

  return (
    <FormControl error={isTouched && !!errorMessage} size={size}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect
        {...muiSelectProps}
        ref={ref}
        labelId={labelId}
        label={label}
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  SelectWithoutRef,
);
