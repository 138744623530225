import {
  GridColTypeDef,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { CopyText } from 'src/shared/typography/copy-text';

export function useCopyColumn(): GridColTypeDef {
  return {
    type: 'string',
    width: 100,
    renderCell: (
      params: GridRenderCellParams<GridValidRowModel, string>,
    ): React.JSX.Element | null =>
      params.formattedValue ? (
        <CopyText maxWidth={params.colDef.computedWidth}>
          {params.formattedValue}
        </CopyText>
      ) : null,
  };
}
