import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { isActionWithPayloadDataModel } from '../helper/is-action-with-payload-data-model';
import { HttpStatusEnum } from '../model/enum/http-status.enum';
import { disconnect } from '../authentication/authentication.slice';

export const unauthorizedMiddleware: Middleware =
  (store) => (next) => (action: unknown) => {
    if (
      isRejectedWithValue(action) &&
      isActionWithPayloadDataModel(action) &&
      action.payload.status === HttpStatusEnum.Unauthorized
    ) {
      store.dispatch(disconnect());
    }

    return next(action);
  };
