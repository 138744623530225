import {
  CURRENT_LOCALE_LSK,
  JWT_LSK,
  KEEP_MENU_OPEN_LSK,
} from 'src/config/storage-keys';
import {
  getFromLocalStorage,
  removeInLocalStorage,
  setInLocalStorage,
} from 'src/core/storage/storage.utils';
import { SupportedLocaleEnum } from 'src/core/model/enum/supported-locale.enum';

export abstract class InAppStorage {
  public static get jwt(): string | null {
    return getFromLocalStorage<string | null>(JWT_LSK, null);
  }

  public static set jwt(value: string | null) {
    if (value === null) {
      removeInLocalStorage(JWT_LSK);
      return;
    }
    setInLocalStorage(JWT_LSK, value);
  }

  public static get keepMenuOpen(): boolean {
    return getFromLocalStorage<boolean>(KEEP_MENU_OPEN_LSK, true);
  }

  public static set keepMenuOpen(value: boolean) {
    setInLocalStorage(KEEP_MENU_OPEN_LSK, value.toString());
  }

  public static get locale(): SupportedLocaleEnum | null {
    return getFromLocalStorage<string | null>(
      CURRENT_LOCALE_LSK,
      null,
    ) as SupportedLocaleEnum;
  }

  public static set locale(value: SupportedLocaleEnum | null) {
    if (value === null) {
      removeInLocalStorage(CURRENT_LOCALE_LSK);
      return;
    }
    setInLocalStorage(CURRENT_LOCALE_LSK, value);
  }
}
