import { ADMIN_ENDPOINT } from 'src/config/api-routes';
import { useLayoutEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiButton from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useDialog } from 'src/core/helper/use-dialog';
import { useSetPageActionContext } from 'src/shared/layout/page-layout/page-layout.context-provider';
import { useTranslation } from 'react-i18next';
import { UserResponseModel } from 'src/core/model/user.model';
import { UserDataGrid } from '../../generic-user/user-data-grid/user.data-grid';
import { AdministratorUpdate } from '../administrator-update/administrator-update';
import { CreateAdministrator } from '../administrator-create/administrator-create';

export function AdministratorList(): React.JSX.Element {
  const {
    isOpen: isCreateDialogOpen,
    onOpen: onOpenCreateDialog,
    onClose: onCloseCreateDialog,
  } = useDialog();
  const [userUpdate, setUserUpdate] = useState<UserResponseModel | null>(null);

  const { t } = useTranslation();
  const setPageAction = useSetPageActionContext();

  const handleCloseUserUpdateDialog = () => {
    setUserUpdate(null);
  };

  useLayoutEffect(() => {
    const createAdministratorButton = (
      <MuiButton
        key="create-administrator"
        onClick={onOpenCreateDialog}
        variant="contained">
        <AddOutlinedIcon />
        {t('form.create-button')}
      </MuiButton>
    );

    setPageAction([createAdministratorButton]);
  }, [t, onOpenCreateDialog, setPageAction]);

  return (
    <>
      <UserDataGrid endpoint={ADMIN_ENDPOINT} onUpdateUser={setUserUpdate} />

      <Dialog open={isCreateDialogOpen} onClose={onCloseCreateDialog}>
        <CreateAdministrator
          onCancel={onCloseCreateDialog}
          onSuccess={onCloseCreateDialog}
        />
      </Dialog>

      <Dialog open={!!userUpdate} onClose={handleCloseUserUpdateDialog}>
        {userUpdate && (
          <AdministratorUpdate
            user={userUpdate}
            onCancel={handleCloseUserUpdateDialog}
            onSuccess={handleCloseUserUpdateDialog}
          />
        )}
      </Dialog>
    </>
  );
}
