import React from 'react';
import { Navigate } from 'react-router-dom';
import { APP_PATH } from 'src/config/app-paths';
import { getUserId } from 'src/core/authentication/authentication.slice';
import { useAppSelector } from 'src/core/helper/redux.hook';
import { WithUserIdProps } from 'src/core/model/routing-configuration.model';

type ComponentProps<T> = Omit<T, keyof WithUserIdProps>;

export function WithUserId<T extends WithUserIdProps = WithUserIdProps>(
  WrappedComponent: React.ComponentType<T>,
): (props: ComponentProps<T>) => React.JSX.Element {
  const ComponentWithChargePointId = (
    props: ComponentProps<T>,
  ): React.JSX.Element => {
    const userId: string | null = useAppSelector(getUserId);

    if (!userId) {
      return <Navigate to={APP_PATH.LOGIN} />;
    }

    return <WrappedComponent {...(props as T)} userId={userId} />;
  };

  return ComponentWithChargePointId;
}
