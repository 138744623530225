import { Logger } from 'src/core/logger/logger';

const PRODUCTION_ENV = 'production';

export abstract class ConfigurationService {
  static getApi(): string {
    return process.env.DELMONICOS_API ?? '';
  }

  static isProduction(): boolean {
    return process.env.NODE_ENV === PRODUCTION_ENV;
  }

  static getVersion(): string {
    return process.env.VERSION ?? '';
  }

  static toJSON(): Record<string, string> {
    return {
      isProduction: this.isProduction().toString(),
      api: this.getApi(),
      version: this.getVersion(),
      logLevel: Logger.getLogLevel().toString(),
    };
  }
}
