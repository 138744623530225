/* eslint-disable react/destructuring-assignment */
import { ReactNode } from 'react';
import {
  FilterDefinitionModel,
  FilterQueryModel,
  FilterValueModel,
} from 'src/core/model/data-grid.model';
import { FilterChip } from '../active-filter/filter-chip';

type UseSimpleChipProps = {
  label: string;
  field: string;
  valueFormatter?: (value: FilterValueModel) => string;
};

export function useSimpleChip(
  props: UseSimpleChipProps,
): FilterDefinitionModel['getChip'] {
  const { label, field, valueFormatter = (value) => value.toString() } = props;

  return (filterQuery: FilterQueryModel, onDelete): ReactNode => {
    if (!filterQuery[field]) {
      return null;
    }

    return (
      <FilterChip
        key={field}
        label={`${label} = ${valueFormatter(filterQuery[field])}`}
        onDelete={() => onDelete(field)}
      />
    );
  };
}
