import { GridColTypeDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNumberColumn } from './use-number-column';

export function useEnergyDeliveredColumn(): GridColTypeDef {
  const { t } = useTranslation();

  const numberColumn = useNumberColumn();

  return {
    ...numberColumn,
    width: 120,
    valueFormatter: (
      params: GridValueFormatterParams<number | null>,
    ): string =>
      params.value !== null
        ? t('data-grid.row.energy-delivered', {
            value: params.value,
          })
        : '-',
  };
}
