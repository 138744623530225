import { GridColTypeDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactNode } from 'react';
import { EmailText } from 'src/shared/typography/email-text';

type RenderEmailCellProps = {
  readonly emailAddress?: string;
};

function RenderEmailCell(props: RenderEmailCellProps): ReactNode {
  const { emailAddress } = props;

  if (!emailAddress) {
    return null;
  }

  return <EmailText maxWidth={200} to={emailAddress} />;
}

export function useEmailColumn(): GridColTypeDef {
  return {
    width: 200,
    type: 'string',
    renderCell: (params: GridRenderCellParams): React.JSX.Element => (
      <RenderEmailCell emailAddress={params.formattedValue} />
    ),
  };
}
