export enum ChargingSessionStatusEnum {
  SESSION_INVALID = 0,
  START_CHARGE_CPO_ACCEPTED = 1,
  START_CHARGE_CPO_REFUSED = 2,
  START_CHARGE_CP_TIMEOUT = 3,
  START_CHARGE_CP_ACCEPTED = 4,
  START_CHARGE_CP_REFUSED = 5,
  SESSION_START_TIMEOUT = 6,
  SESSION_STARTED = 7,
  SESSION_PENDING = 8,
  STOP_CHARGE_CPO_ACCEPTED = 9,
  STOP_CHARGE_CPO_REFUSED = 10,
  STOP_CHARGE_CP_TIMEOUT = 11,
  STOP_CHARGE_CP_ACCEPTED = 12,
  STOP_CHARGE_CP_REFUSED = 13,
  SESSION_STOP_TIMEOUT = 14,
  SESSION_ENDED = 15,
  CDR_EDITED = 16,
}

export enum ChargingSessionsFilterStatusEnum {
  isPending = 'isPending',
  isTerminated = 'isTerminated',
}
