import { OcpiEvseModel } from 'src/core/model/ocpi.model';
import { Autocomplete, AutocompleteProps } from 'src/shared/form/autocomplete';

type Props = Pick<
  AutocompleteProps<OcpiEvseModel>,
  'onChange' | 'label' | 'errorMessage' | 'isTouched'
> & {
  readonly evseList: OcpiEvseModel[];
};

export function EvseSelect(props: Props): React.JSX.Element {
  const { evseList, ...autocompleteProps } = props;

  return (
    <Autocomplete
      disabled={evseList.length === 0}
      options={evseList}
      isOptionEqualToValue={(option, value) => option.evse_id === value.evse_id}
      getOptionLabel={(option) => option.evse_id}
      {...autocompleteProps}
    />
  );
}
