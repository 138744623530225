import {
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { APP_PATH } from 'src/config/app-paths';
import { ChargingSessionResponseModel } from 'src/core/model/charging-session.model';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export type ChargingSessionSectionProps = {
  readonly selectedId?: string;
  readonly list: ChargingSessionResponseModel[];
  readonly onSelect: (order: ChargingSessionResponseModel) => void;
};

export function ChargingSessionSection(
  props: ChargingSessionSectionProps,
): React.JSX.Element {
  const { selectedId, list, onSelect } = props;
  const { t } = useTranslation();

  const chargingSessionTranslations: Record<
    keyof ChargingSessionResponseModel,
    string
  > = t('model.charging-session', {
    returnObjects: true,
  });

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        {t('quarter-overview.charging-session-section.title', {
          count: list.length,
        })}
      </Typography>

      {list.length > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{chargingSessionTranslations.locationName}</TableCell>
              <TableCell>{chargingSessionTranslations.locationCity}</TableCell>
              <TableCell>{chargingSessionTranslations.startedAt}</TableCell>
              <TableCell>{chargingSessionTranslations.stoppedAt}</TableCell>
              <TableCell align="right">
                {chargingSessionTranslations.energyDelivered}
              </TableCell>
              <TableCell align="right">
                {chargingSessionTranslations.totalHT}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {list.map((chargingSession) => (
              <TableRow
                key={chargingSession.id}
                selected={selectedId === chargingSession.id}>
                <TableCell>
                  <Radio
                    checked={selectedId === chargingSession.id}
                    onChange={() => onSelect(chargingSession)}
                  />
                </TableCell>

                <TableCell>{chargingSession.locationName}</TableCell>
                <TableCell>{chargingSession.locationCity}</TableCell>

                <TableCell>
                  {t('data-grid.row.date', {
                    value: chargingSession.startedAt,
                  })}
                </TableCell>

                <TableCell>
                  {t('data-grid.row.date', {
                    value: chargingSession.stoppedAt,
                  })}
                </TableCell>

                <TableCell align="right">
                  {t('data-grid.row.energy-delivered', {
                    value: chargingSession.energyDelivered,
                  })}
                </TableCell>

                <TableCell align="right">
                  {t('data-grid.row.currency', {
                    value: chargingSession.totalHT,
                  })}
                </TableCell>

                <TableCell>
                  <IconButton
                    component={RouterLink}
                    to={generatePath(APP_PATH.CHARGING_SESSION_DETAIL, {
                      chargingSessionId: chargingSession.id,
                    })}
                    target="_blank">
                    <OpenInNewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
