import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertProps } from '@mui/material';

import { CustomerUnlockButton } from './customer-unlock.button';

type CustomerLockAlertProps = Omit<AlertProps, 'severity' | 'action'> & {
  customerId?: string;
  remainingLockTime?: number;
  onRemainingLockTimeout: () => void;
};

export function CustomerLockAlert(
  props: CustomerLockAlertProps,
): React.JSX.Element | null {
  const {
    customerId,
    remainingLockTime = 0,
    onRemainingLockTimeout,
    ...alertProps
  } = props;
  const { t } = useTranslation();

  const [lockTime, setLockTime] = useState(remainingLockTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLockTime((value) => Math.max(value - 1, 0));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (remainingLockTime > 0 && lockTime === 0) {
      onRemainingLockTimeout();
    }
  }, [remainingLockTime, lockTime, onRemainingLockTimeout]);

  return (
    <Alert
      {...alertProps}
      severity="warning"
      action={
        <CustomerUnlockButton
          color="inherit"
          size="small"
          customerId={customerId}
        />
      }>
      {t('customer.detail.lock-warning', {
        count: lockTime,
      })}
    </Alert>
  );
}
