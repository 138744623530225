import { useState } from 'react';
import { TariffModel } from 'src/core/model/tariff.model';
import JsonView from 'react18-json-view';
import { TariffDimensionTypeEnum } from 'src/core/model/enum/tariff-dimension-type.enum';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { TariffForm } from '../tariff-form/tariff.form';

export function TariffDemo(): React.JSX.Element {
  const [tariff, setTariff] = useState<TariffModel>({
    tariffAltText: [
      {
        language: 'fr',
        text: 'Forfait 4,5€ pour 20 kWh chargés non divisibles.',
      },
      {
        language: 'en',
        text: 'Flat rate of €4.5 for 20 undividable kWh.',
      },
    ],
    elements: [
      {
        priceComponents: [
          { type: TariffDimensionTypeEnum.ENERGY, price: 0.38, stepSize: 1 },
        ],
        restrictions: {
          startTime: '06:00',
          endTime: '22:00',
        },
      },
      {
        priceComponents: [
          { type: TariffDimensionTypeEnum.FLAT, price: 4.5, stepSize: 1 },
        ],
        restrictions: {
          minKwh: 20,
        },
      },
      {
        priceComponents: [
          { type: TariffDimensionTypeEnum.ENERGY, price: 0.33, stepSize: 1 },
        ],
      },
    ],
  });

  return (
    <Stack direction={{ md: 'column', lg: 'row' }} gap={8}>
      <Box sx={{ flex: { md: 'unset', lg: 1 } }}>
        <TariffForm onSubmit={setTariff} defaultValues={tariff} />
      </Box>

      <Divider orientation="vertical" />

      <Box sx={{ flex: { md: 'unset', lg: 1 } }}>
        <Typography variant="h5">Résultat</Typography>
        <JsonView src={tariff} />
      </Box>
    </Stack>
  );
}
