import Avatar from '@mui/material/Avatar';
import Card, { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { useTranslation } from 'react-i18next';
import {
  CardContent,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { InvoicedProductModel } from 'src/core/model/order.model';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { InvoiceTableBody } from './invoice.table-body';

type InvoiceCardProps = CardProps & {
  invoiceId?: string;
  articleList?: InvoicedProductModel[];
  totalHT?: number;
  totalTTC?: number;
  vatRate?: number;
  isPending?: boolean;
};

export function InvoiceCard(
  props: Readonly<InvoiceCardProps>,
): React.JSX.Element {
  const {
    invoiceId,
    articleList = [],
    totalHT = 0,
    totalTTC = 0,
    vatRate = 0.2,
    isPending = false,
    sx,
    ...cardProps
  } = props;

  const { t } = useTranslation();

  return (
    <Card sx={{ overflow: 'unset', ...sx }} {...cardProps}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary.light' }}>
            <RequestQuoteIcon color="primary" />
          </Avatar>
        }
        title={t('invoice.card.title')}
        subheader={invoiceId}
      />

      <TableContainer component={CardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('invoice.card.header.item')}</TableCell>
              <TableCell align="right">
                {t('invoice.card.header.quantity')}
              </TableCell>
              <TableCell align="right">
                {t('invoice.card.header.invoicedQuantity')}
              </TableCell>
              <TableCell align="right">
                {t('invoice.card.header.priceHT')}
              </TableCell>
              <TableCell align="right">
                {t('invoice.card.header.totalHT')}
              </TableCell>
            </TableRow>
          </TableHead>

          <InvoiceTableBody articleList={articleList} isPending={isPending} />

          {!isPending && articleList.length > 0 && (
            <TableFooter>
              <TableRow>
                <TableCell rowSpan={3} colSpan={2} />
                <TableCell colSpan={2}>
                  {t('invoice.card.footer.subTotal')}
                </TableCell>
                <TableCell align="right">
                  {t('invoice.card.price', { value: totalHT })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('invoice.card.footer.vat')}</TableCell>
                <TableCell align="right">
                  {t('invoice.card.footer.vatRate', { value: vatRate })}
                </TableCell>
                <TableCell align="right">
                  {t('invoice.card.price', {
                    value: totalTTC - totalHT,
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  {t('invoice.card.footer.total')}
                </TableCell>
                <TableCell align="right">
                  {t('invoice.card.price', { value: totalTTC })}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Card>
  );
}
