import { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LogModel } from 'src/core/model/log.model';
import { useDatetimeColumn } from 'src/feature/data-grid/column/use-datetime-column';
import { useJsonColumn } from 'src/feature/data-grid/column/use-json-column';
import { useTextColumn } from 'src/feature/data-grid/column/use-text-column';
import { useUidColumn } from 'src/feature/data-grid/column/use-uid-column';

export function useGenericLogColumn(): GridColDef[] {
  const { t } = useTranslation();

  const datetimeColumn = useDatetimeColumn();
  const textColumn = useTextColumn();
  const uidColumn = useUidColumn();
  const jsonColumn = useJsonColumn();

  const logTranslations: Record<keyof LogModel, string> = t('model.log', {
    returnObjects: true,
  });

  return useMemo(
    () => [
      {
        ...uidColumn,
        field: 'eventId',
        width: 120,
        headerName: logTranslations.eventId,
      },
      {
        ...datetimeColumn,
        field: 'eventDate',
        headerName: logTranslations.eventDate,
      },
      {
        ...textColumn,
        field: 'indexName',
        width: 160,
        headerName: logTranslations.indexName,
      },
      {
        ...jsonColumn,
        field: 'payload',
        headerName: logTranslations.payload,
        sortable: false,
      },
    ],
    [datetimeColumn, textColumn, uidColumn, jsonColumn, logTranslations],
  );
}
