import Box from '@mui/material/Box';

type ImageHeroProps = {
  readonly alt: string;
  readonly src: string;
};

export function ImageHero(props: ImageHeroProps): React.JSX.Element {
  const { alt, src } = props;

  return (
    <Box
      sx={(theme) => ({
        padding: 8,
        background: theme.palette.grey['50'],
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}>
      <img src={src} alt={alt} />
    </Box>
  );
}
