import { useTranslation } from 'react-i18next';
import { ConnectorTypeEnum } from 'src/core/model/enum/connector-type.enum';
import { PowerTypeEnum } from 'src/core/model/enum/power-type.enum';
import { OcpiConnectorModel } from 'src/core/model/ocpi.model';
import { Autocomplete, AutocompleteProps } from 'src/shared/form/autocomplete';

type Props = Pick<
  AutocompleteProps<OcpiConnectorModel>,
  'onChange' | 'label' | 'errorMessage' | 'isTouched'
> & {
  readonly connectorList: OcpiConnectorModel[];
};

export function ConnectorSelect(props: Props): React.JSX.Element {
  const { connectorList, ...autocompleteProps } = props;
  const { t } = useTranslation();

  const getPowerTypeText = (powerType: PowerTypeEnum) =>
    t(`model.enum.power-type.${powerType}`);

  const getConnectorTypeText = (connectorType: ConnectorTypeEnum) =>
    t(`model.enum.connector-type.${connectorType}`);

  return (
    <Autocomplete
      {...autocompleteProps}
      disabled={connectorList.length === 0}
      options={connectorList}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        `${getPowerTypeText(option.power_type)} - ${getConnectorTypeText(
          option.standard,
        )}`
      }
    />
  );
}
