import { useGetListCustomerQuery } from 'src/core/service/customer.service';
import { DataGrid } from 'src/feature/data-grid/data-grid';
import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useTranslation } from 'react-i18next';

import { useDataGridSearchParams } from '../../data-grid/use-data-grid-search-params/use-data-grid-search-params';
import { useCustomerColumn } from './use-customer-column';
import { useCustomerFilter } from './use-customer-filter';

export function CustomerDataGrid(): React.JSX.Element {
  const columns = useCustomerColumn();
  const filterDefinitionModel = useCustomerFilter();
  const { model, onModelChange } = useDataGridSearchParams({
    ...DEFAULT_DATA_GRID_MODEL,
    sort: [
      {
        field: 'lastConnection',
        sort: 'desc',
      },
    ],
  });

  const { t } = useTranslation();
  const {
    data: paginatedData,
    isFetching,
    isError,
  } = useGetListCustomerQuery(model);

  return (
    <>
      <DataGrid
        columns={columns}
        filterDefinitionModel={filterDefinitionModel}
        loading={isFetching}
        rows={paginatedData?.docs ?? []}
        rowCount={paginatedData?.totalDocs ?? 0}
        model={model}
        onModelChange={onModelChange}
      />

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.get-list-customer-query')}
        />
      )}
    </>
  );
}
