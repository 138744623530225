import { RoutingConfigurationModel } from '../model/routing-configuration.model';

export function flatRoutingConfiguration(
  routingConfig: RoutingConfigurationModel[],
): RoutingConfigurationModel[] {
  const flatResult: RoutingConfigurationModel[] = [];

  routingConfig.forEach((entry: RoutingConfigurationModel): void => {
    flatResult.push(entry);

    if (entry.items) {
      const childrenConfiguration = flatRoutingConfiguration(entry.items);
      flatResult.push(...childrenConfiguration);
    }
  });

  return flatResult;
}
