import {
  useLoginMutation,
  useSendOtpMutation,
} from 'src/core/service/login.service';

import { EmailPasswordForm } from './use-email-password.form-validation';
import { EmailPasswordStepView } from './email-password-step.view';

type EmailPasswordStepControllerProps = {
  readonly onNext: (emailAddress: string) => void;
};

export function EmailPasswordStepController(
  props: EmailPasswordStepControllerProps,
): React.JSX.Element {
  const { onNext } = props;
  const [login, { error: loginError, isLoading: isLoginLoading }] =
    useLoginMutation();
  const [sendOtp, { error: otpError, isLoading: isOtpLoading }] =
    useSendOtpMutation();

  async function handleLogin(payload: EmailPasswordForm): Promise<void> {
    const resultLogin = await login(payload);

    if (!('data' in resultLogin)) {
      return;
    }

    const resultSendOtp = await sendOtp({
      emailAddress: payload.emailAddress,
      token: resultLogin.data.access_token,
    });

    if (!('data' in resultSendOtp)) {
      return;
    }

    onNext(payload.emailAddress);
  }

  return (
    <EmailPasswordStepView
      pending={isLoginLoading || isOtpLoading}
      isError={!!loginError || !!otpError}
      onSubmit={handleLogin}
    />
  );
}
