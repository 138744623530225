import { Navigate, Outlet } from 'react-router-dom';
import { APP_PATH } from 'src/config/app-paths';
import { isAuthenticated } from 'src/core/authentication/authentication.slice';
import { useAppSelector } from 'src/core/helper/redux.hook';

export function AuthenticatedController(): React.JSX.Element {
  const isUserAuthenticated = useAppSelector(isAuthenticated);

  if (!isUserAuthenticated) {
    return <Navigate to={APP_PATH.LOGIN} />;
  }

  return <Outlet />;
}
