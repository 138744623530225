import { IconButton, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { LevelTypography } from './level-typography';

type Props = {
  readonly title: string;
  readonly onRemove?: () => void;
  readonly level?: number;
};

export function FieldArrayFormContainer(
  props: PropsWithChildren<Props>,
): React.JSX.Element {
  const { title, onRemove, children, level } = props;

  return (
    <Stack direction="column" pl={2}>
      <Stack direction="row" justifyContent="space-between">
        <LevelTypography level={level}>{title}</LevelTypography>

        {onRemove && (
          <IconButton onClick={onRemove}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>

      {children}
    </Stack>
  );
}
