import { isObject } from 'radash';
import {
  OCPI_FILTERABLE_FIELDS,
  OCPI_FILTERABLE_FIELD_KEY,
} from 'src/config/app.config';
import {
  DataGridQueryModel,
  FilterQueryModel,
  FilterValueModel,
} from '../model/data-grid.model';

export function groupOcpiFilters(
  flatFilters: FilterQueryModel,
): FilterQueryModel {
  return Object.entries(flatFilters).reduce(
    (
      accumulator: FilterQueryModel,
      [filterKey, filterValue]: [string, FilterValueModel],
    ) => {
      const nextAccumulator: FilterQueryModel = { ...accumulator };
      const isKeyInOcpiFilter = OCPI_FILTERABLE_FIELDS.includes(filterKey);

      if (isKeyInOcpiFilter) {
        (nextAccumulator[OCPI_FILTERABLE_FIELD_KEY] as FilterQueryModel)[
          filterKey
        ] = filterValue;
      } else {
        nextAccumulator[filterKey] = filterValue;
      }

      return nextAccumulator;
    },
    { [OCPI_FILTERABLE_FIELD_KEY]: {} } as FilterQueryModel,
  );
}

export function mapDataGridQueryModelToApiQuery(
  params: DataGridQueryModel,
): URLSearchParams {
  const searchParams = new URLSearchParams();

  if (params.paginate && Object.keys(params.paginate).length) {
    searchParams.set(
      'offset',
      (params.paginate.page * params.paginate.pageSize).toString(),
    );
    searchParams.set('limit', params.paginate.pageSize.toString());
  }

  if (params?.sort?.length) {
    const sortItem = params.sort[0];
    searchParams.set('sortField', sortItem.field);
    searchParams.set('sortOrder', sortItem.sort === 'desc' ? '-1' : '1');
  }

  if (params.textSearch) {
    searchParams.set('textSearch', params.textSearch);
  }

  if (params.filter && Object.keys(params.filter).length) {
    Object.entries(params.filter).forEach(([key, value]) => {
      searchParams.set(
        key,
        isObject(value) ? JSON.stringify(value) : value.toString(),
      );
    });
  }

  return searchParams;
}
