import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material';

function SkeletonItem(): React.JSX.Element {
  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      </ListItemIcon>

      <ListItemText>
        <Skeleton />
      </ListItemText>
    </ListItem>
  );
}

type SkeletonListProps = {
  length?: number;
};

export function SkeletonList(props: SkeletonListProps): React.JSX.Element[] {
  const { length = 4 } = props;

  return Array.from({ length }, (_, index) => index).map((item) => (
    <SkeletonItem key={item} />
  ));
}
