import { useVerifyOtpMutation } from 'src/core/service/login.service';

import { EmailOtpStepView } from './email-otp-step.view';
import { EmailOtpForm } from './use-email-otp.form-validation';

export type EmailOtpStepControllerProps = {
  readonly emailAddress?: string;
  readonly onNext: (jwt: string) => void;
};

export function EmailOtpStepController(
  props: EmailOtpStepControllerProps,
): React.JSX.Element {
  const { emailAddress, onNext } = props;
  const [verifyOtp, { isLoading, error }] = useVerifyOtpMutation();

  async function handleLogin(loginPayload: EmailOtpForm): Promise<void> {
    const result = await verifyOtp(loginPayload);

    if ('data' in result) {
      onNext(result.data.access_token);
    }
  }

  return (
    <EmailOtpStepView
      pending={isLoading}
      emailAddress={emailAddress}
      onSubmit={handleLogin}
      isError={!!error}
    />
  );
}
