import {
  FilterDefinitionModel,
  FilterFormItemProps,
  FilterValueModel,
} from 'src/core/model/data-grid.model';
import { Slider } from '@mui/material';

import { FilterItem } from './filter-item';
import { useRangeChip } from './use-range-chip';

type UseNumberRangeFilterProps = {
  label: string;
  keyMin: string;
  keyMax: string;
  min?: number;
  max?: number;
  valueFormatter?: (value: FilterValueModel) => string;
};

export function useNumberRangeFilter(
  hookProps: UseNumberRangeFilterProps,
): FilterDefinitionModel {
  const {
    label,
    keyMin,
    keyMax,
    min = 0,
    max = 100,
    valueFormatter = (value: FilterValueModel): string => value.toString(),
  } = hookProps;

  const getRangeChip = useRangeChip({ label, keyMin, keyMax, valueFormatter });

  return {
    getFilter: (props: FilterFormItemProps) => {
      const { values, setValues } = props;

      return (
        <FilterItem key={`${keyMin}-${keyMax}`} label={label}>
          <Slider
            value={[
              (values[keyMin] as number) ?? min,
              (values[keyMax] as number) ?? max,
            ]}
            valueLabelFormat={valueFormatter}
            onChange={(_, newValue: number | number[]) => {
              setValues((prev) => ({
                ...prev,
                [keyMin]: Math.min(...(newValue as number[])),
                [keyMax]: Math.max(...(newValue as number[])),
              }));
            }}
            valueLabelDisplay="auto"
            min={min}
            max={max}
          />
        </FilterItem>
      );
    },

    getChip: getRangeChip,
  };
}
