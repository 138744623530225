import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { I18N_DEFAULT_NS } from 'src/config/app.config';
import { Avatar } from 'src/shared/avatar/avatar';
import Typography from '@mui/material/Typography';

interface UserIdentityProps extends StackProps {
  readonly trigram?: string;
  readonly firstName?: string;
  readonly lastName?: string;
}

export function UserIdentity(props: UserIdentityProps): React.JSX.Element {
  const { trigram, firstName, lastName, sx, ...stackProps } = props;

  const { t } = useTranslation(I18N_DEFAULT_NS, {
    keyPrefix: 'dashboard.user-presentation.user-identity',
  });

  return (
    <Stack
      direction="row"
      sx={{ ...sx, alignItems: 'center', gap: 1, width: '100%' }}
      {...stackProps}>
      <Avatar size={60} trigram={trigram} />

      <Typography sx={{ fontWeight: 'bold', flex: 1 }}>
        {firstName && lastName ? (
          t('fullname', {
            firstName,
            lastName,
          })
        ) : (
          <Skeleton />
        )}
      </Typography>
    </Stack>
  );
}
