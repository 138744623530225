import { Alert, AlertColor, Snackbar as MuiSnackbar } from '@mui/material';
import { useState } from 'react';

type SnackbarProps = {
  message: string;
  key?: string;
  variant: AlertColor;
};

export function Snackbar(
  props: Readonly<SnackbarProps>,
): React.JSX.Element | null {
  const { message, key = message, variant } = props;

  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => setIsOpen(false);

  return (
    <MuiSnackbar
      key={key}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert severity={variant} onClose={handleClose}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
