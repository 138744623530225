export const APP_PATH = {
  /*
    Guest paths
*/
  LOGIN: '/login',

  /*
    Authenticated paths
*/
  DASHBOARD: '/',

  LIST_INVOICE: '/invoices',
  INVOICE_HTML: '/invoices/:invoiceId',

  LIST_ORDER: '/orders',

  LIST_CUSTOMER: '/customers',
  CUSTOMER_DETAIL: '/customers/:customerId',

  LIST_ADMINISTRATOR: '/administrator',
  CREATE_ADMINISTRATOR: '/administrator/create',

  LIST_OPERATOR: '/operator',
  CREATE_OPERATOR: '/operator/create',

  LIST_DEVELOPER: '/developer',
  CREATE_DEVELOPER: '/developer/create',

  LIST_CHARGING_SESSION: '/charging-session',
  CHARGING_SESSION_DETAIL: '/charging-session/:chargingSessionId',

  LIST_CHARGE_POINT: '/charge-point',

  LIST_LOG: '/logs',

  QUARTER_OVERVIEW: '/quarter-overview',

  LIST_TARIFF: '/tariffs',
};
