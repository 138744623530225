import { DataGrid } from 'src/feature/data-grid/data-grid';
import { useGetListInvoiceQuery } from 'src/core/service/invoice.service';
import { useDataGridSearchParams } from 'src/feature/data-grid/use-data-grid-search-params/use-data-grid-search-params';
import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';
import { useTranslation } from 'react-i18next';
import { Snackbar } from 'src/shared/snackbar/snackbar';

import { useInvoiceColumn } from './use-invoice-column';
import { useInvoiceFilter } from './use-invoice-filter';

export function InvoiceDataGrid(): React.JSX.Element {
  const columns = useInvoiceColumn();
  const filterDefinition = useInvoiceFilter();

  const { model, onModelChange } = useDataGridSearchParams({
    ...DEFAULT_DATA_GRID_MODEL,
    sort: [
      {
        field: 'createdAt',
        sort: 'desc',
      },
    ],
  });

  const { t } = useTranslation();
  const {
    data: paginatedData,
    isFetching,
    isError,
  } = useGetListInvoiceQuery(model);

  return (
    <>
      <DataGrid
        columns={columns}
        filterDefinitionModel={filterDefinition}
        loading={isFetching}
        rows={paginatedData?.docs ?? []}
        rowCount={paginatedData?.totalDocs ?? 0}
        model={model}
        onModelChange={onModelChange}
        initialState={{
          columns: {
            columnVisibilityModel: {
              quoteId: false,
              orderId: false,
              sessionId: false,
            },
          },
        }}
      />

      {isError && (
        <Snackbar variant="error" message={t('error.get-list-invoice-query')} />
      )}
    </>
  );
}
