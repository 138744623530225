import { Button, Collapse, Stack } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LevelTypography } from './level-typography';

type Props = {
  readonly title: string;
  readonly level?: number;
  readonly hideText?: string;
  readonly showText?: string;
  readonly canHide?: boolean;
};

export function FormSection(
  props: PropsWithChildren<Props>,
): React.JSX.Element {
  const { t } = useTranslation();
  const {
    title,
    children,
    level,
    canHide = true,
    hideText = t('tariff.form.hide'),
    showText = t('tariff.form.show'),
  } = props;
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between">
        <LevelTypography level={level}>{title}</LevelTypography>

        {canHide && isOpen && (
          <Button onClick={() => setIsOpen(false)}>{hideText}</Button>
        )}
      </Stack>

      <Collapse in={isOpen}>
        <Stack
          direction="column"
          gap={3}
          pl={2}
          pt={2}
          ml={1}
          sx={(theme) => ({
            borderLeft: `3px solid ${theme.palette.divider}`,
          })}>
          {children}
        </Stack>
      </Collapse>

      {canHide && !isOpen && (
        <Button sx={{ mt: 2 }} onClick={() => setIsOpen(true)}>
          {showText}
        </Button>
      )}
    </Stack>
  );
}
