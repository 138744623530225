export function sendBeacon(url: string, payload: string): void {
  const endpoint = new URL(url, process.env.DELMONICOS_API ?? '');

  if (navigator.sendBeacon) {
    navigator.sendBeacon(endpoint, payload);
    return;
  }

  fetch(endpoint.toString(), {
    body: payload,
    method: 'POST',
    keepalive: true,
  });
}
