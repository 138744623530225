import * as React from 'react';
import { StackProps, Stack } from '@mui/material';

export function RowStack(props: StackProps): React.JSX.Element {
  const {
    gap = 1,
    direction = 'row',
    alignItems = 'center',
    sx,
    ...stackProps
  } = props;
  return (
    <Stack
      gap={gap}
      direction={direction}
      alignItems={alignItems}
      sx={{ width: '100%', ...sx }}
      {...stackProps}
    />
  );
}
