import { Typography, TypographyProps } from '@mui/material';

type Props = Omit<TypographyProps, 'variant'> & { level?: number };

const mapLevelToVariant: TypographyProps['variant'][] = [
  'h5',
  'h6',
  'body1',
  'body2',
];

export function LevelTypography(props: Readonly<Props>): React.JSX.Element {
  const { level = 0, ...typographyProps } = props;
  return (
    <Typography
      {...typographyProps}
      variant={mapLevelToVariant[level] ?? 'body1'}
    />
  );
}
