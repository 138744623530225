import { useCallback, useState } from 'react';
import {
  CheckoutFeeModel,
  EnhancedQuarterOverviewModel,
  QuarterOverviewModel,
} from 'src/core/model/quarter-overview.model';
import { useEnhanceWithOrder } from './use-enhance-with-order';
import { useEnhanceWithCheckout } from './use-enhance-with-checkout';
import { OrderSection } from './order-section';
import { CheckoutSection } from './checkout-section';
import { CollapsibleQuarterOverviewTableRow } from '../quarter-overview-table/collapsible-quarter-overview.table-row';
import { useEnhanceWithChargingSession } from './use-enhance-with-charging-session';
import { ChargingSessionSection } from './charging-session-section';

type Props = {
  readonly quarterOverview: QuarterOverviewModel;
  readonly rawCheckoutList?: CheckoutFeeModel[];
  readonly onChange: (data: Partial<EnhancedQuarterOverviewModel>) => void;
};

export function EnhanceQuarterOverview(props: Props): React.JSX.Element {
  const { rawCheckoutList, onChange, quarterOverview } = props;

  const [iTransactionId, setITransactionId] = useState<string>();

  const handleOrderChange = useCallback(
    (
      payload: Partial<EnhancedQuarterOverviewModel>,
      orderITransactionId: string,
    ) => {
      setITransactionId(orderITransactionId);
      onChange(payload);
    },
    [onChange],
  );

  const { state: orderState, ...orderProps } = useEnhanceWithOrder({
    sessionId: quarterOverview.id,
    onChange: handleOrderChange,
  });

  const { state: chargingSessionState, ...chargingSessionProps } =
    useEnhanceWithChargingSession({
      sessionId: quarterOverview.id,
      onChange,
    });

  const { state: checkoutState, ...checkoutProps } = useEnhanceWithCheckout({
    rawCheckoutList,
    iTransactionId,
    onChange,
  });

  return (
    <CollapsibleQuarterOverviewTableRow
      {...quarterOverview}
      orderState={orderState}
      chargingSessionState={chargingSessionState}
      checkoutState={checkoutState}>
      <OrderSection {...orderProps} />
      <ChargingSessionSection {...chargingSessionProps} />
      <CheckoutSection {...checkoutProps} />
    </CollapsibleQuarterOverviewTableRow>
  );
}
