import chademo from 'src/asset/power-chademo.svg';
import typeE from 'src/asset/power-type-e.svg';
import type1 from 'src/asset/power-type-1.svg';
import type1ccs from 'src/asset/power-type-1-ccs.svg';
import type2 from 'src/asset/power-type-2.svg';
import type2ccs from 'src/asset/power-type-2-ccs.svg';
import unknown from 'src/asset/power-domestic.svg';

export const ConnectorAssetConfig: Record<string, string> = {
  CHADEMO: chademo,
  DOMESTIC_E: typeE,
  IEC_62196_T1: type1,
  IEC_62196_T1_COMBO: type1ccs,
  IEC_62196_T2: type2,
  IEC_62196_T2_COMBO: type2ccs,
  unknown,
};

export const DEFAULT_CONNECTOR_ASSET = 'unknown';
