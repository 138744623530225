import { useTranslation } from 'react-i18next';
import { RoleEnum } from 'src/core/model/enum/role.enum';

type RoleTextHookReturn = (role?: RoleEnum) => string;

export function useGetRole(): RoleTextHookReturn {
  const { t } = useTranslation();

  return (role?: RoleEnum) =>
    t([`model.enum.role.${role}`, 'model.enum.role.unknown']);
}
