import { DataGrid } from 'src/feature/data-grid/data-grid';
import { useGetListChargingSessionQuery } from 'src/core/service/charging-session.service';
import { useDataGridSearchParams } from 'src/feature/data-grid/use-data-grid-search-params/use-data-grid-search-params';
import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { useTranslation } from 'react-i18next';

import { useChargingSessionColumn } from './use-charging-session-column';
import { useChargingSessionFilter } from './use-charging-session-filter';

export function ChargingSessionDataGrid(): React.JSX.Element {
  const columns = useChargingSessionColumn();
  const filterDefinitionModel = useChargingSessionFilter();
  const { model, onModelChange } = useDataGridSearchParams({
    ...DEFAULT_DATA_GRID_MODEL,
    sort: [
      {
        field: 'stoppedAt',
        sort: 'desc',
      },
    ],
  });
  const { t } = useTranslation();

  const {
    data: paginatedData,
    isFetching,
    isError,
  } = useGetListChargingSessionQuery(model);

  return (
    <>
      <DataGrid
        columns={columns}
        filterDefinitionModel={filterDefinitionModel}
        loading={isFetching}
        rows={paginatedData?.docs ?? []}
        rowCount={paginatedData?.totalDocs ?? 0}
        model={model}
        onModelChange={onModelChange}
      />
      {isError && (
        <Snackbar
          variant="error"
          message={t('error.get-list-charging-session-query')}
        />
      )}
    </>
  );
}
