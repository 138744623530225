import Link from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';

export function Copyright(props: TypographyProps): React.JSX.Element {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.delmonicos.tech/">
        Delmonicos
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
