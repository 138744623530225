import { useTranslation } from 'react-i18next';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Card from '@mui/material/Card';
import { SubTitle } from 'src/shared/typography/title';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import Divider from '@mui/material/Divider';
import { Avatar } from 'src/shared/avatar/avatar';
import { RowStack } from 'src/shared/row-stack/row-stack';
import { SxProps } from '@mui/material';
import { useGetCurrentUserSupervisorQuery } from 'src/core/service/current-user.service';
import Typography from '@mui/material/Typography';
import { EmailText } from 'src/shared/typography/email-text';
import { Snackbar } from 'src/shared/snackbar/snackbar';

type UserSupervisorProps = {
  readonly sx: SxProps;
};

export function UserSupervisor(props: UserSupervisorProps): React.JSX.Element {
  const { sx } = props;
  const { t } = useTranslation();

  const { data: supervisor, isError } = useGetCurrentUserSupervisorQuery();

  return (
    <>
      <Card
        elevation={1}
        sx={{
          p: 4,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        }}>
        <SubTitle icon={<SupervisorAccountOutlinedIcon />}>
          {t('dashboard.user-presentation.user-supervisor.title')}
        </SubTitle>

        <Divider />

        <RowStack>
          <Avatar trigram={supervisor?.trigram} />
          <Typography>
            {t('dashboard.user-presentation.user-supervisor.fullname', {
              firstName: supervisor?.firstName,
              lastName: supervisor?.lastName,
            })}
          </Typography>
        </RowStack>

        <RowStack>
          <EmailOutlinedIcon />
          {supervisor?.emailAddress && (
            <EmailText maxWidth="unset" to={supervisor.emailAddress} />
          )}
        </RowStack>
      </Card>

      {isError && (
        <Snackbar
          variant="error"
          message={t('error.get-current-user-supervisor-query')}
        />
      )}
    </>
  );
}
