import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetOneChargingSessionQuery } from 'src/core/service/charging-session.service';
import { useGetOneOrderQuery } from 'src/core/service/order.service';
import { ChargingSessionCard } from 'src/shared/charging-session-card/charging-session.card';
import { ChargingStatusCard } from 'src/shared/charging-status-card/charging-status.card';
import { OrderCustomerCard } from 'src/shared/customer-card/order-customer.card';
import { InvoiceCard } from 'src/shared/invoice-card/invoice.card';
import { Snackbar } from 'src/shared/snackbar/snackbar';

export function ChargingSessionDetail(): React.JSX.Element {
  const { chargingSessionId } = useParams();
  const { t } = useTranslation();

  const {
    data: chargingSession,
    isLoading: isLoadingChargingSession,
    isError: isChargingSessionError,
  } = useGetOneChargingSessionQuery(chargingSessionId ?? null, {
    skip: !chargingSessionId,
  });

  const {
    data: order,
    isLoading: isLoadingOrder,
    isError: isOrderError,
  } = useGetOneOrderQuery(chargingSession?.orderId ?? null, {
    skip: !chargingSession?.orderId,
  });

  const isPending = isLoadingChargingSession || isLoadingOrder;

  return (
    <>
      <ChargingSessionCard
        isPending={isPending}
        chargingSessionId={chargingSessionId}
        chargingDuration={order?.chargingTime}
        parkingDuration={order?.parkingTime}
        locationName={chargingSession?.locationName}
        locationCity={chargingSession?.locationCity}
        startedAt={chargingSession?.startedAt}
        stoppedAt={chargingSession?.stoppedAt}
        energyDelivered={chargingSession?.energyDelivered}
        sessionId={chargingSession?.sessionId}
        cpoId={chargingSession?.cpoId}
        tokenUid={chargingSession?.tokenUid}
        contractId={chargingSession?.contractId}
        evseUid={chargingSession?.evseUid}
        locationId={chargingSession?.locationId}
        connectorId={chargingSession?.connectorId}
      />

      <InvoiceCard
        invoiceId={order?.invoiceId}
        articleList={order?.invoicedProducts}
        totalHT={order?.totalHT}
        totalTTC={order?.totalTTC}
        vatRate={order?.vatRate}
      />

      <OrderCustomerCard
        isPending={isPending}
        lastName={order?.customerLastName}
        firstName={order?.customerFirstName}
        pseudo={order?.customerPseudo}
        email={order?.customerEmail}
      />

      <ChargingStatusCard
        orderStatusList={order?.statuses}
        chargingSessionStatusList={chargingSession?.status}
        isPending={isPending}
      />

      {isChargingSessionError && (
        <Snackbar
          variant="error"
          message={t('error.get-one-charging-session-query')}
        />
      )}

      {isOrderError && (
        <Snackbar variant="error" message={t('error.get-one-order-query')} />
      )}
    </>
  );
}
