import {
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

type InvoiceListItemProps = Omit<ListItemProps, 'children'> & {
  createdAt?: string;
  totalTTC?: number;
};

export function InvoiceListItem(
  props: Readonly<InvoiceListItemProps>,
): React.JSX.Element {
  const { createdAt, totalTTC, ...listItemProps } = props;
  const { t } = useTranslation();

  return (
    <ListItem {...listItemProps}>
      <ListItemIcon>
        <RequestQuoteIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          createdAt
            ? t('invoice.list-item.primary', {
                value: new Date(createdAt),
              })
            : t('invoice.card.fallback')
        }
        secondary={
          totalTTC
            ? t('invoice.list-item.secondary', {
                value: totalTTC,
              })
            : t('invoice.card.fallback')
        }
      />
    </ListItem>
  );
}
