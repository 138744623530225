import { FieldErrors, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/shared/button/button';
import { PriceComponentModel } from 'src/core/model/tariff.model';
import AddIcon from '@mui/icons-material/Add';
import { TariffDimensionTypeEnum } from 'src/core/model/enum/tariff-dimension-type.enum';
import { FieldArrayFormContainer } from '../component/field-array-form-container';
import { PriceComponentForm } from './price-component-form/price-component.form';
import { FormSection } from '../component/form-section';

const DEFAULT_PRICE_COMPONENT: PriceComponentModel = {
  type: TariffDimensionTypeEnum.ENERGY,
  price: 0,
  stepSize: 1,
};

type Props = {
  prefixName: string;
  errors?: FieldErrors<PriceComponentModel>[];
  touchedFields?: Partial<
    Readonly<{
      [x: string]: any;
    }>
  >;
  level?: number;
};

export function PriceComponentFormManager(
  props: Readonly<Props>,
): React.JSX.Element {
  const { prefixName, errors, touchedFields, level = 0 } = props;

  const { t } = useTranslation();
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: prefixName,
  });

  return (
    <FormSection
      level={level}
      title={t('tariff.form.tariff-element.price-component.title', {
        count: fields.length,
      })}
      showText={t('tariff.form.tariff-element.price-component.show', {
        count: fields.length,
      })}
      canHide={fields.length > 0}>
      {fields.map((field, index) => (
        <FieldArrayFormContainer
          level={level + 1}
          key={field.id}
          title={t('tariff.form.tariff-element.price-component.item.title', {
            index: index + 1,
          })}
          onRemove={() => remove(index)}>
          <PriceComponentForm
            prefixName={`${prefixName}.${index}`}
            errors={errors?.[index]}
            touchedFields={touchedFields?.[index]}
          />
        </FieldArrayFormContainer>
      ))}

      <div>
        <Button
          startIcon={<AddIcon />}
          onClick={() => append(DEFAULT_PRICE_COMPONENT)}>
          {t('tariff.form.tariff-element.price-component.append')}
        </Button>
      </div>
    </FormSection>
  );
}
