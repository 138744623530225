import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerResponseModel } from 'src/core/model/customer.model';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { APP_PATH } from 'src/config/app-paths';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BoltIcon from '@mui/icons-material/Bolt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDatetimeColumn } from 'src/feature/data-grid/column/use-datetime-column';
import { usePseudoColumn } from 'src/feature/data-grid/column/use-pseudo-column';

import { useEmailColumn } from '../../data-grid/column/use-email-column';
import { useNameColumn } from '../../data-grid/column/use-name-column';
import { generateDataGridRoute } from '../../data-grid/use-data-grid-search-params/generate-data-grid-route';

export function useCustomerColumn(): GridColDef[] {
  const { t } = useTranslation();

  const customerTranslations: Record<keyof CustomerResponseModel, string> = t(
    'model.customer',
    {
      returnObjects: true,
    },
  );

  const datetimeColumn = useDatetimeColumn();
  const emailColumn = useEmailColumn();
  const nameColumn = useNameColumn();
  const pseudoColumn = usePseudoColumn();

  return useMemo(
    () => [
      {
        ...pseudoColumn,
        field: 'pseudo',
        headerName: customerTranslations.pseudo,
      },

      {
        ...nameColumn,
        field: 'firstName',
        headerName: customerTranslations.firstName,
      },

      {
        ...nameColumn,
        field: 'lastName',
        headerName: customerTranslations.lastName,
      },

      {
        ...emailColumn,
        field: 'emailAddress',
        headerName: customerTranslations.emailAddress,
      },

      {
        ...datetimeColumn,
        field: 'lastConnection',
        headerName: customerTranslations.lastConnection,
        valueGetter: (
          params: GridValueGetterParams<CustomerResponseModel>,
        ): Date => new Date(params.row.lastConnection),
      },
      {
        field: 'isEmailAddressVerified',
        type: 'boolean',
        headerName: customerTranslations.isEmailAddressVerified,
        width: 120,
        sortable: false,
      },
      {
        field: 'isWebauthnEnabled',
        type: 'boolean',
        headerName: customerTranslations.isWebauthnEnabled,
        width: 120,
        sortable: false,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params: GridRowParams<CustomerResponseModel>) => [
          <GridActionsCellItem
            key="link-to-detail"
            component={RouterLink}
            target="_blank"
            label={t('charging-session.data-grid.cta.view-detail')}
            icon={<OpenInNewIcon />}
            to={generatePath(APP_PATH.CUSTOMER_DETAIL, {
              customerId: params.row.id,
            })}
          />,
          <GridActionsCellItem
            key="link-to-invoice"
            component={RouterLink}
            target="_blank"
            label={t('customer.data-grid.cta.link-to-invoice')}
            icon={<RequestQuoteIcon />}
            to={generateDataGridRoute(APP_PATH.LIST_INVOICE, {
              filter: { pseudo: params.row.pseudo },
            })}
          />,
          <GridActionsCellItem
            key="link-to-charging-session"
            component={RouterLink}
            target="_blank"
            label={t('customer.data-grid.cta.link-to-charging-session')}
            icon={<BoltIcon />}
            to={generateDataGridRoute(APP_PATH.LIST_CHARGING_SESSION, {
              filter: { pseudo: params.row.pseudo },
            })}
          />,
        ],
      },
    ],
    [
      t,
      customerTranslations,
      emailColumn,
      nameColumn,
      datetimeColumn,
      pseudoColumn,
    ],
  );
}
