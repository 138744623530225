import { Route } from 'react-router-dom';
import { RoutingConfigurationModel } from 'src/core/model/routing-configuration.model';
import { PageLayout } from 'src/shared/layout/page-layout/page.layout';
import { RoleGuard } from './role.guard';

export function generateRouterFromConfiguration(
  configuration: RoutingConfigurationModel[],
): React.ReactNode {
  return configuration.map((entry): React.JSX.Element => {
    return (
      <Route
        key={entry.route}
        path={entry.route}
        element={
          <RoleGuard role={entry.role} key={entry.role}>
            <PageLayout key={entry.route}>
              <PageLayout.Header labelKey={entry.labelKey} icon={entry.icon} />
              <entry.controller />
            </PageLayout>
          </RoleGuard>
        }
      />
    );
  });
}
