import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';

import {
  DOWNLOAD_INVOICE_HTML_ENDPOINT,
  DOWNLOAD_INVOICE_PDF_ENDPOINT,
  INVOICE_ENDPOINT,
} from 'src/config/api-routes';
import { InvoiceResponseModel } from '../model/invoice.model';
import {
  DataGridQueryModel,
  PaginationResponseModel,
} from '../model/data-grid.model';
import { baseQueryBuilder } from './base-query.builder';
import { downloadBlob } from '../helper/download-blob';
import { mapDataGridQueryModelToApiQuery } from '../helper/map-data-grid-query-model-to-api-query';

export const invoiceApi = createApi({
  reducerPath: 'invoiceApi',

  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getListInvoice: builder.query<
      PaginationResponseModel<InvoiceResponseModel>,
      DataGridQueryModel
    >({
      query: (params: DataGridQueryModel) =>
        `${INVOICE_ENDPOINT}?${mapDataGridQueryModelToApiQuery(
          params,
        ).toString()}`,
    }),

    getInvoiceHtml: builder.query<string, string | null>({
      query: (invoiceId: string | null) => ({
        url: generatePath(DOWNLOAD_INVOICE_HTML_ENDPOINT, { invoiceId }),
        responseHandler: 'text',
      }),
    }),

    downloadInvoicePdf: builder.mutation<null, string>({
      queryFn: async (invoiceId: string, _, __, baseQuery) => {
        let result = await baseQuery({
          url: generatePath(DOWNLOAD_INVOICE_PDF_ENDPOINT, { invoiceId }),
          method: 'GET',
          responseHandler: (response: any): Blob => response.blob(),
        });

        if (result.error) {
          result = await baseQuery({
            url: generatePath(DOWNLOAD_INVOICE_PDF_ENDPOINT, { invoiceId }),
            method: 'POST',
            responseHandler: (response: any): Blob => response.blob(),
          });
        }

        if (result.data) {
          downloadBlob(result.data as Blob, `invoice-${invoiceId}.pdf`);
        }

        return { data: null };
      },
    }),
  }),
});

export const {
  useGetListInvoiceQuery,
  useDownloadInvoicePdfMutation,
  useGetInvoiceHtmlQuery,
} = invoiceApi;
