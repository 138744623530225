import { toFloat } from 'radash';
import { parse as papaparse, ParseResult } from 'papaparse';
import {
  DELMONICOS_QUARTER_OVERVIEW_EXTERNAL_ID_CARD,
  QO_PARSE_CHECKOUT,
  QO_PARSE_INDEX,
} from 'src/config/app.config';
import {
  CHECKOUT_FEE_MODEL_LENGTH,
  CheckoutFeeModel,
  EnhancedQuarterOverviewModel,
  QUARTER_OVERVIEW_MODEL_LENGTH,
  QuarterOverviewModel,
} from 'src/core/model/quarter-overview.model';
import { CheckoutBreakdownTypeEnum } from 'src/core/model/enum/checkout-breakdown-type.enum';
import { Logger } from 'src/core/logger/logger';

function csvStringToFloat(value: string = ''): number {
  return toFloat(value.replace(',', '.'), 0);
}

function floatToCsvString(value?: number): string {
  return value?.toString()?.replace('.', ',') ?? '';
}

export function parseToQuarterOverviewModel(data: any[]): QuarterOverviewModel {
  if (data.length < QUARTER_OVERVIEW_MODEL_LENGTH) {
    throw new Error(
      `data ${data[0] ?? null} does not match QuarterOverviewModel, ${
        data.length
      }/${QUARTER_OVERVIEW_MODEL_LENGTH} properties available.`,
    );
  }

  return {
    id: data[QO_PARSE_INDEX.id],
    date: data[QO_PARSE_INDEX.date],
    chargePointName: data[QO_PARSE_INDEX.chargePointName],
    connectorId: data[QO_PARSE_INDEX.connectorId],
    customerId: data[QO_PARSE_INDEX.customerId],
    name: data[QO_PARSE_INDEX.name],
    externalCardId: data[QO_PARSE_INDEX.externalCardId],
    internalCardId: data[QO_PARSE_INDEX.internalCardId],
    global: data[QO_PARSE_INDEX.global],
    energyDelivered: csvStringToFloat(data[QO_PARSE_INDEX.energyDelivered]),
    totalHT: csvStringToFloat(data[QO_PARSE_INDEX.totalHT]),
    durationInMin: csvStringToFloat(data[QO_PARSE_INDEX.durationInMin]),
  };
}

export function parseToCheckoutFeeModel(data: any[]): CheckoutFeeModel {
  if (data.length < CHECKOUT_FEE_MODEL_LENGTH) {
    throw new Error(
      `data ${data[0] ?? null} does not match CheckoutFeeModel, ${
        data.length
      }/${CHECKOUT_FEE_MODEL_LENGTH} properties available.`,
    );
  }

  const expectedBreakdownType = Object.values(CheckoutBreakdownTypeEnum);
  const receivedBreakdownType = data[QO_PARSE_CHECKOUT.breakdownType];

  if (!expectedBreakdownType.includes(receivedBreakdownType)) {
    Logger.warn(
      `[CHECKOUT-FILE]: Unknown breakdownType, received: ${receivedBreakdownType}`,
    );
  }

  return {
    breakdownType: receivedBreakdownType,
    iTransactionId: data[QO_PARSE_CHECKOUT.iTransactionId],
    totalHT: csvStringToFloat(data[QO_PARSE_CHECKOUT.totalHT]),
  };
}

export function unparseQuarterOverviewModel(
  data: EnhancedQuarterOverviewModel,
): string[] {
  return [
    data.id,
    data.date,
    data.chargePointName,
    data.customerId,
    data.connectorId,
    data.name,
    data.externalCardId,
    data.internalCardId,
    data.global,
    floatToCsvString(data.energyDelivered),
    floatToCsvString(data.totalHT),
    floatToCsvString(data.durationInMin),

    data.evseUid ?? '',
    floatToCsvString(data.totalHTDelmonicos),
    floatToCsvString(data.totalTTCDelmonicos),
    data.transactionId ?? '',
    data.iTransactionId ?? '',
    floatToCsvString(data.checkoutFees),
  ];
}

export function isDelmonicosLine(data: QuarterOverviewModel): boolean {
  const regex = new RegExp(`^${DELMONICOS_QUARTER_OVERVIEW_EXTERNAL_ID_CARD}*`);

  return regex.test(data.externalCardId);
}

export async function parse(file: File): Promise<any[]> {
  return new Promise((resolve, reject) => {
    papaparse(file, {
      worker: true,
      skipEmptyLines: true,
      complete({ data, errors }: ParseResult<Record<string, unknown>>) {
        if (errors?.length > 0) {
          reject(errors);
          return;
        }

        resolve(data.slice(1)); // remove headers
      },
    });
  });
}
