import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { CustomerResponseModel } from 'src/core/model/customer.model';
import { SkeletonList } from '../skeleton/skeleton-list';

type OrderCustomerCardProps = CardProps & {
  lastName?: string;
  firstName?: string;
  pseudo?: string;
  email?: string;
  isPending?: boolean;
};

export function OrderCustomerCard(
  props: Readonly<OrderCustomerCardProps>,
): React.JSX.Element {
  const {
    email,
    firstName,
    lastName,
    pseudo,
    isPending = false,
    sx,
    ...cardProps
  } = props;

  const { t } = useTranslation();

  const customerTranslations: Record<keyof CustomerResponseModel, string> = t(
    'model.customer',
    {
      returnObjects: true,
    },
  );

  return (
    <Card sx={{ overflow: 'unset', ...sx }} {...cardProps}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary.light' }}>
            <PersonIcon color="primary" />
          </Avatar>
        }
        title={t('customer.card.title')}
      />

      <CardContent>
        <List>
          {isPending ? (
            <SkeletonList />
          ) : (
            <>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  secondary={firstName ?? t('customer.card.fallback')}
                  primary={customerTranslations.firstName}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  secondary={lastName ?? t('customer.card.fallback')}
                  primary={customerTranslations.lastName}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <EmailOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  secondary={email ?? t('customer.card.fallback')}
                  primary={customerTranslations.emailAddress}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <PermIdentityIcon />
                </ListItemIcon>
                <ListItemText
                  secondary={pseudo ?? t('customer.card.fallback')}
                  primary={customerTranslations.pseudo}
                />
              </ListItem>
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
}
