import { GridColTypeDef } from '@mui/x-data-grid';
import { useCopyColumn } from './use-copy-column';

export function useUidColumn(): GridColTypeDef {
  const identifierColumn = useCopyColumn();

  return {
    ...identifierColumn,
    width: 160,
  };
}
