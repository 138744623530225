import { CircularProgress, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import invoiceErrorUrl from 'src/asset/invoice-error.png';

const Container = styled('div')(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.grey[50],
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
}));

type Props = {
  readonly isError?: boolean;
};

export function InvoicePlaceholder(props: Props): React.JSX.Element {
  const { isError } = props;
  const { t } = useTranslation();

  return (
    <Container>
      {isError ? (
        <>
          <img src={invoiceErrorUrl} alt="Invoice preview is not available" />

          <Typography component="h2" variant="h4" sx={{ mt: 1 }}>
            {t('invoice.preview.placeholder.title')}
          </Typography>

          <Typography variant="body1">
            {t('invoice.preview.placeholder.caption')}
          </Typography>
        </>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
}
