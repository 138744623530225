import { Navigate } from 'react-router-dom';

import { Logger } from 'src/core/logger/logger';
import { APP_PATH } from 'src/config/app-paths';
import { useAppSelector } from 'src/core/helper/redux.hook';
import { isAuthenticated } from 'src/core/authentication/authentication.slice';

export const DefaultController = (): React.JSX.Element => {
  const isUserAuthenticated = useAppSelector(isAuthenticated);

  Logger.error('Default controller: router error');

  return (
    <Navigate
      to={isUserAuthenticated ? APP_PATH.DASHBOARD : APP_PATH.LOGIN}
      replace
    />
  );
};
