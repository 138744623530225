import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { CURRENT_LOCALE_LSK } from 'src/config/storage-keys';
import { I18N_DEFAULT_NS } from 'src/config/app.config';

import en from './en.json';
import fr from './fr.json';
import { SupportedLocaleEnum } from '../model/enum/supported-locale.enum';
import { unityFormatter } from './formatter/unity.formatter';
import { durationFormatter } from './formatter/duration.formatter';
import { errorMessagePostProcessor } from './post-processor/error-message.post-processor';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(errorMessagePostProcessor)
  .init({
    defaultNS: I18N_DEFAULT_NS,
    resources: {
      [SupportedLocaleEnum.en]: {
        translation: en,
      },
      [SupportedLocaleEnum.fr]: {
        translation: fr,
      },
    },
    fallbackLng: [SupportedLocaleEnum.fr, SupportedLocaleEnum.en],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      lookupLocalStorage: CURRENT_LOCALE_LSK,
      caches: [],
    },
    postProcess: ['errorMessage'],
  });

i18n.services.formatter?.add('unity', unityFormatter);
i18n.services.formatter?.add('duration', durationFormatter);

export default i18n;
