import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  IconButton,
  List,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetListInvoiceQuery } from 'src/core/service/invoice.service';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { SkeletonList } from 'src/shared/skeleton/skeleton-list';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { APP_PATH } from 'src/config/app-paths';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { generateDataGridRoute } from 'src/feature/data-grid/use-data-grid-search-params/generate-data-grid-route';
import { InvoiceListItem } from 'src/shared/invoice-list-item/invoice.list-item';

type CustomerInvoiceListProps = CardProps & {
  pseudo?: string;
};

export function CustomerInvoiceList(
  props: Readonly<CustomerInvoiceListProps>,
): React.JSX.Element {
  const { pseudo = '', sx, ...cardProps } = props;
  const { t } = useTranslation();

  const { data, isFetching, isError, isUninitialized } = useGetListInvoiceQuery(
    {
      paginate: {
        pageSize: 3,
        page: 0,
      },
      filter: {
        pseudo,
      },
      sort: [
        {
          field: 'createdAt',
          sort: 'desc',
        },
      ],
    },
    {
      skip: !pseudo,
    },
  );

  return (
    <>
      <Card sx={{ overflow: 'unset', ...sx }} {...cardProps}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: 'primary.light' }}>
              <RequestQuoteIcon color="primary" />
            </Avatar>
          }
          title={t('customer.detail.invoice-section')}
        />

        <CardContent>
          {isFetching || isUninitialized ? (
            <SkeletonList />
          ) : (
            <List>
              {data?.docs.length === 0
                ? t('customer.detail.no-invoice')
                : data?.docs.map((invoice) => (
                    <InvoiceListItem
                      key={invoice.quoteId}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          component={RouterLink}
                          target="_blank"
                          to={generatePath(APP_PATH.INVOICE_HTML, {
                            invoiceId: invoice.invoiceId,
                          })}>
                          <OpenInNewIcon />
                        </IconButton>
                      }
                      createdAt={invoice.createdAt}
                      totalTTC={invoice.totalTTC}
                    />
                  ))}
            </List>
          )}
        </CardContent>

        {pseudo && data?.hasNextPage && (
          <CardActions>
            <Button
              component={RouterLink}
              target="_blank"
              to={generateDataGridRoute(APP_PATH.LIST_INVOICE, {
                filter: { pseudo },
              })}>
              {t('customer.detail.see-more')}
            </Button>
          </CardActions>
        )}
      </Card>

      {isError && (
        <Snackbar variant="error" message={t('error.get-list-invoice-query')} />
      )}
    </>
  );
}
