import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useGetLockStatusQuery,
  useGetOneCustomerQuery,
} from 'src/core/service/customer.service';
import { CustomerCard } from 'src/shared/customer-card/customer.card';
import { Snackbar } from 'src/shared/snackbar/snackbar';
import { Stack } from '@mui/material';
import { ReactNode, useLayoutEffect } from 'react';
import { useSetPageActionContext } from 'src/shared/layout/page-layout/page-layout.context-provider';

import { CustomerInvoiceList } from './customer-invoice-list';
import { CustomerChargingSessionList } from './customer-charging-session-list';
import { CustomerLockButton } from '../customer-lock/customer-lock.button';
import { CustomerLockAlert } from '../customer-lock/customer-lock.alert';
import { CustomerSyncSubscriptionButton } from '../customer-sync-subscription/customer-sync-subscription.button';
import { CustomerSubscriptionCard } from './customer-subscription.card';

export function CustomerDetail(): React.JSX.Element {
  const { customerId } = useParams();
  const { t } = useTranslation();
  const setPageAction = useSetPageActionContext();

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    isError: isCustomerError,
  } = useGetOneCustomerQuery(customerId ?? null, {
    skip: !customerId,
  });

  const {
    data: lockStatus,
    isError: isLockStatusError,
    refetch: refetchLockStatus,
  } = useGetLockStatusQuery(customerId ?? null, {
    skip: !customerId,
  });

  useLayoutEffect(() => {
    const lockCustomerButton: ReactNode | null =
      lockStatus && !lockStatus.isLocked ? (
        <CustomerLockButton key="lock" customerId={customerId} />
      ) : null;

    setPageAction([
      <CustomerSyncSubscriptionButton
        isEmailAddressVerified={customer?.isEmailAddressVerified}
        key="sync-subscription"
        customerId={customerId}
      />,
      lockCustomerButton,
    ]);
  }, [customerId, lockStatus, setPageAction, customer?.isEmailAddressVerified]);

  return (
    <>
      <Stack direction="row" gap={4} flexWrap="wrap">
        {lockStatus?.isLocked && (
          <CustomerLockAlert
            customerId={customerId}
            remainingLockTime={(lockStatus?.remainingLockTime ?? 0) / 1000}
            onRemainingLockTimeout={refetchLockStatus}
          />
        )}

        <CustomerCard
          isPending={isLoadingCustomer}
          {...customer}
          sx={{ flex: 1, flexBasis: 350 }}
        />
        <CustomerInvoiceList
          pseudo={customer?.pseudo}
          sx={{ flex: 1, flexBasis: 350 }}
        />
        <CustomerChargingSessionList
          pseudo={customer?.pseudo}
          sx={{ flex: 1, flexBasis: 350 }}
        />
        <CustomerSubscriptionCard
          isLoading={isLoadingCustomer}
          subscriptionIds={customer?.emspSubscriptionIds}
          sx={{ flex: 1, flexBasis: 350 }}
        />
      </Stack>

      {isCustomerError && (
        <Snackbar variant="error" message={t('error.get-one-customer-query')} />
      )}

      {isLockStatusError && (
        <Snackbar variant="error" message={t('error.get-lock-status-query')} />
      )}
    </>
  );
}
