import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

type DrawerMenuItemProps = {
  readonly onClick: (event: React.MouseEvent<HTMLElement>) => void;
  readonly icon: React.ReactNode;
  readonly label: string;
  readonly selected?: boolean;
};

export function DrawerMenuItem(props: DrawerMenuItemProps): React.JSX.Element {
  const { onClick, icon, label, selected = false } = props;

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick} selected={selected}>
        <ListItemIcon>{icon} </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {label}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
}
