import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import { APP_PATH } from 'src/config/app-paths';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbItem } from './breadcrumb-item';

export function Breadcrumb(): React.JSX.Element {
  const location = useLocation();

  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <MUIBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      max-items={5}
      aria-label="breadcrumb"
      sx={{ color: 'inherit' }}>
      <BreadcrumbItem path={APP_PATH.DASHBOARD} />

      {pathnames.map((subPath, index): React.JSX.Element | null => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return <BreadcrumbItem path={to} key={to} subPath={subPath} />;
      })}
    </MUIBreadcrumbs>
  );
}
