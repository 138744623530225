import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  COMMENT_MAX_LENGTH,
  DEFAULT_TARIFF_STRENGTH,
} from 'src/config/form-validation-rules';
import {
  ChargePointFormModel,
  ChargePointResponseModel,
} from 'src/core/model/charge-point.model';
import { useGetListPreCaptureAmountQuery } from 'src/core/service/payment.service';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export function useChargePointFormValidation(): Record<
  keyof ChargePointFormModel,
  RegisterOptions
> {
  const { t } = useTranslation();

  const chargePointTranslations: Record<
    keyof ChargePointResponseModel,
    string
  > = t('model.charge-point', {
    returnObjects: true,
  });

  const formValidationRules = useFormValidation();

  const { data: preCaptureAmountList } = useGetListPreCaptureAmountQuery();
  const tariffStrengthList = (preCaptureAmountList ?? []).map(
    ({ strength }) => strength,
  );
  const tariffStrengthMin =
    Math.min(...tariffStrengthList) ?? DEFAULT_TARIFF_STRENGTH;
  const tariffStrengthMax =
    Math.max(...tariffStrengthList) ?? DEFAULT_TARIFF_STRENGTH;

  return useMemo(
    () => ({
      cpoId: {
        ...formValidationRules.required({
          field: chargePointTranslations.cpoId,
        }),
      },
      locationId: {
        ...formValidationRules.required({
          field: chargePointTranslations.locationId,
        }),
      },
      evseUid: {
        ...formValidationRules.required({
          field: chargePointTranslations.evseUid,
        }),
      },
      tariffStrength: {
        valueAsNumber: true,
        ...formValidationRules.required({
          field: chargePointTranslations.tariffStrength,
        }),
        ...formValidationRules.min({
          field: chargePointTranslations.tariffStrength,
          value: tariffStrengthMin,
        }),
        ...formValidationRules.max({
          field: chargePointTranslations.tariffStrength,
          value: tariffStrengthMax,
        }),
      },
      connectorId: {
        ...formValidationRules.required({
          field: chargePointTranslations.connectorId,
        }),
      },
      comment: {
        ...formValidationRules.maxLength({
          field: chargePointTranslations.comment,
          value: COMMENT_MAX_LENGTH,
        }),
      },
      themeId: {
        ...formValidationRules.uidPattern({
          field: chargePointTranslations.themeId,
        }),
      },
    }),
    [
      formValidationRules,
      chargePointTranslations,
      tariffStrengthMin,
      tariffStrengthMax,
    ],
  );
}
