import {
  FilterDefinitionModel,
  FilterValueModel,
} from 'src/core/model/data-grid.model';
import { useTranslation } from 'react-i18next';

import { useDateFilter } from './use-date-filter';

type UseMonthFilterProps = {
  field: string;
  label: string;
  valueFormatter?: (value: FilterValueModel) => string;
};

export function useMonthFilter(
  hookProps: UseMonthFilterProps,
): FilterDefinitionModel {
  const { t } = useTranslation();

  const {
    label,
    field,
    valueFormatter = (value) =>
      t('data-grid.row.month', { value: new Date(value.toString()) }),
  } = hookProps;

  return useDateFilter({
    label,
    field,
    valueFormatter,
    datePickerFormat: 'MMMM',
    datePickerOnChangeFormatTemplate: 'MM',
    datePickerViews: ['month'],
  });
}
