/*
    Local storage keys
    suffix with LSK
*/
export const JWT_LSK = 'jwt';
export const CURRENT_LOCALE_LSK = 'i18nextLng';
export const KEEP_MENU_OPEN_LSK = 'keep-menu-open';

/*
    Session storage keys
    suffix with SSK
*/
