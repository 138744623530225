import { DataGrid } from 'src/feature/data-grid/data-grid';
import { useDataGridSearchParams } from 'src/feature/data-grid/use-data-grid-search-params/use-data-grid-search-params';
import { useGetListOrderQuery } from 'src/core/service/order.service';
import { DEFAULT_DATA_GRID_MODEL } from 'src/config/data-grid.config';
import { useTranslation } from 'react-i18next';
import { Snackbar } from 'src/shared/snackbar/snackbar';

import { useOrderColumn } from './use-order-column';
import { useOrderFilter } from './use-order-filter';

export function OrderDataGrid(): React.JSX.Element {
  const columns = useOrderColumn();
  const filterDefinitionModel = useOrderFilter();
  const { model, onModelChange } = useDataGridSearchParams({
    ...DEFAULT_DATA_GRID_MODEL,
    sort: [
      {
        field: 'createdAt',
        sort: 'desc',
      },
    ],
  });

  const { t } = useTranslation();
  const {
    data: paginatedData,
    isFetching,
    isError,
  } = useGetListOrderQuery(model);

  return (
    <>
      <DataGrid
        columns={columns}
        filterDefinitionModel={filterDefinitionModel}
        loading={isFetching}
        rows={paginatedData?.docs ?? []}
        rowCount={paginatedData?.totalDocs ?? 0}
        model={model}
        onModelChange={onModelChange}
        initialState={{
          columns: {
            columnVisibilityModel: {
              quoteId: false,
              orderId: false,
              sessionId: false,
            },
          },
        }}
      />

      {isError && (
        <Snackbar variant="error" message={t('error.get-list-order-query')} />
      )}
    </>
  );
}
