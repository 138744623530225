import { createApi } from '@reduxjs/toolkit/query/react';

import { LOCATION_API } from 'src/config/api-routes';
import { baseQueryBuilder } from './base-query.builder';
import { OcpiLocationModel } from '../model/ocpi.model';

export const locationApi = createApi({
  reducerPath: 'locationApi',

  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getListLocation: builder.query<OcpiLocationModel[], string>({
      query: (cpoUrl: string) => ({ url: LOCATION_API, params: { cpoUrl } }),
    }),
  }),
});

export const { useGetListLocationQuery } = locationApi;
