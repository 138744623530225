import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';

import { ORDER_DETAIL_ENDPOINT, ORDER_ENDPOINT } from 'src/config/api-routes';
import {
  DataGridQueryModel,
  PaginationResponseModel,
} from '../model/data-grid.model';
import { baseQueryBuilder } from './base-query.builder';
import { mapDataGridQueryModelToApiQuery } from '../helper/map-data-grid-query-model-to-api-query';
import { OrderResponseModel } from '../model/order.model';

export const orderApi = createApi({
  reducerPath: 'orderApi',

  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getListOrder: builder.query<
      PaginationResponseModel<OrderResponseModel>,
      DataGridQueryModel
    >({
      query: (params: DataGridQueryModel) =>
        `${ORDER_ENDPOINT}?${mapDataGridQueryModelToApiQuery(
          params,
        ).toString()}`,
    }),
    getOneOrder: builder.query<OrderResponseModel, string | null>({
      query: (orderId: string | null) =>
        generatePath(ORDER_DETAIL_ENDPOINT, { orderId }),
    }),
  }),
});

export const { useGetListOrderQuery, useGetOneOrderQuery } = orderApi;
