import { useTranslation } from 'react-i18next';
import { matchPath, useNavigate } from 'react-router-dom';
import { RoutingConfigurationModel } from 'src/core/model/routing-configuration.model';
import { DrawerMenuItemCollapsible } from 'src/shared/layout/dashboard-layout/drawer-menu/drawer-menu-collapsible-item';
import { DrawerMenuItem } from 'src/shared/layout/dashboard-layout/drawer-menu/drawer-menu-item';
import { canAccess } from 'src/core/helper/can-access';
import { RoleEnum } from 'src/core/model/enum/role.enum';
import { useAppSelector } from 'src/core/helper/redux.hook';
import { getUserRole } from 'src/core/authentication/authentication.slice';

type DrawerMenuBuilderProps = {
  pathname: string;
  configuration: RoutingConfigurationModel[];
};

export function DrawerMenuBuilder(
  props: Readonly<DrawerMenuBuilderProps>,
): React.ReactNode {
  const { configuration, pathname } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole: RoleEnum | null = useAppSelector(getUserRole);

  return configuration
    .filter((item) => item.showInMenu)
    .filter((item) => userRole && canAccess(userRole, item.role))
    .map(
      (entry): React.ReactNode =>
        entry.items ? (
          <DrawerMenuItemCollapsible
            onClick={() => navigate(entry.route)}
            key={entry.labelKey}
            icon={entry.icon}
            label={t(entry.labelKey)}>
            <DrawerMenuBuilder
              pathname={pathname}
              configuration={entry.items}
            />
          </DrawerMenuItemCollapsible>
        ) : (
          <DrawerMenuItem
            key={entry.labelKey}
            icon={entry.icon}
            label={t(entry.labelKey)}
            onClick={() => navigate(entry.route)}
            selected={!!matchPath(entry.route, pathname)}
          />
        ),
    );
}
