import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import faviconURL from 'src/asset/favicon.svg';
import { APP_PATH } from 'src/config/app-paths';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 1,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const FirstLetter = styled(Typography)(({ theme }) => ({
  '::first-letter': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
}));

type BrandProps = {
  readonly onClose: () => void;
};

export function Brand(props: BrandProps): React.JSX.Element {
  const navigate = useNavigate();
  const { onClose } = props;

  return (
    <Container>
      <Box
        onClick={() => navigate(APP_PATH.DASHBOARD)}
        sx={{
          backgroundColor: 'primary.main',
          borderRadius: 1,
          padding: 1,
          width: 40,
          height: 40,
        }}>
        <img src={faviconURL} alt="brand" />
      </Box>

      <Stack>
        <Typography sx={{ fontWeight: 'bold' }}>delmonicos</Typography>

        <Stack direction="row" gap={1}>
          <FirstLetter>Back</FirstLetter>
          <FirstLetter>Office</FirstLetter>
        </Stack>
      </Stack>

      <IconButton onClick={onClose}>
        <ChevronLeftIcon />
      </IconButton>
    </Container>
  );
}
