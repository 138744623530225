import { createApi } from '@reduxjs/toolkit/query/react';

import { CPO_API } from 'src/config/api-routes';
import { baseQueryBuilder } from './base-query.builder';
import { OcpiCpoModel } from '../model/ocpi.model';

export const cpoApi = createApi({
  reducerPath: 'cpoApi',

  baseQuery: baseQueryBuilder(),

  endpoints: (builder) => ({
    getListCpo: builder.query<OcpiCpoModel[], null>({
      query: () => CPO_API,
    }),
  }),
});

export const { useGetListCpoQuery } = cpoApi;
