import { configureStore } from '@reduxjs/toolkit';
import { loginApi } from './service/login.service';
import { authenticationReducer } from './authentication/authentication.slice';
import { userApi } from './service/user.service';
import { invoiceApi } from './service/invoice.service';
import { customerApi } from './service/customer.service';
import { unauthorizedMiddleware } from './middleware/unauthorized.middleware';
import { currentUserApi } from './service/current-user.service';
import { chargingSessionApi } from './service/charging-session.service';
import { chargePointApi } from './service/charge-point.service';
import { qrCodeApi } from './service/qr-code.service';
import { orderApi } from './service/order.service';
import { logApi } from './service/log.service';
import { paymentApi } from './service/payment.service';
import { cpoApi } from './service/cpo.service';
import { locationApi } from './service/location.service';

export const reduxStore = configureStore({
  reducer: {
    authentication: authenticationReducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [currentUserApi.reducerPath]: currentUserApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [chargingSessionApi.reducerPath]: chargingSessionApi.reducer,
    [chargePointApi.reducerPath]: chargePointApi.reducer,
    [qrCodeApi.reducerPath]: qrCodeApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [logApi.reducerPath]: logApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [cpoApi.reducerPath]: cpoApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      loginApi.middleware,
      userApi.middleware,
      currentUserApi.middleware,
      invoiceApi.middleware,
      customerApi.middleware,
      chargingSessionApi.middleware,
      chargePointApi.middleware,
      qrCodeApi.middleware,
      orderApi.middleware,
      logApi.middleware,
      paymentApi.middleware,
      cpoApi.middleware,
      locationApi.middleware,

      unauthorizedMiddleware,
    ]),
});

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;
