import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { UserFormModel, UserResponseModel } from 'src/core/model/user.model';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TextField } from 'src/shared/form/text-field';
import { CancelButton } from 'src/shared/button/cancel.button';
import { SubmitButton } from 'src/shared/button/submit.button';
import { ApiErrorEnum } from 'src/core/model/enum/api-error.enum';
import { useUserFormValidation } from './use-user.form-validation';

type UserFormViewProps = {
  readonly formType: 'create' | 'update';
  readonly defaultValues?: UserFormModel;
  readonly errorCode?: string | null;
  readonly pending: boolean;
  readonly onSubmit: (payload: UserFormModel) => void;
  readonly onCancel: () => void;
};

export function UserFormView(props: UserFormViewProps): React.JSX.Element {
  const { formType, defaultValues, pending, onSubmit, onCancel, errorCode } =
    props;

  const { t } = useTranslation();
  const formValidation = useUserFormValidation();

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<UserFormModel>({
    mode: 'onChange',
    defaultValues,
  });

  const userTranslations: Record<keyof UserResponseModel, string> = t(
    'model.user',
    {
      returnObjects: true,
    },
  );

  const emailApiErrorMessage =
    errorCode === ApiErrorEnum.EMAIL_ALREADY_USED
      ? t(`error.${errorCode}`)
      : null;

  const trigramApiErrorMessage =
    errorCode === ApiErrorEnum.TRIGRAM_ALREADY_USED
      ? t(`error.${errorCode}`)
      : null;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <TextField
          {...register('firstName', formValidation.firstName)}
          id="firstName"
          label={userTranslations.firstName}
          autoComplete="firstName"
          autoFocus
          errorMessage={errors?.firstName?.message}
          isTouched={touchedFields?.firstName}
        />

        <TextField
          {...register('lastName', formValidation.lastName)}
          id="lastName"
          label={userTranslations.lastName}
          autoComplete="lastName"
          errorMessage={errors?.lastName?.message}
          isTouched={touchedFields?.lastName}
        />

        <TextField
          {...register('trigram', formValidation.trigram)}
          id="trigram"
          label={userTranslations.trigram}
          autoComplete="trigram"
          errorMessage={errors?.trigram?.message ?? trigramApiErrorMessage}
          isTouched={touchedFields?.trigram}
        />

        <TextField
          {...register('emailAddress', formValidation.emailAddress)}
          id="email-address"
          label={userTranslations.emailAddress}
          autoComplete="email"
          errorMessage={errors?.emailAddress?.message ?? emailApiErrorMessage}
          isTouched={touchedFields?.emailAddress}
        />
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onCancel} />
        <SubmitButton loading={pending} disabled={!isValid}>
          {t(`form.${formType}-button`)}
        </SubmitButton>
      </DialogActions>
    </form>
  );
}
