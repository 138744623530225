import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TINY_URL_MAX_LENGTH } from 'src/config/form-validation-rules';
import { QrCodeFormModel } from 'src/core/model/qr-code.model';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export function useQrCodeFormValidation(): Record<
  keyof QrCodeFormModel,
  RegisterOptions
> {
  const { t } = useTranslation();

  const qrCodeTranslations: Record<keyof QrCodeFormModel, string> = t(
    'model.qr-code',
    {
      returnObjects: true,
    },
  );

  const formValidationRules = useFormValidation();

  return useMemo(
    () => ({
      chargePointId: {
        ...formValidationRules.required({
          field: qrCodeTranslations.chargePointId,
        }),
      },
      tinyId: {
        ...formValidationRules.required({
          field: qrCodeTranslations.tinyId,
        }),
      },
      tinyUrl: {
        ...formValidationRules.required({
          field: qrCodeTranslations.tinyUrl,
        }),

        ...formValidationRules.maxLength({
          field: qrCodeTranslations.tinyUrl,
          value: TINY_URL_MAX_LENGTH,
        }),
      },
      template: {
        ...formValidationRules.required({
          field: qrCodeTranslations.template,
        }),
      },
      connector: {
        ...formValidationRules.required({
          field: qrCodeTranslations.connector,
        }),
      },
    }),
    [formValidationRules, qrCodeTranslations],
  );
}
