import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PriceComponentModel } from 'src/core/model/tariff.model';
import { useFormValidation } from 'src/shared/form/use-form-validation';

export function usePriceComponentFormValidation(): Record<
  keyof PriceComponentModel,
  RegisterOptions
> {
  const { t } = useTranslation();
  const translations: Record<keyof PriceComponentModel, string> = t(
    'model.price-component',
    {
      returnObjects: true,
    },
  );

  const formValidationRules = useFormValidation();

  return useMemo(
    () => ({
      type: {
        ...formValidationRules.required({
          field: translations.type,
        }),
      },
      price: {},
      stepSize: {},
    }),
    [translations, formValidationRules],
  );
}
