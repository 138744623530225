import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Stack,
  SxProps,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { QuarterOverviewStateEnum } from 'src/core/model/enum/quarter-overview-state.enum';
import { EnhancedQuarterOverviewModel } from 'src/core/model/quarter-overview.model';
import { QuarterOverviewStatus } from './quarter-overview-status';

type RenderCellStatusProps = {
  readonly state?: QuarterOverviewStateEnum;
  readonly label: string;
};
function RenderCellStatus(props: RenderCellStatusProps): React.JSX.Element {
  const { state, label } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}>
      <Typography>{label}</Typography>
      <QuarterOverviewStatus state={state} />
    </Stack>
  );
}

export type QuarterOverviewTableRowProps = EnhancedQuarterOverviewModel & {
  readonly chargingSessionState?: QuarterOverviewStateEnum;
  readonly orderState?: QuarterOverviewStateEnum;
  readonly checkoutState?: QuarterOverviewStateEnum;
  readonly sx?: SxProps;
  readonly isOpen?: boolean;
  readonly setIsOpen?: (isOpen: boolean) => void;
};

export function QuarterOverviewTableRow(
  props: QuarterOverviewTableRowProps,
): React.JSX.Element {
  const {
    chargingSessionState,
    checkoutState,
    orderState,
    sx,
    isOpen,
    setIsOpen,
    id,
    date,
    chargePointName,
    customerId,
    connectorId,
    name,
    externalCardId,
    internalCardId,
    global,
    energyDelivered,
    totalHT,
    durationInMin,
    evseUid,
    totalHTDelmonicos,
    totalTTCDelmonicos,
    transactionId,
    iTransactionId,
    checkoutFees,
  } = props;

  const { t } = useTranslation();

  return (
    <TableRow
      key={id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...sx }}>
      <TableCell>
        {setIsOpen && (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </TableCell>

      <TableCell>
        <RenderCellStatus
          state={orderState}
          label={t('quarter-overview.order-state')}
        />
        <RenderCellStatus
          state={chargingSessionState}
          label={t('quarter-overview.charging-session-state')}
        />
        <RenderCellStatus
          state={checkoutState}
          label={t('quarter-overview.checkout-state')}
        />
      </TableCell>

      <TableCell>{id ?? '-'}</TableCell>
      <TableCell>
        {date ? t('data-grid.row.date', { value: date }) : '-'}
      </TableCell>
      <TableCell>{chargePointName ?? '-'}</TableCell>
      <TableCell>{customerId ?? '-'}</TableCell>
      <TableCell>{connectorId ?? '-'}</TableCell>
      <TableCell>{name ?? '-'}</TableCell>
      <TableCell>{externalCardId ?? '-'}</TableCell>
      <TableCell>{internalCardId ?? '-'}</TableCell>
      <TableCell>{global ?? '-'}</TableCell>
      <TableCell align="right">
        {energyDelivered
          ? t('data-grid.row.energy-delivered', { value: energyDelivered })
          : '-'}
      </TableCell>
      <TableCell align="right">
        {totalHT ? t('data-grid.row.currency', { value: totalHT }) : '-'}
      </TableCell>
      <TableCell align="right">{durationInMin ?? '-'}</TableCell>
      <TableCell>{evseUid ?? '-'}</TableCell>
      <TableCell align="right">
        {totalHTDelmonicos
          ? t('data-grid.row.currency', { value: totalHTDelmonicos })
          : '-'}
      </TableCell>
      <TableCell align="right">
        {totalTTCDelmonicos
          ? t('data-grid.row.currency', { value: totalTTCDelmonicos })
          : '-'}
      </TableCell>
      <TableCell>{transactionId ?? '-'}</TableCell>
      <TableCell>{iTransactionId ?? '-'}</TableCell>
      <TableCell align="right">
        {checkoutFees
          ? t('data-grid.row.currency', { value: checkoutFees })
          : '-'}
      </TableCell>
    </TableRow>
  );
}
