import { useTranslation } from 'react-i18next';
import { FilterDefinitionModel } from 'src/core/model/data-grid.model';
import { InvoiceResponseModel } from 'src/core/model/invoice.model';
import { useDateRangeFilter } from 'src/feature/data-grid/filter/generic-filter/use-date-range-filter';
import { useNumberRangeFilter } from 'src/feature/data-grid/filter/generic-filter/use-number-range-filter';
import { useTextFilter } from 'src/feature/data-grid/filter/generic-filter/use-text-filter';

export function useInvoiceFilter(): FilterDefinitionModel[] {
  const { t } = useTranslation();

  const invoiceTranslations: Record<keyof InvoiceResponseModel, string> = t(
    'model.invoice',
    {
      returnObjects: true,
    },
  );

  const pseudoFilter = useTextFilter(
    'pseudo',
    invoiceTranslations.customerPseudo,
  );
  const amountFilter = useNumberRangeFilter({
    label: invoiceTranslations.totalTTC,
    keyMin: 'totalTTCMin',
    keyMax: 'totalTTCMax',
    valueFormatter: (value) => t('data-grid.row.currency', { value }),
  });

  const updatedAtFilter = useDateRangeFilter({
    label: invoiceTranslations.updatedAt,
    keyMin: 'updatedAfter',
    keyMax: 'updatedBefore',
  });

  const createdAtFilter = useDateRangeFilter({
    label: invoiceTranslations.createdAt,
    keyMin: 'createdAfter',
    keyMax: 'createdBefore',
  });

  return [pseudoFilter, amountFilter, updatedAtFilter, createdAtFilter];
}
