import { useDialog } from 'src/core/helper/use-dialog';
import { Button } from 'src/shared/button/button';
import { useTranslation } from 'react-i18next';
import { UpdatePasswordDialog } from './update-password.dialog';

export function UpdatePasswordButton(): React.JSX.Element {
  const { isOpen, onClose, onOpen } = useDialog();
  const { t } = useTranslation();

  return (
    <>
      <Button variant="outlined" onClick={onOpen}>
        {t('update-password.button-label')}
      </Button>
      <UpdatePasswordDialog isOpen={isOpen} onClose={onClose} />
    </>
  );
}
