import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import 'src/core/locale/i18n';
import { ConfigurationService } from 'src/core/configuration/configuration.service';
import { Logger } from 'src/core/logger/logger';
import { Router } from 'src/router/router';
import { ThemeProvider } from 'src/shared/styles/theme-provider';
import { reduxStore } from 'src/core/store';

export function App(): React.JSX.Element {
  useEffect(() => {
    Logger.debug('Configuration loaded', ConfigurationService.toJSON());
  }, []);

  return (
    <ReduxProvider store={reduxStore}>
      <BrowserRouter>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router />
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
}
