import { styled } from '@mui/material/styles';
import { HTMLProps, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

export type TextOverflowProps = {
  readonly maxWidth?: number | 'unset';
};

const CustomTextOverflow = styled('span', {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<TextOverflowProps>(({ maxWidth }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  maxWidth,
}));

export function TextOverflow(
  props: TextOverflowProps & HTMLProps<HTMLSpanElement>,
): React.JSX.Element {
  const { children, maxWidth, onClick } = props;
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textElementRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setIsOverflowed(
      (textElementRef.current?.scrollHeight ?? 0) >
        (textElementRef.current?.clientHeight ?? 0) ||
        (textElementRef.current?.scrollWidth ?? 0) >
          (textElementRef.current?.clientWidth ?? 0),
    );
  }, []);

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <CustomTextOverflow
        ref={textElementRef}
        maxWidth={maxWidth}
        onClick={onClick}>
        {children}
      </CustomTextOverflow>
    </Tooltip>
  );
}
